import React, { FunctionComponent } from 'react';
import Button from 'react-bootstrap/Button';

type ContentBtnProps = {
  variant: string;
  text: string;
  btnClick: () => void;
  className?: string;
  disabled?: boolean;
};

export const ContentButton: FunctionComponent<ContentBtnProps> = ({ variant, text, btnClick, className, disabled, ...props }) => {

  return (
    <Button className={`content-button d-flex justify-content-center align-items-center ${className}`} variant={variant} disabled={disabled} onClick={btnClick} {...props}>
      <div className="content-button__content">{props.children}</div>
      <div className="content-button__text">{text}</div>
    </Button>
  );
};
