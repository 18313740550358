import React, { FunctionComponent } from 'react';
import { IconType } from '../../api/enums';
import { ReactComponent as LeaveIcon } from '../../assets/icons/leave.svg';
import { ReactComponent as DoubleIcon } from '../../assets/icons/double.svg';
import { GoListUnordered, GoPlus } from 'react-icons/go';
import { BiFullscreen, BiExitFullscreen, BiHelpCircle } from 'react-icons/bi';
import { FaDice } from 'react-icons/fa';
import { FiRotateCcw } from 'react-icons/fi';

type iconProps = {
  iconType: IconType;
};

export const Icon: FunctionComponent<iconProps> = ({ iconType }) => {
  switch (iconType) {
    case IconType.Rotate:
      return <FiRotateCcw className="content-icon icon-svg" size={24} />;
    case IconType.Double:
      return <DoubleIcon />;
    case IconType.Leave:
      return <LeaveIcon />;
    case IconType.Plus:
      return <GoPlus className="content-icon icon-svg" />;
    case IconType.List:
      return <GoListUnordered className="content-icon icon-svg" />;
    case IconType.FullScreen:
      return <BiFullscreen className="content-icon icon-svg" />;
    case IconType.FullScreenExit:
      return <BiExitFullscreen className="content-icon icon-svg" />;
    case IconType.Help:
      return <BiHelpCircle className="content-icon icon-svg" size={24} />;
    case IconType.Dice:
      return <FaDice className="content-icon icon-svg" size={24} />;
    default:
      return null;
  }
};
