import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMyProfile, getToken } from '../../redux/user/userActions';
import PlayerAuth from '../PlayerAuth';
import { ReactComponent as WhiteDice } from '../../assets/white-dice-logo.svg';
import { ReactComponent as BlackDice } from '../../assets/black-dice-logo.svg';
import { ReactComponent as NameLogo } from '../../assets/name-logo.svg';
import { ReactComponent as BlackChip } from '../../assets/black-chip.svg';
import { ReactComponent as WhiteChip } from '../../assets/white-chip.svg';
import { isEmpty } from 'react-redux-firebase';
import { FirebaseReducer } from 'react-redux-firebase/index.d';
import cn from 'classnames';

interface Props {
  auth: FirebaseReducer.AuthState;

  getMyProfile: (playerId: string) => void;
  getToken: () => Promise<any>;
  loginHandler: () => void;
}

function calculateBGChips(num: number) {
  const arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(<BlackChip key={`BlackChip${i}`} className={`bg-chip-black${i + 1}`} />);
    arr.push(<WhiteChip key={`WhiteChip${i}`} className={`bg-chip-white${i + 1}`} />);
  }
  return arr;
}

const BGChips = calculateBGChips(10);

class Login extends Component<Props> {
  state = {
    loginModalShow: true,
  };

  setLoginModalShow = (loginModalShow: boolean) => this.setState({ loginModalShow });

  render() {
    return (
      <div className={'login'}>
        {BGChips}
        <div
          className={cn('col-xs-12 col-sm-8 col-md-6 col-lg-5 login__container', { 'login--loader': !isEmpty(this.props.auth) })}
        >
          <div className="login__logo">
            <div className="login__logo-img">
              <WhiteDice className={'white-dice'} />
              <BlackDice className={'black-dice'} />
            </div>
            <div className={'login__logo-name'}>
              <NameLogo className={'logo-name'} />
            </div>
          </div>

          <PlayerAuth login={this.props.loginHandler} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ game, user, firebase }) => {
  return {
    profile: user.myProfile,
    token: user.token,
    auth: firebase.auth,
  };
};

export default connect(mapStateToProps, {
  getMyProfile,
  getToken,
})(Login);
