import { GameColor } from '../api/enums';

/* returns true if all men are in home and can leave board */
export const isEveryoneInHomeSquare = (points, isMyTurn) => {
  let isEveryoneHome = true;

  //get points with actions
  points.forEach((point, i) => {
    //Check homeboard
    //Player 1
    if (isMyTurn && i <= 17 && point.playerColor === GameColor.White) {
      isEveryoneHome = false;
    }
    //Player 2
    else if (!isMyTurn && i >= 6 && point.playerColor === GameColor.Black) {
      isEveryoneHome = false;
    }

    return null;
  });

  return isEveryoneHome;
};
