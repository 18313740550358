import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase/index.d';
import firebase from 'firebase/app';
import { History } from 'history';

import { PlayerProfile } from '../../api/models';
import CustomHeader from '../CustomHeader/CustomHeader';
import CustomFooter from '../CustomFooter/CustomFooter';
import { ReactComponent as ButtonBack } from '../../assets/arrow.svg';
import { setNewName } from '../../redux/user/userActions';

import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/image';
import Button from 'react-bootstrap/Button';
import emptyAvatar from '../../assets/empty-avatar.png';
import cn from 'classnames';
import { IAppContext, withAppContext } from '../../context';
import { AlertVariant } from '../AlertList/AlertsList';

interface Props {
  history: History;
  profile: PlayerProfile;
  auth: FirebaseReducer.AuthState;
  appContext?: IAppContext;

  setNewName: (playerId: string, newName: string) => Promise<any>;
}

interface State {
  name: string;
  buttonSaveDisabled: boolean;
}

class Profile extends Component<Props, State> {
  state = {
    name: this.props.profile.displayName || this.props.profile.email,
    buttonSaveDisabled: true,
  };

  goBack = () => {
    this.props.history.goBack();
  };

  goToLobby = () => {
    this.props.history.push('/Lobbies');
  };

  changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: e.target.value,
    });

    e.target.value?.trim()
      ? this.setState({
          buttonSaveDisabled: false,
        })
      : this.setState({
          buttonSaveDisabled: true,
        });
  };

  saveChanges = () => {
    this.props.setNewName(this.props.profile.userId, this.state.name?.trim()).then(() => {
      this.props.appContext.setAlert({
        title: 'Profile',
        message: 'Name successfully changed',
        variant: AlertVariant.success,
      });
      this.setState({
        buttonSaveDisabled: true,
        name: this.state.name?.trim()
      });
    });
  };

  render() {
    return (
      <>
        <div className="flex-grow-1">
          <CustomHeader history={this.props.history} statusAndDisableAd={true} />
          <div className="p-4">
            <div className="user-profile">
              <div className="d-flex flex-column flex-wrap position-absolute f-left">
                <span className="page-name">Profile</span>
                <ButtonBack role="button" className="button-back" onClick={this.goBack} />
              </div>
              <Card className="info-card">
                <div className="user-avatar rounded-circle">
                  <Image src={this.props.profile.avatar || emptyAvatar} className="rounded-circle" />
                </div>
                {/*<Button variant="app-outline" className="upload-button">
                  <div className="text">Upload Photo</div>
                </Button>*/}
                <div className="form">
                  <label htmlFor="name">Your Name</label>
                  <div
                    className={cn({
                      'input-group mb-3': true,
                    })}
                  >
                    <input
                      id={'new-name'}
                      type={'text'}
                      className="form-control custom"
                      placeholder="Display Name"
                      aria-label="name"
                      value={this.state.name}
                      onChange={this.changeName}
                    />
                  </div>
                </div>
                <Row className="profile-buttons">
                  <Button variant="app-outline" className="p-0 btn-action" onClick={this.goToLobby}>
                    Cancel
                  </Button>
                  <Button variant="app-main ml-auto" className="p-0 btn-action" disabled={this.state.buttonSaveDisabled} onClick={this.saveChanges}>
                    Save
                  </Button>
                </Row>
              </Card>
            </div>
          </div>
        </div>
        <CustomFooter history={this.props.history} />
      </>
    );
  }
}

const mapStateToProps = ({ user, firebase }) => {
  return {
    profile: user.myProfile,
    auth: firebase.auth,
  };
};

export default connect(mapStateToProps, {
  setNewName,
})(withAppContext(Profile));
