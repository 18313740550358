import React from 'react';
import { Provider } from 'react-redux';
import firebase from 'firebase';
import ReactDOM from 'react-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { firebaseConfig as fbConfig, reduxFirebase as rfConfig } from './config';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/store';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import AppRouter from './container/AppRouter';

firebase.initializeApp(fbConfig);

function AuthIsLoaded({ children }) {
  const auth = useSelector((state: any) => state.firebase.auth);
  if (isLoaded(auth)) {
    console.log('auth!!!', auth);
    return children;
  }
  return <div className="auth-info">Auth Loading...</div>;
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider firebase={firebase} config={rfConfig} dispatch={store.dispatch}>
      <AuthIsLoaded>
        <div className="auth-info">Auth is Loaded</div> {/* Rest of App Components */}
        <AppRouter />
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
