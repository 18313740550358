import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase/index.d';
import firebase from 'firebase/app';
import { Redirect } from 'react-router-dom';
import { History } from 'history';

import UserStatus from '../UserStatus/UserStatus';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import { AppModal } from '../Modals/AppModal';

import logoImg from '../../assets/logo.png';
import photoEmpty from '../../assets/empty-avatar.png';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { PlayerProfile } from '../../api/models';
import { isMobileOnly } from 'react-device-detect';

interface Props {
  statusAndDisableAd: boolean;
  history: History;
  profile: PlayerProfile;
  auth: FirebaseReducer.AuthState;
  isFromGame?: boolean;
  parentRef?: React.RefObject<HTMLDivElement>;
  onLogout?: () => void;
}

interface State {
  sidebarMenuShow: boolean;
  profileRedirectModalShow: boolean;
  logOutModalShow: boolean;
}

class CustomHeader extends Component<Props, State> {
  getInitState = () => {
    return {
      sidebarMenuShow: false,
      profileRedirectModalShow: false,
      logOutModalShow: false,
    };
  };

  state = this.getInitState();

  setSidebarMenuShow = (sidebarMenuShow: boolean) => this.setState({ sidebarMenuShow });
  setProfileRedirectModalShow = (profileRedirectModalShow: boolean) => this.setState({ profileRedirectModalShow });
  setLogOutModalShow = (logOutModalShow: boolean) => this.setState({ logOutModalShow });

  logOut = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        console.log('Sign-out successful', res);
        if (this.props.onLogout) {
          this.props.onLogout();
        }

        // document.location.reload();
        // if (this.props.history !== null) {
        // this.props.history.push('/login');

        // }
      })
      .catch((error) => {
        console.log('signOut error', error);
      });
  };

  goToProfile = () => {
    this.props.history.push('/Profile');
  };

  cancelProfileModalHandler = () => {
    this.setProfileRedirectModalShow(false);
  };

  goToProfileHandler = () => {
    this.setProfileRedirectModalShow(false);
    this.props.history.push('/Lobbies');
    this.goToProfile();
  };

  cancelLogOutModalHandler = () => {
    this.setLogOutModalShow(false);
  };

  logOutHandler = () => {
    this.setLogOutModalShow(false);
    this.logOut();
  };

  render() {
    let banner = isMobileOnly ? null : (
      <div className="banner">
        <iframe
          src="https://syndication.exdynsrv.com/ads-iframe-display.php?idzone=4055226&output=noscript&type=728x90"
          width="728"
          height="90"
          scrolling="no"
          marginWidth={0}
          marginHeight={0}
          frameBorder={0}
        ></iframe>
      </div>
    );

    let disableAd =
      this.props.statusAndDisableAd === true ? (
        <Button variant="app-primary" className="button-disableAd">
          Disable Ad
        </Button>
      ) : null;

    let status =
      this.props.statusAndDisableAd === true ? (
        <UserStatus
          allGames={this.props.profile.totalGames}
          winsGames={this.props.profile.wonGames}
          lossesGames={this.props.profile.looseGames}
        />
      ) : null;

    let profile = (
      <Dropdown alignRight className="dropdown">
        <Dropdown.Toggle variant="app-empty">
          <Row className="w-100 ml-auto flex-nowrap justify-content-end">
            <div className="header-name">{this.props.profile.displayName || this.props.profile.email}</div>
            <div className="avatar-group header-userAvatar rounded-circle">
              <Image src={this.props.profile.avatar || photoEmpty} className="rounded-circle" />
            </div>
          </Row>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu">
          <Button
            variant="app-empty"
            className="dropdown-item item item-first p-0"
            onClick={this.props.isFromGame ? () => this.setProfileRedirectModalShow(true) : this.goToProfile}
          >
            <div className="text">Profile</div>
          </Button>
          <Button
            variant="app-empty"
            className="dropdown-item item item-second p-0"
            onClick={this.props.isFromGame ? () => this.setLogOutModalShow(true) : this.logOut}
          >
            <div className="text">Log Out</div>
          </Button>
        </Dropdown.Menu>
      </Dropdown>
    );

    let menu = (
      <div className="menu-icon">
        <MenuIcon role="button" onClick={() => this.setSidebarMenuShow(true)} />
      </div>
    );

    let profileRedirectModal = (
      <AppModal
        show={this.state.profileRedirectModalShow}
        onHide={() => this.setProfileRedirectModalShow(false)}
        parentRef={this.props.parentRef}
        modalConfig={{
          showClose: true,
          headText: 'Are you sure you want to go to Profile?',
          bodyText: 'If you go to Profile you will automaticly lose',
          secondaryBtnText: 'Cancel',
          primaryBtnText: 'Profile',
          secondaryHandle: this.cancelProfileModalHandler,
          primaryHandle: this.goToProfileHandler,
        }}
      />
    );

    let logOutRedirectModal = (
      <AppModal
        show={this.state.logOutModalShow}
        onHide={() => this.setLogOutModalShow(false)}
        parentRef={this.props.parentRef}
        modalConfig={{
          showClose: true,
          headText: 'Are you sure you want to log out?',
          bodyText: 'If you log out you will automaticly lose',
          secondaryBtnText: 'Cancel',
          primaryBtnText: 'Log out',
          secondaryHandle: this.cancelLogOutModalHandler,
          primaryHandle: this.logOutHandler,
        }}
      />
    );

    let header = (
      <div className="header">
        <Row className="align-items-center flex-nowrap m-0 px-3">
          <Col xs lg={2} className="p-sm-0 logo">
            <a href="/Lobbies">
              <Image src={logoImg} fluid />
            </a>
          </Col>
          <Col xs lg={10}>
            <Row className="flex-nowrap justify-content-end">
              {/* {banner} */}
              <Row className={'header__user-stats'}>
                {/* {disableAd} */}
                {status}
                {profile}
                {menu}
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    );

    return (
      <div>
        {this.props.auth.uid ? (
          <div>
            <SidebarMenu
              show={this.state.sidebarMenuShow}
              onHide={() => this.setSidebarMenuShow(false)}
              profile={this.props.profile}
              history={this.props.history}
              isFromGame={this.props.isFromGame}
              parentRef={this.props.parentRef}
            />
            {header}
            {profileRedirectModal}
            {logOutRedirectModal}
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user, firebase }) => {
  return {
    profile: user.myProfile,
    auth: firebase.auth,
  };
};

export default connect(mapStateToProps)(CustomHeader);
