import React, { FunctionComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

type ModalProps = {
  show: boolean;
  backdrop?: string | boolean;
  onHide?: () => void;
  modalConfig: AppModalConfig;
  parentRef?: React.RefObject<HTMLDivElement>;
};

export const AppModal: FunctionComponent<ModalProps> = ({ modalConfig, parentRef, ...props }) => {
  const secondButton = modalConfig.secondaryBtnText ? (
    <Button variant={modalConfig.isSecondaryRed ? 'app-decline' : 'app-outline'} onClick={modalConfig.secondaryHandle}>
      {modalConfig.secondaryBtnText}
    </Button>
  ) : null;
  const primaryButton = modalConfig.primaryBtnText ? (
    <Button variant="app-main" onClick={modalConfig.primaryHandle}>
      {modalConfig.primaryBtnText}
    </Button>
  ) : null;
  const closeBtn = modalConfig.showClose ? (
    <CloseIcon
      className="close"
      role="button"
      onClick={props.onHide}
    />
  ) : null;
  const headerText = modalConfig.headText ? (
    <div className="modal-header">
      <div className="modal-title">{modalConfig.headText}</div>
      {closeBtn}
    </div>
  ) : null;

  return (
    <Modal
      {...props}
      dialogClassName="modal-50w"
      className="app-modal"
      centered
      container={parentRef}
    >
      {headerText}
      <Modal.Body>
        <p>{modalConfig.bodyText}</p>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {secondButton}
        {primaryButton}
      </Modal.Footer>
    </Modal>
  );
};

export type AppModalConfig = {
  showClose?: boolean;
  headText?: string;
  bodyText?: string;
  secondaryBtnText?: string;
  primaryBtnText?: string;
  secondaryHandle?: () => void;
  primaryHandle?: () => void;
  isSecondaryRed?: boolean;
};
