import React, { FunctionComponent } from 'react';

interface Props {
  label: string;
  clicked: () => void;
}

export const RollButton: FunctionComponent<Props> = ({ label, clicked, ...props }) => {
  let clickable = '';
  if (clicked) {
    clickable = ' clickable';
  }

  return (
    <div className="rollButton" onClick={clicked}>
      <div className={'rollButtonContent' + clickable}>
        <p>{label}</p>
      </div>
    </div>
  );
};
