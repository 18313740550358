import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createDebounce from 'redux-debounced';
import thunk from 'redux-thunk';
import { firebaseReducer, FirebaseReducer } from 'react-redux-firebase';
import gameReducer, { InitialGameStateType } from './game/gameReducer';
import userReducer, { InitialUserStateType } from './user/userReducer';

const middlewares = [thunk, createDebounce()];

const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

interface UserProfile {
  email: string;
}

export interface RootState {
  game: InitialGameStateType;
  user: InitialUserStateType;
  firebase: FirebaseReducer.Reducer<UserProfile>;
}

const rootReducer = combineReducers<RootState>({
  game: gameReducer,
  user: userReducer,
  firebase: firebaseReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export { store };
