export enum Sound {
  Drop,
  Drag,
  Dice,
  Win,
  Lose,
  Done,
  Undo,
  Out,
}
