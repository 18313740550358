import React, { Component } from 'react';
import firebase from 'firebase/app';
import { connect } from 'react-redux';
import { History } from 'history';

import { AppModal } from '../Modals/AppModal';
import UserStatus from '../UserStatus/UserStatus';
import emptyAvatar from '../../assets/empty-avatar.png';
import { ReactComponent as CloseIcon } from '../../assets/icons/big_close.svg';

import Sidebar from 'react-sidebar';

import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/image';
import { PlayerProfile } from '../../api/models';

const getSidebarStyles = (isOpen) => ({
  root: {
    pointerEvents: isOpen ? 'auto' : 'none',
  },
  sidebar: {
    background: '#1d593d',
    width: '30%',
    maxWidth: '300px',
    position: 'fixed',
    zIndex: '4',
  },
});

interface Props {
  show: boolean;
  profile: PlayerProfile;
  isOpponent?: boolean;
  history?: History;
  isFromGame?: boolean;
  parentRef?: React.RefObject<HTMLDivElement>;
  onHide?: () => void;
  onLogout?: () => void;
}

interface State {
  profileRedirectModalShow: boolean;
  logOutModalShow: boolean;
}

class SidebarMenu extends Component<Props> {
  getInitState = () => {
    return {
      sidebarMenuShow: false,
      profileRedirectModalShow: false,
      logOutModalShow: false,
    };
  };

  state = this.getInitState();

  setProfileRedirectModalShow = (profileRedirectModalShow: boolean) => this.setState({ profileRedirectModalShow });
  setLogOutModalShow = (logOutModalShow: boolean) => this.setState({ logOutModalShow });

  logOut = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        console.log('Sign-out successful', res);
        if (this.props.onLogout) {
          this.props.onLogout();
        }
        // document.location.reload();
        // if (this.props.history !== null) {
        //   // this.props.history.push('/login');

        // }
      })
      .catch((error) => {
        console.log('signOut error', error);
      });
  };

  goToProfile = () => {
    this.props.history.push('/Profile');
  };

  cancelProfileModalHandler = () => {
    this.setProfileRedirectModalShow(false);
  };

  goToProfileHandler = () => {
    this.setProfileRedirectModalShow(false);
    this.props.history.push('/Lobbies');
    this.goToProfile();
  };

  cancelLogOutModalHandler = () => {
    this.setLogOutModalShow(false);
  };

  logOutHandler = () => {
    this.setLogOutModalShow(false);
    this.logOut();
  };

  render() {
    let profileRedirectModal = (
      <AppModal
        show={this.state.profileRedirectModalShow}
        onHide={() => this.setProfileRedirectModalShow(false)}
        parentRef={this.props.parentRef}
        modalConfig={{
          showClose: true,
          headText: 'Are you sure you want to go to Profile?',
          bodyText: 'If you go to Profile you will automaticly lose',
          secondaryBtnText: 'Cancel',
          primaryBtnText: 'Profile',
          secondaryHandle: this.cancelProfileModalHandler,
          primaryHandle: this.goToProfileHandler,
        }}
      />
    );

    let logOutRedirectModal = (
      <AppModal
        show={this.state.logOutModalShow}
        onHide={() => this.setLogOutModalShow(false)}
        parentRef={this.props.parentRef}
        modalConfig={{
          showClose: true,
          headText: 'Are you sure you want to log out?',
          bodyText: 'If you log out you will automaticly lose',
          secondaryBtnText: 'Cancel',
          primaryBtnText: 'Log out',
          secondaryHandle: this.cancelLogOutModalHandler,
          primaryHandle: this.logOutHandler,
        }}
      />
    );

    let sidebarContent = (
      <div className="sidebar-menu">
        <CloseIcon className="close" role="button" onClick={this.props.onHide} />
        <div className="userAvatar-menu rounded-circle">
          <Image
            src={
              this.props.profile && this.props.profile.avatar && this.props.profile.avatar != 'bot'
                ? this.props.profile.avatar
                : emptyAvatar
            }
            className="rounded-circle"
          />
        </div>
        <div className="user-name-menu">
          <div className="sidebar-menu__name">
            {this.props.profile ? this.props.profile.displayName || this.props.profile.email : ''}
          </div>
          <div className={'pl-1 sidebar-menu__rating'}>({this.props.profile ? this.props.profile.rating.toString() : ''})</div>
        </div>
        <UserStatus
          allGames={this.props.profile ? this.props.profile.totalGames : 0}
          winsGames={this.props.profile ? this.props.profile.wonGames : 0}
          lossesGames={this.props.profile ? this.props.profile.looseGames : 0}
        />
        {!this.props.isOpponent ? (
          <div className="menu">
            <Button
              variant="app-empty"
              className="menu-item menu-item-first"
              onClick={this.props.isFromGame ? () => this.setProfileRedirectModalShow(true) : this.goToProfile}
            >
              <div className="text">Profile</div>
            </Button>
            <Button
              variant="app-empty"
              className="menu-item"
              onClick={this.props.isFromGame ? () => this.setLogOutModalShow(true) : this.logOut}
            >
              <div className="text">Log Out</div>
            </Button>
          </div>
        ) : null}
        {/* <Button variant="app-primary" className="p-0 menu-button-disableAd">
          Disable Ad
        </Button> */}
      </div>
    );

    return (
      <>
        <Sidebar
          defaultSidebarWidth={30}
          transitions={true}
          pullRight={!this.props.isOpponent}
          open={this.props.show}
          styles={getSidebarStyles(this.props.show)}
          sidebar={sidebarContent}
          onSetOpen={this.props.onHide}
        >
          <span />
        </Sidebar>
        {profileRedirectModal}
        {logOutRedirectModal}
      </>
    );
  }
}

export default SidebarMenu;
