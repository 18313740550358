//Calculate score
import { getOutSideBarWithoutActions, getPointsWithoutActions } from '../helpers';
import { GameColor } from '../api/enums';
import { BarCheckers, Lanes } from '../api/models';

interface Game {
  points: Lanes
  checkersOnBar: BarCheckers
  outsideCheckers: BarCheckers
}

interface Result {
  P1: number
  P2: number
}

export const calculateScore = (game: Game): Result => {
  let scoreP1 = 0;
  let scoreP2 = 0;
  let points = getPointsWithoutActions(game.points);
  const checkersOnBar = { ...game.checkersOnBar };
  const outsideCheckers = getOutSideBarWithoutActions(game.outsideCheckers);

  points.map((point, index) => {
    if (point.playerColor) {
      //Check if the point belongs to a player

      if (point.playerColor === GameColor.White) {
        //if player 1
        scoreP1 += (24 - index) * point.checkers;
      } else {
        //If player 2
        scoreP2 += (index + 1) * point.checkers;
      }
    }
    return false;
  });

  //Score from checkersOnBar
  if (checkersOnBar.firstPlayer) {
    scoreP1 += 25 * checkersOnBar.firstPlayer;
  }
  if (checkersOnBar.secondPlayer) {
    scoreP2 += 25 * checkersOnBar.secondPlayer;
  }

  return { P1: scoreP1, P2: scoreP2 };
};
