import React, { Component } from 'react';
import { GameColor } from '../../api/enums';
import { ReactComponent as Pin1 } from '../../assets/pin1.svg';
import { ReactComponent as Pin2 } from '../../assets/pin2.svg';

interface Props {
  selectedColor: GameColor;
  className?: string;
  onPick: (color: GameColor) => void;
}

interface State {
  selected: GameColor;
}

class ColorPicker extends Component<Props, State> {
  state = {
    selected: this.props.selectedColor,
  };

  onSelectColor = (color: GameColor) => {
    this.setState({ selected: color });
    this.props.onPick(color);
  };

  render() {
    const { selected } = this.state;
    return (
      <div className={'color-picker ' + this.props.className ? this.props.className : null}>
        <p>Colour</p>
        <Pin2
          className={'pick-checker ' + (selected === GameColor.Black ? 'pick-checker--picked' : '')}
          role="button"
          onClick={() => this.onSelectColor(GameColor.Black)}/>
        <Pin1
          className={'pick-checker ' + (selected === GameColor.White ? 'pick-checker--picked' : '')}
          role="button"
          onClick={() => this.onSelectColor(GameColor.White)}
        />
      </div>
    );
  }
}

export default ColorPicker;
