import React from 'react';
import { GameColor } from '../../api/enums';
import { DragChecker } from '../dnd/DragChecker';

export const getCheckers = (player: GameColor, numberOfCheckers: number, callerIdKey: string, canMove: any, replacerColor: boolean, canDrag) => {
  if (player && numberOfCheckers) {
    //mount up to 5 checkers
    const count = numberOfCheckers > 5 ? 5 : numberOfCheckers;
    //checkers array
    const checkers = [];

    //Get checkers
    for (let i = 0; i < count; i++) {
      //highlight last checker if it can move
      if (canMove && i === count - 1) {
        checkers.push(<DragChecker player={player} count={1} key={callerIdKey + player + 'P' + i} keycode={callerIdKey + player + 'P' + i} replacerColor={replacerColor} canMove={true} canMoveEvent={canMove} canDrag={canDrag}/>);
      } else {
        checkers.push(<DragChecker player={player} count={1} key={callerIdKey + player + 'P' + i} keycode={callerIdKey + player + 'P' + i} replacerColor={replacerColor} canMove={false} canMoveEvent={canMove} canDrag={canDrag}/>);
      }
    }

    //add label to the first checker if the point has more than 5 checkers
    if (numberOfCheckers > 5) {
      checkers[0] = <DragChecker player={player} count={numberOfCheckers - 4} key={callerIdKey + player + 'P0'} keycode={callerIdKey + player + 'P0'} replacerColor={replacerColor} canMove={false} canMoveEvent={canMove} canDrag={canDrag}/>;
    }

    return checkers;
  } else {
    return null;
  }
};

export default getCheckers;
