import React, { Component } from 'react';
import { ReactComponent as Board } from '../../assets/board.svg';

interface Props {
  isRotatedSelected: boolean;
  className?: string;
  onPick: (value: boolean) => void;
}

interface State {
  selected: boolean;
}

class DirectPicker extends Component<Props, State> {
  state = {
    selected: this.props.isRotatedSelected,
  };

  onSelectDirect = (isRotated: boolean) => {
    this.setState({ selected: isRotated });
    this.props.onPick(isRotated);
  };

  render() {
    const { selected } = this.state;
    return (
      <div className={'direct-picker ' + this.props.className ? this.props.className : null}>
        <p>Direction</p>
        <Board
          className={'pick-direct ' + (!selected ? 'pick-direct--picked' : '')}
          role="button"
          onClick={() => this.onSelectDirect(false)}
        />
        <Board
          className={'pick-direct rotate ' + (selected ? 'pick-direct--picked' : '')}
          role="button"
          onClick={() => this.onSelectDirect(true)}
        />
      </div>
    );
  }
}

export default DirectPicker;
