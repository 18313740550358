import React from 'react';
import { IAlert } from './components/AlertList/AlertsList';

export interface IAppContext{
  setAlert: (alert: IAlert)=>void
}

export const AppContext = React.createContext({
});
export const withAppContext = (Component) => {
  const wrapped = (props) => {
    return <AppContext.Consumer>
      {
        (context) =>
          <Component {...props} appContext={{...context}}/>
      }
    </AppContext.Consumer>;
  };
  return wrapped;
};
