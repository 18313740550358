import React, { FunctionComponent, RefObject } from 'react';
import Container from 'react-bootstrap/Container';
import { AppModal } from '../components/Modals/AppModal';
import { GameStatus } from '../api/enums';
import Spinner from 'react-bootstrap/Spinner';
import { GameInfoModal } from '../components/Modals/GameInfoModal';
import { PLAYBOTROUTE } from '../api/constants';

interface Props {
  game: {
    status: number
    active: {
      isMyFirstTurn: boolean
      doublingCube: number
    }
    opponentProfile: {
      displayName: string
      email: string
    }
  },
  state: {
    waitingAnswerModalShow: boolean
    gameInfoModalShow: boolean
    acceptInfoModalShow: boolean
    helpModalShow: boolean
    leaveModalShow: boolean
    doubleStakeOfferedModalShow: boolean
  },
  parentRef: RefObject<HTMLDivElement>,
  cancelGameHandler: () => void,
  secondaryHandler: () => void,
  primaryHandler: () => void,
  declineHandler: () => void,
  acceptHandler: () => void,

  setLeaveModalShow: (state: boolean) => void,
  setGameInfoModalShow: (state: boolean) => void,
  setAcceptInfoModalShow: (status: boolean) => void,
  setHelpModalShow: (status: boolean) => void,

  match: {
    params:
      {
        id: string
      }
  },
}

const Modals:FunctionComponent<Props> = ({
                  game,
                  state,
                  parentRef,
                  cancelGameHandler,
                  setLeaveModalShow,
                  secondaryHandler,
                  primaryHandler,
                  setGameInfoModalShow,
                  declineHandler,
                  acceptHandler,
                  setAcceptInfoModalShow,
                  match,
                  setHelpModalShow,
                }) => {

  const handleLeaveModalHide = () => {
    setLeaveModalShow(false);
  };
  const handleGameInfoModalHide = () => {
    setGameInfoModalShow(false);
  };
  const handleAcceptInfoModalHide = () => {
    setAcceptInfoModalShow(false);
  };
  const handleHelpModalHide = () => {
    setHelpModalShow(false);
  };

  const waitingModals = [
    {
      show: game.status === GameStatus.CreatedLobby,
      backdrop: 'static',
      parentRef: parentRef,
      modalConfig: {
        headText: 'Waiting for the opponent...',
        secondaryBtnText: 'Leave',
        secondaryHandle: cancelGameHandler,
      },
    },
    {
      show: state.waitingAnswerModalShow,
      backdrop: 'static',
      parentRef: parentRef,
      modalConfig: {
        headText: 'Waiting for the answer...',
      },
    },
  ];

  const gameModals = [
    {
      show: state.gameInfoModalShow,
      onHide: handleGameInfoModalHide,
      parentRef: parentRef,
      modalConfig: {
        headText: 'Game has started!',
        bodyText: game.active.isMyFirstTurn
          ? 'You move first! Clock is running! Roll and click on the chip you want to move, and the destination space you want to move it to'
          : 'You move second! Clock is running! Roll and click on the chip you want to move, and the destination space you want to move it to',
        primaryBtnText: 'Got It!',
      },
    },
    {
      show: state.acceptInfoModalShow,
      onHide: handleAcceptInfoModalHide,
      parentRef: parentRef,
      modalConfig: {
        headText: 'Doubling Cube',
        bodyText: `${match.params.id === PLAYBOTROUTE ? 'Gammon Bot' : (
          game.opponentProfile ? game.opponentProfile.displayName || game.opponentProfile.email : 'Opponent')
        } accepted your doubling (x${game.active.doublingCube})`,
        primaryBtnText: 'Close',
      },
    },
    {
      show: state.helpModalShow,
      onHide: handleHelpModalHide,
      parentRef: parentRef,
      modalConfig: {
        headText: 'Help',
        bodyText:
          'Currently you can only move your chips by clicking on them, and then click on the destination spots where the chips can be moved to. Destination spots will illuminate in Green to make this process simple.',
        primaryBtnText: 'Got It!',
      },
    },
  ];

  const statusModals = [
    {
      show: state.leaveModalShow,
      onHide: handleLeaveModalHide,
      parentRef: parentRef,
      modalConfig: {
        showClose: true,
        headText: 'Are you sure you want to leave?',
        bodyText: 'If you leave you will automaticly lose',
        secondaryBtnText: 'Cancel',
        primaryBtnText: 'Leave',
        secondaryHandle: secondaryHandler,
        primaryHandle: primaryHandler,
      },
    },
    {
      show: state.doubleStakeOfferedModalShow,
      backdrop: 'static',
      parentRef: parentRef,
      modalConfig: {
        headText: 'Doubling cube',
        bodyText: `${
          game.opponentProfile ? game.opponentProfile.displayName || game.opponentProfile.email : 'Opponent'
        } wants to double the bet.`,
        secondaryBtnText: 'Decline',
        primaryBtnText: 'Accept',
        isSecondaryRed: true,
        secondaryHandle: declineHandler,
        primaryHandle: acceptHandler,
      },
    },
  ];

  return <>
    {
      waitingModals.map((item, i) =>
        <AppModal
          key={i}
          {...item}
        >
          <Spinner className="p-2" animation="border" variant="light" />
        </AppModal>)
    }
    {
      statusModals.map((item, i) =>
        <AppModal
          key={i}
          {...item}
        />,
      )
    }
    {
      gameModals.map((item, i) =>
        <GameInfoModal
          key={i}
          {...item}
        />,
      )
    }
  </>;
};

export default Modals;
