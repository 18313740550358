import axios, { AxiosResponse, AxiosError } from 'axios';
import { PlayerProfile, PlayerRegister, Token } from '../../api/models';
import { InitialGameStateType } from '../game/gameReducer';
import { FirebaseReducer } from 'react-redux-firebase';

const URL = process.env.REACT_APP_API_URL;
const API = URL + 'api/';

// action types
export const GET_TOKEN = 'GET_TOKEN';
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const SET_MY_PICTURE = 'SET_MY_PICTURE';
export const SET_NEW_NAME = 'SET_NEW_NAME';
export const NEW_USER = 'NEW_USER';


export type InferActionsTypes<T> = T extends { [keys: string]: (...args: any[]) => infer U } ? U : never;
export type ActionsType = InferActionsTypes<typeof userActions>;
export type StateFunc = () => { firebase: FirebaseReducer.Reducer; game: InitialGameStateType };

// action creators
export const userActions = {
  setToken: (token: Token) => ({ type: GET_TOKEN, token } as const),
  setProfile: (myProfile: PlayerProfile) => ({ type: GET_MY_PROFILE, myProfile } as const),
  setMyPicture: (base64: string) => ({ type: SET_MY_PICTURE, base64 } as const),
  setNewName: (newName: string) => ({ type: SET_NEW_NAME, newName} as const),
  newUser: () => ({ type: NEW_USER} as const),
};

export const getToken = (uid: string) => {
  return (dispatch) => {
    // const userId = getState().game.myProfile.id;
    // const userId =getState().firebase.auth.uid;
    // const userIdInt = parseInt(getState().firebase.auth.uid.replace(/\D/g,''));
    if (uid) {
      return axios
        .post(`${URL}getdevtoken/`, { userId: uid })
        .then((response: AxiosResponse<any>) => {
          dispatch(userActions.setToken(response.data));
          return Promise.resolve(response);
        })
        .catch((err: AxiosError) => console.log(err));
    }
  };
};

// export const getMyProfile = (playerId: string) => {
//   return (dispatch: any) => {
//     dispatch(
//       userActions.setProfile({
//         userId: playerId,
//         avatar: null,
//         displayName: null,
//         isPrime: false,
//         email: null,
//         looseGames: 0,
//         rating: 0,
//         totalGames: 0,
//         wonGames: 0,
//       })
//     );
//   };
// };

export const registerProfile = (uid: string, newProfile: PlayerRegister) => {
  return (dispatch) => {
    // const userId = getState().game.myProfile.id;
    // const userId =getState().firebase.auth.uid;
    // const userIdInt = parseInt(getState().firebase.auth.uid.replace(/\D/g,''));
    if (uid) {
      return axios
        .post(`${API}Users/${uid}`, newProfile)
        .then((response: AxiosResponse<any>) => {
          // dispatch(userActions.registerProfile(response.data));
          return Promise.resolve(response);
        })
        .catch((err: AxiosError) => console.log(err));
    }
  };
};

export const getMyProfile = (playerId: string) => {
  return (dispatch: any) => {
    return axios
      .get(`${API}Users/${playerId}`)
      .then((response: AxiosResponse<PlayerProfile>) => {
        dispatch(userActions.setProfile(response.data));
        return Promise.resolve(response.data);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  };
};

export const setMyPicture = (url: string) => {
  return (dispatch: any) => {
    dispatch(userActions.setMyPicture(url));
  };
};

export const setNewUser = () => {
  return (dispatch: any) => {
    dispatch(userActions.newUser());
  };
};

export const setNewName = (playerId: string, newName: string) => {
  return (dispatch: any) => {
    return axios
      .patch(`${API}Users/${playerId}`, {'displayName': newName})
      .then((response: AxiosResponse<any>) => {
        dispatch(userActions.setNewName(newName));
        return Promise.resolve(response.data);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  }
}
