export const getMovingChecker = (isMyTurn, movingCheckerFromState) => {
  let movingChecker;
  if (movingCheckerFromState !== false) {
    //Moving checker is assigned
    movingChecker = movingCheckerFromState;
  } else {
    //Checker coming from checkersOnBar
    if (isMyTurn) {
      movingChecker = -1;
    } else {
      movingChecker = 24;
    }
  }
  return movingChecker;
};
