import React, { Component } from 'react';

type Props = {
  interval: number;
  startTimeInSeconds: number;
  children?: any;
  autoStart?: boolean;
  showAlways?: boolean;
  isOver?: () => void;
};

type State = {
  timer: any;
  timeRemainingInSeconds: number;
  timeIsOver: boolean;
};

class TimerProvider extends Component<Props, State> {
  state = {
    timer: null,
    timeRemainingInSeconds: this.props.startTimeInSeconds,
    timeIsOver: true,
  };

  componentDidMount() {
    if (this.props.autoStart) {
      this.startTimer();
      this.setState({
        timeIsOver: false,
      });
    }
  }

  componentWillUnmount() {
    this.stopTimer();
    this.setState({
      timeIsOver: true,
    });
  }

  startTimer = () => {
    if (this.state.timer) {
      this.stopTimer();
      this.resetTimer();
    }
    this.setState({
      timer: setInterval(() => {
        this.decrementTimeRemaining();
      }, 1000),
      timeIsOver: true,
    });
  };

  resetTimer = () => {
    this.setState({ timeRemainingInSeconds: this.props.startTimeInSeconds, timeIsOver: false });
  };
  stopTimer = () => {
    this.setState({
      timer: null,
    });
    clearInterval(this.state.timer);
  };

  decrementTimeRemaining = () => {
    if (this.state.timeRemainingInSeconds === 0) {
      this.setState({
        timeIsOver: true,
      });
      if (this.props.isOver) {
        this.props.isOver();
      }
    }
    if (this.state.timeRemainingInSeconds > 0) {
      this.setState({
        timeRemainingInSeconds: this.state.timeRemainingInSeconds - 1,
      });
    } else {
      clearInterval(this.state.timer);
    }
  };

  render() {
    // let start = <button onClick={this.startTimer}>start d</button>;
    // let stop = <button onClick={this.stopTimer}>stop</button>;
    let child = this.props.showAlways || !this.state.timeIsOver ? this.props.children(this.state.timeRemainingInSeconds) : null;
    console.log('this.state.timer', this.state.timer);

    // let reset = <button onClick={this.resetTimer}>reset</button>;
    // let resume = <button onClick={this.startTimer}>resume</button>;

    return <div className={'timer-provider ' + (this.state.timeIsOver ? '' : 'timer-provider--launched')}>{child}</div>;
  }
}

export default TimerProvider;
