import React, { Component } from 'react';
import { History } from 'history';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

interface Props {
  history: History;
}

class CustomFooter extends Component<Props> {
  goToContacts = () => {
    this.props.history.push('/Contacts');
  };

  goToPrivacy = () => {
    this.props.history.push('/PrivacyAndTerms');
  };

  render() {
    return (
      <div className="footer">
        <Row className="m-0 h-100 justify-content-end">
          <Button variant="app-empty" onClick={this.goToPrivacy} className="p-0 footer-link">
            Privacy and Terms
          </Button>
          <Button variant="app-empty" onClick={this.goToContacts} className="p-0 footer-link">
            Contacts
          </Button>
        </Row>
      </div>
    );
  }
}

export default CustomFooter;
