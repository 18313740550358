import {
  ActionsType,
  GET_MY_PROFILE,
  SET_MY_PICTURE,
  GET_TOKEN,
  SET_NEW_NAME,
  NEW_USER,
} from './userActions';
import { PlayerProfile,Token } from '../../api/models';

export type InitialUserStateType = {
  myProfile: PlayerProfile;
  token: Token;
  isNewUser: boolean;
};

const initialState: InitialUserStateType = {
  myProfile: {
    userId: null, // my playerId
    avatar: null,
    displayName: null,
    isPrime: false,
    email: null,
    looseGames: 0,
    rating: 0,
    totalGames: 0,
    wonGames: 0,
  },
  token: {
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
  },
  isNewUser: false,
};

const gameReducer = (state = initialState, action: ActionsType): InitialUserStateType => {
  switch (action.type) {
    case NEW_USER:
      return {
        ...state,
        isNewUser: true,
      };
    case GET_MY_PROFILE:
      return {
        ...state,
        myProfile: action.myProfile,
      };
    case SET_MY_PICTURE:
      const newProfile = state.myProfile;
      newProfile.avatar = action.base64;
      return {
        ...state,
        myProfile: newProfile,
      };
    case GET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_NEW_NAME:
      const profileWithNewName = state.myProfile;
      profileWithNewName.displayName = action.newName;
      return {
        ...state,
        myProfile: profileWithNewName,
      };
    default:
      return state;
  }
};
export default gameReducer;
