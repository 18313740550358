import React, { Component } from 'react';
import { History } from 'history';

import CustomHeader from '../CustomHeader/CustomHeader';
import CustomFooter from '../CustomFooter/CustomFooter';
import { ReactComponent as ButtonBack } from '../../assets/arrow.svg';

import Card from 'react-bootstrap/Card';

interface Props {
  history: History;
}

class Contacts extends Component<Props> {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <div className="flex-grow-1">
          <CustomHeader history={this.props.history} statusAndDisableAd={true} />
          <div className="p-4">
            <div className="contacts">
              <div className="d-flex flex-column flex-wrap position-absolute f-left">
                <span className="page-name">Contacts</span>
                <ButtonBack role="button" className="button-back" onClick={this.goBack} />
              </div>
              <Card className="contacts-card">
                <div className="label">E-mail:</div>
                <a href="mailto:dkary555@gmail.com" className="content">
                  dkary555@gmail.com
                </a>
                <div className="label">Address:</div>
                <div className="content end">
                  8507 N Akins Rd <br />N Royalton OH, 44133
                </div>
              </Card>
            </div>
          </div>
        </div>
        <CustomFooter history={this.props.history} />
      </>
    );
  }
}

export default Contacts;
