import { PlayerAction, BoardModel } from "../api/models";
import { GameColor } from '../api/enums';

export const buildBoard = (playerAction : PlayerAction, oldBoard : BoardModel, isWhite : boolean) => {
  playerAction.moves.forEach((move) => {
    if (move.from === null) {
      if (isWhite) {
        oldBoard.checkersOnBar.secondPlayer--;
      } else {
        oldBoard.checkersOnBar.firstPlayer--;
      }
    } else {
      oldBoard.lanes[move.from].checkers--;
      if (oldBoard.lanes[move.from].checkers === 0) {
        oldBoard.lanes[move.from].playerColor = null; //unassign point if there is no checker
      }
    }

    if (move.to === null) {
      if (isWhite) {
        oldBoard.outsideCheckers.secondPlayer++;
      } else {
        oldBoard.outsideCheckers.firstPlayer++;
      }
    } else {
      oldBoard.lanes[move.to].checkers++;
      oldBoard.lanes[move.to].playerColor = isWhite ? GameColor.Black : GameColor.White;
    }

    if (move.toBar) {
      oldBoard.lanes[move.to].checkers--;
      if (isWhite) {
        oldBoard.checkersOnBar.firstPlayer++;
      } else {
        oldBoard.checkersOnBar.secondPlayer++;
      }
    }
  });
  return oldBoard;
};
