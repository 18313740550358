import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

export interface IAlert {
  id?: number;
  variant: string;
  title?: string;
  message: string;
  dismissible?: boolean;
  autoHide?: boolean;
}

export const AlertVariant = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'info',
  light: 'light',
  dark: 'dark',
};

interface Props {
  addItem: (f) => void;
  delay?: number;
  maxCountAlerts: number;
}

interface State {
  alertList: IAlert[];
  delay: number;
}

class AlertsList extends Component<Props, State> {
  state = {
    alertList: [],
    delay: 5000,
  };

  componentDidMount() {
    this.props.addItem(this.addItem.bind(this));
    !!this.props.delay && this.setState({ delay: this.props.delay });
  }

  removeAlert(id) {
    this.setState({
      alertList: this.state.alertList.filter(item =>
        item.id !== id),
    });
  }

  addItem(newAlert) {
    const id = Math.random();
    this.setState({
      alertList: [...this.state.alertList, { ...newAlert, id }],
    });
    const autoHide = newAlert.autoHide || true;
    autoHide && setTimeout(() => this.removeAlert(id), this.state.delay);
  }

  render() {
    return (
      <div className={'alerts'}>
        {
          this.state.alertList.map((alert, i) => {
            return (
              <Alert
                key={i}
                variant={alert.variant}
                dismissible={alert.dismissible || true}
                onClose={() => this.removeAlert(alert.id)}
              >
                {alert.title && <Alert.Heading className={'h5'}>{alert.title}</Alert.Heading>}
                {alert.message}
              </Alert>
            );
          }).slice(0, this.props.maxCountAlerts)
        }
      </div>
    );
  }
}

export default AlertsList;
