import React, { Component } from 'react';
import Dice from '../Dice/Dice';

interface Props {
  isWhite: boolean;
  rollName: string;
}

interface State {
  dice1: number;
  dice2: number;
  timer: any;
}

class DiceRolls extends Component<Props, State> {
  state = {
    dice1: 1,
    dice2: 6,
    timer: null,
  };

  randomDice = () => {
    return Math.floor(Math.random() * 6) + 1;
  };

  componentDidMount() {
    this.setState({
      timer: setInterval(() => {
        this.setState({
          dice1: this.randomDice(),
          dice2: this.randomDice(),
        });
      }, 50),
    });
  }

  componentWillUnmount() {
    this.setState({
      timer: null,
    });
    clearInterval(this.state.timer);
  }

  render() {
    return (
      <div className="diceArea__wrapper">
        <div className="diceArea rolls">
          <Dice diceNumber="index" number={this.state.dice1} key={'dice-rolls' + 1} isWhite={this.props.isWhite} />
          <Dice diceNumber="index" number={this.state.dice2} key={'dice-rolls' + 2} isWhite={this.props.isWhite} />
        </div>
        <div className="text-center m-1 rolls__text">
          <strong>{this.props.rollName}</strong>
          <p>Rolls</p>
        </div>
      </div>
    );
  }
}

export default DiceRolls;
