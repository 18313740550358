import React from 'react';
import { ItemTypes } from './ItemTypes';
import { useDrag, DragSourceMonitor, DragPreviewImage } from 'react-dnd';

import Image from 'react-bootstrap/Image';
import pin1 from '../../assets/pin1.svg';
import pin2 from '../../assets/pin2.svg';
import { GameColor } from '../../api/enums';
import { SoundPlayer } from '../../sounds/soundPlayer';
import { Sound } from '../../sounds/soundEnum';

export interface DragCheckerProps {
  name?: string;
  canMove: boolean;
  replacerColor: boolean;
  player: GameColor;
  count: number;
  keycode: string;
  canDrag: boolean;
  canMoveEvent: () => void;
}

interface DropResult {
  allowedDropEffect: string;
  dropEffect: string;
  name: string;
}

interface DragItem {
  name: string;
  type: string;
  prev: string;
}

export const DragChecker: React.FC<DragCheckerProps> = ({
  name,
  children,
  canMove,
  replacerColor,
  player,
  count,
  keycode,
  canDrag,
  canMoveEvent,
}) => {
  const soundPlayer = SoundPlayer.getInstance();

  let containerClasses = 'checker-cont';

  if (canMove) {
    containerClasses += ' canMove';
  }

  const colorCheckerSrc = !replacerColor ? (player === GameColor.White ? pin1 : pin2) : player === GameColor.White ? pin2 : pin1;
  const item = { name: keycode, type: ItemTypes.BOX, prev: colorCheckerSrc };
  const [{ opacity, isDragging }, drag, preview] = useDrag({
    item,
    canDrag: canMove && canDrag,
    begin(monitor: DragSourceMonitor) {
      soundPlayer.play(Sound.Drag);
      canMoveEvent();
    },
    end(item: DragItem | undefined, monitor: DragSourceMonitor) {
      const dropResult: DropResult = monitor.getDropResult();
      if (dropResult === null) {
        soundPlayer.play(Sound.Drop);
        canMoveEvent();
      }
    },
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <>
      <DragPreviewImage connect={preview} src={colorCheckerSrc} />
      <div className={`${containerClasses} ${isDragging ? 'checker-cont--dragging': null}`} ref={drag}>
        <Image className="checker svg-checker" src={colorCheckerSrc} fluid />
        <div className="checker-num">{count > 1 ? count : ''}</div>
      </div>
    </>
  );
};
