import React, { Component } from 'react';
import { connect } from 'react-redux';
import DiceArea from '../DiceArea/DiceArea';
import getCheckers from '../getCheckers/getCheckers';

import { Lanes, Game, PlayerProfile, BarCheckers } from '../../api/models';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { GameStatus, GameColor, PositionType } from '../../api/enums';
import GrayBar from '../GrayBar/GrayBar';
import { ContentButton } from '../ContentButton/ContentButton';
import TimerProvider from '../../providers/TimerProvider';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { DONELIMIT } from '../../api/constants';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import DiceRolls from '../DiceArea/DiceRolls/DiceRolls';
import { DoublingCube } from '../DoublingCube/DoublingCube';
import { DropTriangle } from '../dnd/DropTriangle';
import { DropOutSideBar } from '../OutSideBar/DropOutSideBar';

interface Props {
  game: {
    opponentProfile: PlayerProfile;
    active: Game;
    dice: number[];
    opponentNoMove: boolean;
    points: Lanes;
    isMyTurn: boolean;
    status: GameStatus;
    outsideCheckers: BarCheckers;
    checkersOnBar: BarCheckers;
  };
  myColor: GameColor;
  replacerColor: boolean;
  opponentColor: GameColor;
  currentPosition: number;
  parentRef: React.RefObject<HTMLDivElement>;
  isRotated: boolean;
  isBot?: boolean;
  disabledCube: boolean;
  maxDisableCube: boolean;
  possiblyUseDoublingCube: boolean;
  hideCube: boolean;
  doneClicked: boolean;
  canDrag: boolean;
  newGameHandler: () => void;
  rollDice: () => void;
  goFirst: () => void;
  doneHandler: () => void;
  undoHandler: () => void;
  isOverHandler: () => void;
  cubeHandler: () => void;
}

class Board extends Component<Props> {
  render() {
    const { game, myColor, opponentColor, currentPosition, isRotated, isBot, doneClicked } = this.props;
    let rigthDiceArea = null;
    let leftDiceArea = null;

    if (game.status === GameStatus.GamePreparation) {
      leftDiceArea = (
        <DiceArea
          clicked={this.props.goFirst}
          gameStatus={this.props.game.status}
          isWhite={!this.props.replacerColor ? game.active.isMyFirstTurn : !game.active.isMyFirstTurn}
        />
      );
    }
    if (this.props.game.status === GameStatus.PlayersRollDice) {
      // --- First dice ---
      const rightFirstDice = game.active.meIsPlayer1 ? [game.active.firstDicePlayer1] : [game.active.firstDicePlayer2];
      const leftFirstDice = !game.active.meIsPlayer1 ? [game.active.firstDicePlayer1] : [game.active.firstDicePlayer2];

      rigthDiceArea = (
        <DiceArea
          dice={rightFirstDice}
          clicked={this.props.newGameHandler}
          gameStatus={game.status}
          isWhite={myColor === GameColor.White}
        />
      );
      leftDiceArea = (
        <DiceArea
          dice={leftFirstDice}
          clicked={this.props.newGameHandler}
          gameStatus={game.status}
          isWhite={opponentColor === GameColor.White}
        />
      );
    }
    if (game.status > GameStatus.PlayersRollDice && game.status < GameStatus.PlayerWin) {
      // --- Roll dice for Me ---
      rigthDiceArea = game.isMyTurn ? (
        <DiceArea dice={game.dice} clicked={this.props.rollDice} gameStatus={game.status} isWhite={myColor === GameColor.White} />
      ) : (
        ''
      );

      // --- Roll dice for Opponent ---
      if (!game.isMyTurn) {
        leftDiceArea =
          game.status === GameStatus.RollDice ? (
            <DiceArea
              dice={game.dice}
              clicked={this.props.rollDice}
              gameStatus={GameStatus.OpponentDice}
              isWhite={myColor !== GameColor.White}
            />
          ) : (
            <DiceRolls
              rollName={
                isBot ? 'Gammon Bot' : game.opponentProfile ? game.opponentProfile.displayName || game.opponentProfile.email : ''
              }
              isWhite={myColor !== GameColor.White}
            />
          );
      }
    }

    // --- No Moves for Me ---
    if (game.status === GameStatus.NoMoveAvailable) {
      rigthDiceArea = (
        <DiceArea dice={game.dice} clicked={this.props.rollDice} gameStatus={game.status} isWhite={myColor === GameColor.White} />
      );
      leftDiceArea = '';
    }

    // --- No Moves for Opponent ---
    if (game.opponentNoMove) {
      rigthDiceArea = '';
      leftDiceArea = (
        <DiceArea
          dice={game.dice}
          clicked={this.props.rollDice}
          gameStatus={game.status}
          isWhite={myColor !== GameColor.White}
          opponentNoMove={game.opponentNoMove}
        />
      );
    }

    let doneButton = !game.dice.length ? (
      <ContentButton variant="app-lite-green" text="Done" className={'done-button'} disabled={doneClicked} btnClick={this.props.doneHandler}>
        <TimerProvider
          startTimeInSeconds={DONELIMIT}
          interval={1000}
          autoStart={true}
          showAlways={true}
          isOver={this.props.isOverHandler}
        >
          {(sec: number) => (
            <CircularProgressbarWithChildren
              className="timer-bar"
              maxValue={DONELIMIT}
              value={sec}
              background
              backgroundPadding={0}
              strokeWidth={10}
              styles={{
                root: {},
                path: {
                  stroke: 'white',
                  strokeLinecap: 'butt',
                  transition: 'stroke-dashoffset 0.5s ease 0s',
                  transform: 'rotate(0.25turn)',
                  transformOrigin: 'center center',
                },
                trail: {
                  stroke: 'transparent',
                },
                text: {
                  fill: 'white',
                  fontSize: '3vw',
                  fontFamily: 'Roboto Slab',
                  marginTop: -5,
                },
                background: {
                  fill: '#FFAD31',
                  padding: 0,
                },
              }}
            >
              <div style={{ marginTop: '43%', fontSize: '0.8vw' }}>{sec.toString()}</div>
            </CircularProgressbarWithChildren>
          )}
        </TimerProvider>
      </ContentButton>
    ) : null;

    let undoButton = currentPosition ? (
      <ContentButton variant="app-red" text="Undo" className={'undo-button'} disabled={doneClicked} btnClick={this.props.undoHandler}>
        <ArrowIcon className="h-100 w-100" />
      </ContentButton>
    ) : null;

    let actionButtons =
      game.isMyTurn &&
      (game.status === GameStatus.Playing ||
        game.status === GameStatus.FromCheckersOnBar ||
        game.status === GameStatus.BearingOff) ? (
        <div className="buttons-area d-flex flex-column justify-content-center align-items-end">
          {doneButton}
          {undoButton}
        </div>
      ) : null;

    const isBoardClockWise = isRotated === game.active.isMyFirstTurn;

    let boardMap = isBoardClockWise
      ? [
          [12, 13, 14, 15, 16, 17],
          [18, 19, 20, 21, 22, 23],
          [11, 10, 9, 8, 7, 6],
          [5, 4, 3, 2, 1, 0],
        ]
      : [
          [11, 10, 9, 8, 7, 6],
          [5, 4, 3, 2, 1, 0],
          [12, 13, 14, 15, 16, 17],
          [18, 19, 20, 21, 22, 23],
        ];

    if (isRotated) {
      boardMap = isBoardClockWise
        ? [
            [0, 1, 2, 3, 4, 5],
            [6, 7, 8, 9, 10, 11],
            [23, 22, 21, 20, 19, 18],
            [17, 16, 15, 14, 13, 12],
          ]
        : [
            [23, 22, 21, 20, 19, 18],
            [17, 16, 15, 14, 13, 12],
            [0, 1, 2, 3, 4, 5],
            [6, 7, 8, 9, 10, 11],
          ];
    }

    return (
      <div id="board" className="container-fluid d-flex h-100 p-0 m-0">
        <Container id="leftSide">
          <div className="wood-logo"></div>
          <Row className="flex-nowrap blocksUp lanes-bg-up">
            {boardMap[0].map((el, i) => {
              return (
                <DropTriangle
                  key={'boardMap0' + i}
                  color={i % 2 ? GameColor.White : GameColor.Black}
                  position={PositionType.Top}
                  canMove={game.points[el].canMove}
                  canReceive={game.points[el].canReceive}
                  allowedDropEffect="any"
                >
                  {getCheckers(
                    game.points[el].playerColor,
                    game.points[el].checkers,
                    'board',
                    game.points[el].canMove,
                    this.props.replacerColor,
                    this.props.canDrag
                  )}
                </DropTriangle>
              );
            })}
          </Row>
          {leftDiceArea}
          <Row className="flex-nowrap blocksDown lanes-bg-down">
            {boardMap[2].map((el, i) => {
              return (
                <DropTriangle
                  key={'boardMap2' + i}
                  color={i % 2 ? GameColor.White : GameColor.Black}
                  position={PositionType.Bottom}
                  canMove={game.points[el].canMove}
                  canReceive={game.points[el].canReceive}
                  allowedDropEffect="any"
                >
                  {getCheckers(
                    game.points[el].playerColor,
                    game.points[el].checkers,
                    'board',
                    game.points[el].canMove,
                    this.props.replacerColor,
                    this.props.canDrag
                  )}
                </DropTriangle>
              );
            })}
          </Row>

          <div className="shadowBox"></div>
        </Container>
        <Container id="middleSide">
          <DoublingCube
            show={this.props.game.active.doublingCube > 1 && !this.props.possiblyUseDoublingCube && !this.props.hideCube}
            disabledCube={this.props.disabledCube}
            maxDisableCube={this.props.maxDisableCube}
            valueCube={this.props.game.active.doublingCube}
            cube={this.props.cubeHandler}
          />
          <div className="hinge hinge__top"></div>
          <GrayBar checkers={game.checkersOnBar} replacerColor={this.props.replacerColor} />
          <div className="hinge hinge__down"></div>
          <DoublingCube
            show={this.props.game.active.doublingCube > 1 && this.props.possiblyUseDoublingCube && !this.props.hideCube}
            disabledCube={this.props.disabledCube}
            maxDisableCube={this.props.maxDisableCube}
            valueCube={this.props.game.active.doublingCube}
            cube={this.props.cubeHandler}
          />
        </Container>

        <Container id="rightSide">
          <div className="wood-logo"></div>
          {actionButtons}

          <Row className="flex-nowrap blocksUp lanes-bg-up">
            {boardMap[1].map((el, i) => {
              return (
                <DropTriangle
                  key={'boardMap1' + i}
                  color={i % 2 ? GameColor.White : GameColor.Black}
                  position={PositionType.Top}
                  canMove={game.points[el].canMove}
                  canReceive={game.points[el].canReceive}
                  allowedDropEffect="any"
                >
                  {getCheckers(
                    game.points[el].playerColor,
                    game.points[el].checkers,
                    'board',
                    game.points[el].canMove,
                    this.props.replacerColor,
                    this.props.canDrag
                  )}
                </DropTriangle>
              );
            })}
          </Row>
          {rigthDiceArea}
          <Row className="flex-nowrap blocksDown lanes-bg-down">
            {boardMap[3].map((el, i) => {
              return (
                <DropTriangle
                  key={'boardMap3' + i}
                  color={i % 2 ? GameColor.White : GameColor.Black}
                  position={PositionType.Bottom}
                  canMove={game.points[el].canMove}
                  canReceive={game.points[el].canReceive}
                  allowedDropEffect="any"
                >
                  {getCheckers(
                    game.points[el].playerColor,
                    game.points[el].checkers,
                    'board',
                    game.points[el].canMove,
                    this.props.replacerColor,
                    this.props.canDrag
                  )}
                </DropTriangle>
              );
            })}
          </Row>
          <div className="shadowBox"></div>
        </Container>
        <DropOutSideBar
          ismyFirstTurn={game.active.isMyFirstTurn}
          isRotated={this.props.isRotated}
          checkers={game.outsideCheckers}
          currentPosition={this.props.currentPosition}
          replacerColor={this.props.replacerColor}
          valueCube={this.props.game.active.doublingCube}
          disabledCube={this.props.disabledCube}
          maxDisableCube={this.props.maxDisableCube}
          cube={this.props.cubeHandler}
          allowedDropEffect="any"
        />

        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = ({ game }) => {
  return {
    game: {
      opponentProfile: game.opponentProfile,
      isMyTurn: game.isMyTurn,
      active: game.activeGame,
      status: game.gameStatus,
      points: game.points,
      dice: game.dice,
      checkersOnBar: game.checkersOnBar,
      outsideCheckers: game.outsideCheckers,
      opponentNoMove: game.opponentNoMove,
    },
    isRotated: game.isRotated,
    doneClicked: game.doneClicked,
  };
};

export default connect(mapStateToProps)(Board);
