import React, { FunctionComponent } from 'react';
import { CheckerFlatPlayer } from '../../../api/enums';

type Props = {
  player: CheckerFlatPlayer;
  replacerColor: boolean;
};

export const CheckerFlat: FunctionComponent<Props> = ({ player, replacerColor }) => {
  let flatNum: string;

  if (!replacerColor) {
    flatNum = player === CheckerFlatPlayer.P1 ? CheckerFlatPlayer.P1 : CheckerFlatPlayer.P2;
  } else {
    flatNum = player === CheckerFlatPlayer.P1 ? CheckerFlatPlayer.P2 : CheckerFlatPlayer.P1;
  }

  return <div className={`checkerFlat ${flatNum}`} />;
};
