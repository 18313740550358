import firebase from 'firebase';

// export const firebase = {
//   apiKey: "AIzaSyCPRSMyMBfhb_TVayA8XXx3mSQubBsLbmE",
//   authDomain: "backgammon-firebase-fc9a1.firebaseapp.com",
//   databaseURL: "https://backgammon-firebase-fc9a1.firebaseio.com",
//   projectId: "backgammon-firebase-fc9a1",
//   storageBucket: "backgammon-firebase-fc9a1.appspot.com",
//   messagingSenderId: "30723634328",
//   appId: "1:30723634328:web:3e462935badcd5e8a5cced",
//   measurementId: "G-2FJZ3YTVN2"
// }

const firebaseConfigDev = {
  apiKey: "AIzaSyAWVIyai32ARzuoKHqjQJLYhiZK1ukE3L0",
  authDomain: "backgammon-tst.firebaseapp.com",
  databaseURL: "https://backgammon-tst.firebaseio.com",
  projectId: "backgammon-tst",
  storageBucket: "backgammon-tst.appspot.com",
  messagingSenderId: "363583421187",
  appId: "1:363583421187:web:818e1ac24eeea2d2c00736",
  measurementId: "G-RCJNM4M5Z7"
}

const firebaseConfigProdOld = {
  apiKey: "AIzaSyBFQxm3CuY8-4tCfH1U3pVTEKLlWooXYUM",
  authDomain: "gammonpro-abc18.firebaseapp.com",
  databaseURL: "https://gammonpro-abc18.firebaseio.com",
  projectId: "gammonpro-abc18",
  storageBucket: "gammonpro-abc18.appspot.com",
  messagingSenderId: "834018142544",
  appId: "1:834018142544:web:2fd5c5f1f28c05b42d2455",
  measurementId: "G-QW5QG1VZ4R"
}

const firebaseConfigProd = {
  apiKey: "AIzaSyBBy5cIqaBdtEIpaQu96R0y4LgQ280JnjM",
  authDomain: "gammonpro-1ef23.firebaseapp.com",
  databaseURL: "https://gammonpro-1ef23.firebaseio.com",
  projectId: "gammonpro-1ef23",
  storageBucket: "gammonpro-1ef23.appspot.com",
  messagingSenderId: "792552249009",
  appId: "1:792552249009:web:b89987b4e4e4298085b490",
  measurementId: "G-N1QFGKNGDM"
}
export const firebaseConfig = (process.env.REACT_APP_PROD === '1') ? firebaseConfigProd : firebaseConfigDev;

export const reduxFirebase = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableLogging: false
}

// Configure FirebaseUI.
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};

export default { firebaseConfig, reduxFirebase }
