import React, { Component } from 'react';
import getCheckers from '../getCheckers/getCheckers';
import { GameColor } from '../../api/enums';
import { BarCheckers } from '../../api/models';


interface Props {
  checkers: BarCheckers;
  replacerColor: boolean;
}

class GrayBar extends Component<Props> {
  shouldComponentUpdate(nextProps, nextState) {
    let propsChanged = false;

    if (
      nextProps.checkers.firstPlayer !== this.props.checkers.firstPlayer ||
      nextProps.checkers.secondPlayer !== this.props.checkers.secondPlayer
    ) {
      propsChanged = true;
    }

    return propsChanged;
  }

  render() {
    const firstPlayer = getCheckers(GameColor.White, this.props.checkers.firstPlayer, 'Graybar', false, this.props.replacerColor, true);
    const secondPlayer = getCheckers(GameColor.Black, this.props.checkers.secondPlayer, 'Graybar', false, this.props.replacerColor, true);

    return (
      <div id="grayBar" className="row">
        <div className="blocksUp">
          <div className="pointContainer pointContainerDown">{firstPlayer}</div>
        </div>
        <div className="blocksDown">
          <div className="pointContainer">{secondPlayer}</div>
        </div>
      </div>
    );
  }
}

export default GrayBar;
