import React, { FunctionComponent } from 'react';
import { Icon } from '../Icon/Icon';
import { IconType } from '../../api/enums';
import Button from 'react-bootstrap/Button';

type CircleBtnProps = {
  variant: string;
  iconType: IconType;
  text: string;
  disabled?: boolean;
  checked?: boolean;
  btnClick?: () => void;
};

export const CircleButton: FunctionComponent<CircleBtnProps> = ({ variant, iconType, text, disabled = false, checked, btnClick, ...props }) => {
  return (
    <div className={`circle-button ${checked ? 'circle-button--checked' : ''}`}>
      <Button className="circle-button__content" variant={variant} disabled={disabled} onClick={btnClick}>
        <div className="circle-button__content__icon">
          <Icon iconType={iconType} />
        </div>
      </Button>
      <div className={`circle-button__text ${disabled ? 'circle-button__text__disabled' : ''}`}>{text}</div>
    </div>
  );
};
