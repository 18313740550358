import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as signalR from '@microsoft/signalr';
import './App.scss';
import { FirebaseReducer } from 'react-redux-firebase/index.d';

import Board from '../components/Board/Board';
import UserInfo from '../components/UserInfo/UserInfo';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-circular-progressbar/dist/styles.css';
import RangeSlider from 'react-bootstrap-range-slider';

import { BsFillVolumeDownFill, BsFillVolumeUpFill } from 'react-icons/bs';

import {
  setActiveGame,
  changeGameStatus,
  changeGamePoints,
  prepareNewGame,
  setupNewGame,
  getRollDiceValues,
  changeDiceValues,
  setIsMyTurn,
  updateBoard,
  updateMoves,
  changeGrayBar,
  changeOutSideBar,
  joinLobby,
  getLobby,
  performPlayerTurn,
  changeFlagNoMove,
  clearGame,
  leaveGame,
  leaveLobby,
  getOpponentProfile,
  playWithBot,
  offerDoubleStake,
  acceptDubleStake,
  changeDoublingCube,
  changeDoneClicked,
} from '../redux/game/gameActions';
import {
  PlayerProfile,
  Lanes,
  Game,
  Token,
  BarCheckers,
  BoardModel,
  Move,
  GameLobby,
  PlayerAction,
  Dices,
  GameApi,
  DoubleStakeOfferedHubModel,
  DoubleStakeAcceptedHubModel,
} from '../api/models';
import { GameStatus, GameColor, IconType } from '../api/enums';
import { BOARD_CONST, FIRST_TIMELIMIT, FULLOUTSIDE, PLAYBOTROUTE, START_HELP_TIME, TIMELIMIT } from '../api/constants';

import {
  getGameStatusDescription,
  getPointsWithoutActions,
  isEveryoneInHomeSquare,
  getOutSideBarWithoutActions,
  getMovingChecker,
  getPlayer,
  checkCanBearOff,
  buildBoard,
} from '../helpers';
import GameOver from '../components/GameOver/GameOver';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AppModal } from '../components/Modals/AppModal';
import { CircleButton } from '../components/CircleButton/CircleButton';
import { FullScreenHandle } from 'react-full-screen';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import logoGPImg from '../assets/logo-gp.png';
import rotationImg from '../assets/screen_rotation.svg';
import CustomHeader from '../components/CustomHeader/CustomHeader';
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg';
import SidebarMenu from '../components/SidebarMenu/SidebarMenu';
import { GameInfoModal } from '../components/Modals/GameInfoModal';
import { SoundPlayer } from '../sounds/soundPlayer';
import { Sound } from '../sounds/soundEnum';
import { DebugVariables } from '../components/Debug/DebugVariables';
import Modals from './modals';
import { calculateScore } from './helper';

const URL = process.env.REACT_APP_API_URL;

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  // match: PropTypes.object.isRequired,
  // location: PropTypes.object.isRequired,
  // history: PropTypes.object.isRequired

  game: {
    opponentProfile: PlayerProfile;
    isMyTurn: boolean;
    active: Game;
    status: GameStatus;
    points: Lanes;
    dice: number[];
    checkersOnBar: BarCheckers;
    outsideCheckers: BarCheckers;
    moves: Move[];
    opponentNoMove: boolean;
    myColor: GameColor;
    opponentColor: GameColor;
    currentPlayerId: string;
    isRotated: boolean;
  };
  user: {
    profile: PlayerProfile;
    token: Token;
    isNewUser: boolean;
  };
  lobbyList: GameLobby[];
  activeLobby: GameLobby;
  auth: FirebaseReducer.AuthState;

  isLandscape: boolean;
  isPortrait: boolean;
  isMobileOnly: boolean;
  isTablet: boolean;

  handleFS: FullScreenHandle;

  playWithBot: (userId: string) => Promise<any>;
  changeGameStatus: (statusGame: GameStatus) => void; // TODO: enum
  changeDiceValues: (dice: number[]) => void;
  setIsMyTurn: (isNext: boolean) => void;
  updateBoard: (board: BoardModel) => void;
  updateMoves: (moves: Move[]) => Promise<any>;
  changeGrayBar: (checkers: BarCheckers) => void;
  changeOutSideBar: (checkers: BarCheckers) => void;
  prepareNewGame: () => void;
  clearGame: () => void;
  setActiveGame: (game: Game) => void;
  joinLobby: (lobbyId: number, isRotated: boolean) => Promise<any>;
  setupNewGame: () => void;
  getRollDiceValues: (userId: string) => Promise<any>;
  getLobby: (id: number) => Promise<GameLobby>;
  changeGamePoints: (points: Lanes[]) => void;
  playerTurn: (playerAction: PlayerAction) => Promise<any>;
  changeFlagNoMove: (opponentNoMove: boolean) => void;
  leaveGame: () => void;
  leaveLobby: () => void;
  getOpponentProfile: (playerId: string) => Promise<PlayerProfile>;
  offerDoubleStake: () => void;
  acceptDubleStake: () => void;
  changeDoublingCube: (value: number) => void;
  changeDoneClicked: (clicked: boolean) => void;
}

interface State {
  history: any;
  currentPosition: number;
  movingChecker: boolean;
  hubConnection: any;
  leaveModalShow: boolean;
  gameInfoModalShow: boolean;
  acceptInfoModalShow: boolean;
  helpModalShow: boolean;
  doubleStakeOfferedModalShow: boolean;
  waitingAnswerModalShow: boolean;
  showDebug: boolean;
  timerEnabed: boolean;
  debugDice: number;
  boardWidth: number;
  boardHeight: number;
  mySidebarShow: boolean;
  opponentSidebarShow: boolean;
  possiblyUseDoublingCube: boolean;
  isFirstHelp: boolean;
  isAutoRoll: boolean;
  player: SoundPlayer;
  volume: number;
  canDrag: boolean;
}

class App extends Component<Props, State> {
  getInitState = () => {
    return {
      history: [],
      currentPosition: 0,
      movingChecker: false,
      hubConnection: null,
      leaveModalShow: false,
      doubleStakeOfferedModalShow: false,
      waitingAnswerModalShow: false,
      gameInfoModalShow: false,
      acceptInfoModalShow: false,
      helpModalShow: false,
      showDebug: process.env.REACT_APP_SHOW_DEBUG === '0',
      timerEnabed: process.env.REACT_APP_PROD === '1',
      debugDice: 1,
      boardWidth: 0,
      boardHeight: 0,
      mySidebarShow: false,
      opponentSidebarShow: false,
      possiblyUseDoublingCube: true,
      isFirstHelp: true,
      isAutoRoll: false,
      player: SoundPlayer.getInstance(),
      volume: SoundPlayer.getInstance().getVolume(),
      canDrag: true
    };
  };

  state = this.getInitState();

  parentRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.props.match.params.id === PLAYBOTROUTE) {
      this.buildConnection();
    } else if (this.props.match && this.props.match.params && this.props.match.params.id && this.props.user.profile.userId) {
      this.props.changeGameStatus(GameStatus.CreatedLobby);
      this.props.getLobby(parseInt(this.props.match.params.id)).then((res: GameLobby) => this.buildConnection());
    }
  }

  componentWillUnmount() {
    if (this.state.hubConnection) {
      this.state.hubConnection.stop();
    } else {
      if (this.props.game.active.gameId) {
        this.props.leaveGame();
      }
    }
  }

  onDiceReceived = (res: Dices) => {
    console.log('SignalR: on(RollDice)', res);
    if (res.userId) {
      if (res.userId === this.props.user.profile.userId) {
        //is my dice
        this.myMove(res.dices);
      } else {
        if (this.props.game.status < GameStatus.NewGame) {
          this.setupNewGameHandler();
        }
        this.props.changeGameStatus(GameStatus.RollDice); // reset 50
        this.rollDiceOpponentHandler(res.dices);
      }
    }
  };

  onMovesReceived = (playerAction: PlayerAction) => {
    console.log('SignalR: on(UpdateMoves)', playerAction);
    if (playerAction.currentPlayerId === this.props.user.profile.userId) {
      this.props.setIsMyTurn(false);
    } else {
      const oldBoard = {
        currentPlayerId: this.props.user.profile.userId,
        lanes: getPointsWithoutActions(this.props.game.points),
        checkersOnBar: { ...this.props.game.checkersOnBar },
        outsideCheckers: getOutSideBarWithoutActions(this.props.game.outsideCheckers),
      };
      const isMyFirstTurn = this.props.game.active.isMyFirstTurn;
      const newBoard = buildBoard(playerAction, oldBoard, isMyFirstTurn);

      this.props.updateBoard(newBoard);
      this.props.changeGrayBar(newBoard.checkersOnBar);
      this.props.changeOutSideBar(newBoard.outsideCheckers);

      if (this.state.isAutoRoll) {
        this.rollDiceHandler();
      }

      this.opponentNoMoveHandler(playerAction.moves);
      let time = 0;
      if (this.props.game.opponentNoMove) {
        time = 1500;
      }
      setTimeout(() => {
        this.props.changeFlagNoMove(false); //reset flag
        this.props.changeDiceValues([0]);
        this.props.setIsMyTurn(true);
      }, time);
    }
  };

  onGameStartReceived = (game: GameApi) => {
    console.log('SignalR: on(GameStarted)', game);
    this.state.player.play(Sound.Dice);
    // this.setState(this.getInitState());
    this.props.prepareNewGame();
    this.props.setActiveGame(game as Game);
    if (this.props.game.active.meIsPlayer1) {
      this.props.getOpponentProfile(game.player2);
    }

    setTimeout(() => {
      if (this.props.game.status !== GameStatus.NewGame) this.setupNewGameHandler();
    }, 2000);
  };

  onGameOverReceived = (gameOver: any) => {
    console.log('SignalR: on(GameOver)', gameOver);
    this.setDoubleStakeOfferedModalShow(false);
    if (gameOver.winnerId === this.props.user.profile.userId) {
      this.state.player.play(Sound.Win);
      this.setGameInfoModalShow(false);
      this.props.changeGameStatus(GameStatus.PlayerWin);
    } else {
      this.state.player.play(Sound.Lose);
      this.setGameInfoModalShow(false);
      this.props.changeGameStatus(GameStatus.PlayerLose);
    }
  };

  onDoubleStakeOffered = (model: DoubleStakeOfferedHubModel) => {
    console.log('SignalR: on(DoubleStakeOffered)', model);
    if (this.props.user.profile.userId === model.targetUserId) {
      this.setDoubleStakeOfferedModalShow(true);
      this.changePossiblyUseDoublingCube(true);
    } else if (this.props.user.profile.userId === model.offeredUserId) {
      this.setWaitingAnswerModalShow(true);
      this.changePossiblyUseDoublingCube(false);
    }
  };

  onDoubleStakeAccepted = (model: DoubleStakeAcceptedHubModel) => {
    console.log('SignalR: on(DoubleStakeAccepted)', model);
    this.setWaitingAnswerModalShow(false);
    this.props.changeDoublingCube(model.acceptedStake);

    if (this.props.user.profile.userId === model.offeredUserId) {
      this.setAcceptInfoModalShow(true);
      setTimeout(() => {
        this.setAcceptInfoModalShow(false);
      }, 2000);
    }
  };

  onDoubleStakeRejected = (model: any) => {
    console.log('SignalR: on(DoubleStakeRejected)', model);
  };

  okGameOverHandler = () => {
    this.setState(this.getInitState());
    this.props.clearGame();
    this.props.history.push('/Lobbies');
  };

  buildConnection = () => {
    this.setState(
      {
        hubConnection: new signalR.HubConnectionBuilder()
          .withUrl(`${URL}game`, {
            accessTokenFactory: () => this.props.user.token.accessToken,
          })
          .withAutomaticReconnect()
          .build(),
      },
      () => {
        const connection = this.state.hubConnection;
        connection.start().then(() => {
          if (this.props.match.params.id === PLAYBOTROUTE) {
            this.props.playWithBot(this.props.user.profile.userId);
          } else if (!this.props.game.active.meIsPlayer1) {
            this.props.joinLobby(parseInt(this.props.match.params.id), this.props.game.isRotated).catch(err => {
              if (err.response) {
                this.okGameOverHandler();
              }
            });
          }
        });
        connection.on('RollDice', this.onDiceReceived);
        connection.on('PlayerTurn', this.onMovesReceived);
        connection.on('GameStarted', this.onGameStartReceived);
        connection.on('GameOver', this.onGameOverReceived);
        connection.on('DoubleStakeOffered', this.onDoubleStakeOffered);
        connection.on('DoubleStakeAccepted', this.onDoubleStakeAccepted);
        connection.on('DoubleStakeRejected', this.onDoubleStakeRejected);
      }
    );
  };

  onLoginSuccess = () => {
    this.buildConnection();
  };

  setLeaveModalShow = (leaveModalShow: boolean) => this.setState({ leaveModalShow, gameInfoModalShow: false });
  setDoubleStakeOfferedModalShow = (doubleStakeOfferedModalShow: boolean) => this.setState({ doubleStakeOfferedModalShow });
  setWaitingAnswerModalShow = (waitingAnswerModalShow: boolean) => this.setState({ waitingAnswerModalShow });
  setGameInfoModalShow = (gameInfoModalShow: boolean) => this.setState({ gameInfoModalShow });
  setAcceptInfoModalShow = (acceptInfoModalShow: boolean) => this.setState({ acceptInfoModalShow });
  setHelpModalShow = (helpModalShow: boolean) => this.setState({ helpModalShow, isFirstHelp: false });
  changePossiblyUseDoublingCube = (possiblyUseDoublingCube: boolean) => this.setState({ possiblyUseDoublingCube });
  changeAutoRoll = () => this.setState({ isAutoRoll: !this.state.isAutoRoll });

  toggleDebug = () => this.setState({ showDebug: !this.state.showDebug });

  secondaryHandler = () => {
    this.setLeaveModalShow(false);
  };

  primaryHandler = () => {
    this.props.leaveGame();
    this.setLeaveModalShow(false);
  };

  cancelGameHandler = () => {
    this.props.leaveLobby();
    this.okGameOverHandler();
  };

  //set up new game
  setupNewGameHandler = () => {
    // Roll die again
    if (this.props.game.active.firstDicePlayer1 === this.props.game.active.firstDicePlayer2) {
      this.goFirstHandler();
      return;
    }
    this.setGameInfoModalShow(true);
    setTimeout(() => {
      this.setGameInfoModalShow(false);
    }, START_HELP_TIME);

    const gameStatus = GameStatus.NewGame;
    const history = [];
    const currentPosition = 0;
    const dice = [0];
    const movingChecker = false;

    this.setState({
      history: history,
      currentPosition: currentPosition,
      movingChecker: movingChecker,
    });

    this.props.changeOutSideBar({ firstPlayer: 0, secondPlayer: 0 });
    this.props.setupNewGame();
    this.props.changeGameStatus(gameStatus);
    // this.props.firstRollDice();
    if (this.props.game.isMyTurn) {
      history.push(
        this.setHistory(
          this.props.game.isMyTurn,
          dice,
          this.props.game.points,
          this.props.game.checkersOnBar,
          this.props.game.outsideCheckers,
          this.props.game.moves
        )
      );
    }

    if (this.state.isAutoRoll && this.props.game.active.firstMovePlayerId === this.props.user.profile.userId) {
      this.rollDiceHandler();
    }
  };

  //Set new history
  setHistory = (isMyTurn, dice, points, checkersOnBar, outsideCheckers, moves, gameStatus = null) => {
    const history = {
      isMyTurn: isMyTurn,
      dice: [...dice],
      points: [...points],
      checkersOnBar: { ...checkersOnBar },
      outsideCheckers: { ...outsideCheckers },
      moves: moves,
      gameStatus: gameStatus,
    };
    return history;
  };

  //Roll first die
  goFirstHandler = () => {
    this.props.changeGameStatus(GameStatus.PlayersRollDice);
    console.log('Roll first die ', this.props.game.active.isMyFirstTurn);
  };

  //Roll dices
  rollDiceHandler = () => {
    this.state.player.play(Sound.Dice);
    this.props.getRollDiceValues(this.props.user.profile.userId).then((res) => {});
  };

  //Roll dices from opponent
  rollDiceOpponentHandler = (dice) => {
    this.state.player.play(Sound.Dice);
    this.props.changeDiceValues(dice);
  };

  opponentNoMoveHandler = (moves) => {
    const dice = [...this.props.game.dice];
    const outsideCheckers = getOutSideBarWithoutActions(this.props.game.outsideCheckers);
    if (dice[0] === dice[1]) {
      dice[2] = dice[3] = dice[0];
    }
    if (
      moves.length !== dice.length &&
      outsideCheckers.firstPlayer !== FULLOUTSIDE &&
      outsideCheckers.secondPlayer !== FULLOUTSIDE
    ) {
      console.log('opponentNoMoveHandler');

      this.props.changeFlagNoMove(true);
    }
  };

  myMove(dices) {
    const isMyTurn = this.props.game.status === GameStatus.NoMoveAvailable ? !this.props.game.isMyTurn : this.props.game.isMyTurn;
    //new dice with two random numbers
    const dice = dices;
    //duplicate numbers if the same
    if (dice[0] === dice[1]) {
      dice[2] = dice[3] = dice[0];
    }

    this.props.changeDiceValues(dice);

    //Get moves and status
    const moves = this.calculateCanMove(
      getPointsWithoutActions(this.props.game.points),
      dice,
      this.props.game.active.isMyFirstTurn,
      this.props.game.checkersOnBar
    );

    //get points and status
    const points = moves.points;
    let gameStatus = moves.gameStatus;

    //reset history
    const currentPosition = 0;
    const history = [];
    //Save current state into history
    history.push(
      this.setHistory(
        this.props.game.active.isMyFirstTurn,
        dice,
        points,
        this.props.game.checkersOnBar,
        this.props.game.outsideCheckers,
        this.props.game.moves,
        gameStatus
      )
    );

    //Set new state
    this.setState({
      history: history,
      currentPosition: currentPosition,
    });

    if (gameStatus === GameStatus.NoMoveAvailable) {
      // CHANGE PLAYER
      console.log('changePlayerNoMove()');
      this.changePlayerNoMove();
    }

    const outsideCheckers = getOutSideBarWithoutActions(this.props.game.outsideCheckers);

    if (outsideCheckers.firstPlayer === FULLOUTSIDE || outsideCheckers.secondPlayer === FULLOUTSIDE) {
      console.log('myMove');
      gameStatus = GameStatus.NoDiceToPlay;
      this.doneHandler();
    }

    this.props.changeGameStatus(gameStatus);
    this.props.changeGamePoints(points);
  }

  //Calculate possible moves return an object with points and game status
  calculateCanMove = (points, dice, isMyTurn, checkersOnBar) => {
    let newPoints = [...points];
    let gameStatus = GameStatus.NoMoveAvailable;

    if (!dice[0]) {
      gameStatus = GameStatus.NoDiceToPlay;
    } else {
      //check if there is checker on gray Bar
      if ((isMyTurn && checkersOnBar.firstPlayer) || (!isMyTurn && checkersOnBar.secondPlayer)) {
        for (let die of dice) {
          const destination = isMyTurn ? die - 1 : 24 - die;
          if (
            points[destination].playerColor === getPlayer(isMyTurn) || //point belongs to user
            points[destination].checkers < 2
          ) {
            //point is empty or belongs to other user but with only one checker
            newPoints[destination].canReceive = this.receiveCheckerHandler.bind(this, die);
            gameStatus = GameStatus.FromCheckersOnBar;
          }
        }
      } else {
        const inHomeBoard = isEveryoneInHomeSquare(newPoints, isMyTurn);

        //get points with actions
        for (let index = 0; index < points.length; index++) {
          let canMove = false;

          //Check if checker can move
          if (points[index].playerColor === getPlayer(isMyTurn)) {
            for (let die of dice) {
              const destination = isMyTurn ? index + die : index - die;
              if (!canMove && destination < 24 && destination >= 0) {
                if (points[destination].playerColor === getPlayer(isMyTurn) || points[destination].checkers < 2) {
                  canMove = true;
                  gameStatus = GameStatus.Playing;
                }
              }
            }
          }

          if (inHomeBoard && ((isMyTurn && index >= 18) || (!isMyTurn && index <= 5))) {
            if (checkCanBearOff(points, index, isMyTurn, dice)) {
              canMove = true;
              gameStatus = GameStatus.BearingOff;
            }
          }

          if (canMove) {
            newPoints[index].canMove = this.moveCheckerHandler.bind(this, index);
          }
        }
      }
    }
    return { points: newPoints, gameStatus: gameStatus };
  };

  //Set moving checker
  moveCheckerHandler = (checker) => {
    let gameStatus = GameStatus.Playing;
    const isMyFirstTurn = this.props.game.active.isMyFirstTurn;
    //Get outsideCheckers without actions
    const outsideCheckers = getOutSideBarWithoutActions(this.props.game.outsideCheckers);
    //get points without actions
    let points = getPointsWithoutActions(this.props.game.points);

    //set or unset the moving checker
    const movingChecker = checker !== this.state.movingChecker ? checker : false;

    if (movingChecker !== false) {
      //add action to the moving checker. This uncheck the moving checker
      points[movingChecker].canMove = this.moveCheckerHandler.bind(this, movingChecker);
      this.setState({canDrag: false});
      for (let die of this.props.game.dice) {
        const destination = isMyFirstTurn ? movingChecker + die : movingChecker - die;
        if (destination < 24 && destination >= 0) {
          //Check if destnation can receive the checker
          if (points[destination].playerColor === getPlayer(isMyFirstTurn) || points[destination].checkers < 2) {
            points[destination].canReceive = this.receiveCheckerHandler.bind(this, die); //Add can Receive to point
          }
        }
      }

      //Bearing off
      if (
        isEveryoneInHomeSquare(points, isMyFirstTurn) &&
        ((isMyFirstTurn && movingChecker >= 18) || (!isMyFirstTurn && movingChecker <= 5))
      ) {
        //Get the dice to move
        let die = checkCanBearOff(points, movingChecker, isMyFirstTurn, this.props.game.dice);
        if (die) {
          if (isMyFirstTurn) {
            //@ts-ignore
            outsideCheckers.p1CanReceive = this.receiveCheckerHandler.bind(this, die);
          } else {
            //@ts-ignore
            outsideCheckers.p2CanReceive = this.receiveCheckerHandler.bind(this, die);
          }
          gameStatus = GameStatus.BearingOff;
        }
      }

      console.log('moving checker from point ' + (isMyFirstTurn ? movingChecker + 1 : 24 - movingChecker));
    } else {
      this.setState({canDrag: true});
      const moves = this.calculateCanMove(
        points,
        this.props.game.dice,
        this.props.game.active.isMyFirstTurn,
        this.props.game.checkersOnBar
      );
      points = moves.points;
      gameStatus = moves.gameStatus;
    }

    this.setState({
      movingChecker: movingChecker,
    });

    this.props.changeOutSideBar(outsideCheckers);

    if (gameStatus === GameStatus.NoMoveAvailable) {
      // TODO: TEST THIS CASE!!
    }

    if (outsideCheckers.firstPlayer === FULLOUTSIDE || outsideCheckers.secondPlayer === FULLOUTSIDE) {
      console.log('moveCheckerHandler');
      gameStatus = GameStatus.NoDiceToPlay;
      this.doneHandler();
    }

    this.props.changeGameStatus(gameStatus);
    this.props.changeGamePoints(points);
  };

  //Receive checker into the point
  receiveCheckerHandler = (die) => {
    this.setState({canDrag: true});
    const checkersOnBar = { ...this.props.game.checkersOnBar };
    const outsideCheckers = getOutSideBarWithoutActions(this.props.game.outsideCheckers);
    const dice = [...this.props.game.dice];
    const currentMoves = [...this.props.game.moves];
    let isMyFirstTurn = this.props.game.active.isMyFirstTurn;
    let gameStatus = GameStatus.Playing;

    //get points without actions
    let points = getPointsWithoutActions(this.props.game.points);

    //get the moving checker or checkersOnBar (-1 or 24)
    let movingChecker = getMovingChecker(isMyFirstTurn, this.state.movingChecker);

    //get destination
    const destination = isMyFirstTurn ? movingChecker + die : movingChecker - die;

    //data for move
    let toBar = false;
    let positionFrom = movingChecker;
    let positionTo = destination;

    //Logging
    if (destination > 23 || destination < 0) {
      console.log('Bearing off Checker');
    } else {
      console.log('Moving checker to point ' + (isMyFirstTurn ? destination + 1 : 24 - destination));
    }

    //Remove the checker from orign and clean point if it has no checker
    if (movingChecker >= 0 && movingChecker <= 23) {
      points[movingChecker].checkers--;

      if (points[movingChecker].checkers === 0) {
        points[movingChecker].playerColor = null; //unassign point if there is no checker
      }
    } else {
      //remove from checkersOnBar
      positionFrom = null;
      if (movingChecker === -1) {
        //remove p1 from gray bar
        checkersOnBar.firstPlayer--;
      } else if (movingChecker === 24) {
        //remove p2 from gray bar
        checkersOnBar.secondPlayer--;
      }
    }

    //Moving checker inside the board
    if (destination <= 23 && destination >= 0) {
      if (points[destination].playerColor === getPlayer(isMyFirstTurn) || points[destination].playerColor === null) {
        //Point either belongs to player or to nobody

        //Add checker to destination
        points[destination].checkers++;
      } else {
        //Destination has different player.
        //Send to gray bar
        toBar = true;
        if (isMyFirstTurn) {
          checkersOnBar.secondPlayer++;
        } else {
          checkersOnBar.firstPlayer++;
        }
      }
      //Assign destination point to player. In this case destination has only one checker
      points[destination].playerColor = getPlayer(isMyFirstTurn);
    } else {
      //Bearing off
      positionTo = null;
      if (isMyFirstTurn) {
        outsideCheckers.firstPlayer++;
      } else {
        outsideCheckers.secondPlayer++;
      }
    }

    //Moving checker now is false
    movingChecker = false;

    //remove die from dice
    const diceIndex = dice.findIndex((dieNumber) => dieNumber === die);
    dice.splice(diceIndex, 1);
    console.log('Played die ' + die);
    currentMoves.push({ from: positionFrom, to: positionTo, toBar: toBar });
    this.props.updateMoves(currentMoves).then(() => {
      //Change player if no die
      if (dice.length === 0) {
        // CHANGE PLAYER
      } else {
        //Get new moves
        const canMoves = this.calculateCanMove(points, dice, this.props.game.active.isMyFirstTurn, checkersOnBar);
        points = canMoves.points;
        gameStatus = canMoves.gameStatus;
      }

      const currentPosition = this.state.currentPosition + 1;
      const history = [...this.state.history];
      history.push(
        this.setHistory(this.props.game.active.isMyFirstTurn, dice, points, checkersOnBar, outsideCheckers, this.props.game.moves)
      );

      this.setState({
        history: history,
        currentPosition: currentPosition,
        movingChecker: movingChecker,
      });

      this.props.changeGrayBar(checkersOnBar);
      this.props.changeOutSideBar(outsideCheckers);

      this.props.changeDiceValues(dice);

      if (
        gameStatus === GameStatus.NoMoveAvailable &&
        outsideCheckers.firstPlayer !== FULLOUTSIDE &&
        outsideCheckers.secondPlayer !== FULLOUTSIDE
      ) {
        // CHANGE PLAYER
        this.changePlayerNoMove();
      }

      if (outsideCheckers.firstPlayer === FULLOUTSIDE || outsideCheckers.secondPlayer === FULLOUTSIDE) {
        gameStatus = GameStatus.NoDiceToPlay;
        this.doneHandler();
      }

      this.props.changeGameStatus(gameStatus);
      this.props.changeGamePoints(points);
    });
  };

  changePlayerNoMove = () => {
    setTimeout(() => {
      this.props
        .playerTurn({
          currentPlayerId: this.props.user.profile.userId,
          moves: [...this.props.game.moves],
        })
        .then(() => {
          this.props.changeDiceValues([0]);
          this.props.setIsMyTurn(false);
          this.props.changeGameStatus(GameStatus.RollDice);
        });
    }, 1500);
  };

  doneHandler = () => {
    this.props.changeDoneClicked(true);
    this.state.player.play(Sound.Done);
    // CHANGE PLAYER
    this.props
      .playerTurn({
        currentPlayerId: this.props.user.profile.userId,
        moves: [...this.props.game.moves],
      })
      .then(() => {
        this.props.changeDiceValues([0]);
        this.props.setIsMyTurn(false);
        this.props.changeDoneClicked(false);
      });
  };

  cubeHandler = () => {
    this.props.offerDoubleStake();
  };

  gameOverHandler = () => {
    if (this.props.game.dice.length === 0) { // if you didn't have time to press "Done" button, but realized all moves
      this.doneHandler();
    } else if (this.state.timerEnabed) {
      this.props.leaveGame();
    }
  };

  acceptHandler = () => {
    this.props.acceptDubleStake();
    this.changePossiblyUseDoublingCube(true);
    this.setDoubleStakeOfferedModalShow(false);
  };

  declineHandler = () => {
    this.props.leaveGame();
    this.setDoubleStakeOfferedModalShow(false);
  };

  undoHandler = () => {
    const history = [...this.state.history];
    const newPosition = this.state.currentPosition - 1;
    const isMyTurn = history[newPosition].isMyTurn;
    const dice = [...history[newPosition].dice];
    const checkersOnBar = { ...history[newPosition].checkersOnBar };
    const outsideCheckers = { ...history[newPosition].outsideCheckers };
    const movingChecker = false;
    const moves = [...history[newPosition].moves];

    console.log('Undo last move');

    this.state.player.play(Sound.Undo);

    const canMoves = this.calculateCanMove(
      getPointsWithoutActions(this.state.history[newPosition].points),
      dice,
      isMyTurn,
      checkersOnBar
    );
    const points = canMoves.points;
    const gameStatus = canMoves.gameStatus;
    //remove last element from history
    history.pop();

    this.setState({
      history: history,
      currentPosition: newPosition,
      movingChecker: movingChecker,
      canDrag: true
    });

    this.props.changeGrayBar(checkersOnBar);
    this.props.changeOutSideBar(outsideCheckers);

    this.props.changeDiceValues(dice);
    this.props.changeGameStatus(gameStatus);
    this.props.changeGamePoints(points);
    this.props.updateMoves(moves);
  };

  // rotateBoard = () => {
  //   console.log(this.props.isRotated);
  //   this.props.rotateBoard(!this.props.isRotated);
  // };

  setMySidebarShow = (sidebarShow: boolean) => this.setState({ mySidebarShow: sidebarShow });
  setOpponentSidebarShow = (sidebarShow: boolean) => this.setState({ opponentSidebarShow: sidebarShow });

  render() {
    const { game, lobbyList, auth, user, activeLobby, isLandscape, isPortrait, isMobileOnly, isTablet, handleFS } = this.props;
    const score = calculateScore(game);
    console.log('Game status is ' + getGameStatusDescription(game.status));

    let header = (
      <CustomHeader history={this.props.history} statusAndDisableAd={false} isFromGame={true} parentRef={this.parentRef} onLogout={this.props.clearGame}/>
    );

    let appStyle;

    let boardWith = (window.innerHeight - 14) * BOARD_CONST;
    if (boardWith < window.innerWidth - 130) {
      appStyle = {
        width: boardWith,
        height: window.innerHeight - 14,
      };
    } else {
      appStyle = {
        width: (window.innerHeight - 36) * BOARD_CONST,
        height: window.innerHeight - 36,
      };
    }

    let app = (
      <div id="App" style={isMobileOnly ? appStyle : null}>
        <div id="game">
          <Board
            replacerColor={game.active.isMyFirstTurn != (game.myColor === GameColor.White)}
            myColor={game.myColor}
            opponentColor={game.opponentColor}
            newGameHandler={this.setupNewGameHandler}
            rollDice={this.rollDiceHandler}
            goFirst={this.goFirstHandler}
            doneHandler={this.doneHandler}
            undoHandler={this.undoHandler}
            isOverHandler={this.doneHandler}
            disabledCube={
              game.status === GameStatus.NewGame ||
              game.status !== GameStatus.RollDice ||
              !game.isMyTurn ||
              !this.state.possiblyUseDoublingCube
            }
            maxDisableCube={game.active.doublingCube === 64}
            cubeHandler={this.cubeHandler}
            currentPosition={this.state.currentPosition}
            parentRef={this.parentRef}
            possiblyUseDoublingCube={this.state.possiblyUseDoublingCube}
            hideCube={this.state.waitingAnswerModalShow || this.state.doubleStakeOfferedModalShow}
            isBot={this.props.match.params.id === PLAYBOTROUTE}
            canDrag={this.state.canDrag}
          ></Board>
        </div>
        <GameOver gameStatus={game.status} parentRef={this.parentRef} okHandler={this.okGameOverHandler} />
      </div>
    );

    let oppUserInfo = (
      <UserInfo
        isActive={user.profile.userId && game.currentPlayerId && user.profile.userId !== game.currentPlayerId}
        isBot={this.props.match.params.id === PLAYBOTROUTE}
        score={game.active.isMyFirstTurn ? score.P2 : score.P1}
        profile={game.opponentProfile}
        isMobile={isMobileOnly}
        player={game.opponentColor}
        timelimit={game.status === GameStatus.PlayersRollDice ? FIRST_TIMELIMIT : TIMELIMIT}
      />
    );

    let myUserInfo = (
      <UserInfo
        isActive={user.profile.userId && game.currentPlayerId && user.profile.userId === game.currentPlayerId}
        profile={user.profile}
        score={game.active.isMyFirstTurn ? score.P1 : score.P2}
        isOverPlayerTimer={this.gameOverHandler}
        isMobile={isMobileOnly}
        player={game.myColor}
        timelimit={game.status === GameStatus.PlayersRollDice ? FIRST_TIMELIMIT : TIMELIMIT}
      />
    );

    let gameContent =
      game.status !== GameStatus.CreatedLobby ? (
        <Jumbotron className="bg-transparent p-3 m-0" fluid>
          <Container fluid>
            <Row className="flex-nowrap justify-content-center">
              <Col xs lg={2} className="p-sm-0">
                {oppUserInfo}
                <div className="side_buttons">
                  <Col className="game-buttons-area flex-nowrap justify-content-center">
                    <Row className="flex-nowrap justify-content-center align-items-center">
                      <BsFillVolumeDownFill size={32} className="p-1" />
                      <RangeSlider
                        value={this.state.volume}
                        step={0.1}
                        max={1}
                        tooltip="off"
                        onChange={(e) => {
                          this.setState({
                            volume: +e.target.value,
                          });
                          this.state.player.setVolume(+e.target.value);
                        }}
                        variant="success"
                      />
                      <BsFillVolumeUpFill size={32} className="p-1" />
                    </Row>
                    <Row className="flex-nowrap justify-content-center align-items-center">
                    <CircleButton
                      iconType={handleFS.active ? IconType.FullScreenExit : IconType.FullScreen}
                      text={handleFS.active ? 'Exit Full Screen' : 'Full Screen'}
                      variant="app-white"
                      btnClick={handleFS.active ? handleFS.exit : handleFS.enter}
                    ></CircleButton>
                    <CircleButton
                      iconType={IconType.Dice}
                      text={this.state.isAutoRoll ? 'Disable Auto' : 'Enable Auto'}
                      variant="app-white"
                      checked={this.state.isAutoRoll}
                      btnClick={this.changeAutoRoll}
                    ></CircleButton>
                      </Row>
                  </Col>
                </div>
              </Col>
              <Col lg={isMobileOnly ? 6 : 8}>
                {app}
                <div className="bottom_buttons">
                  <Row className="game-buttons-area flex-nowrap justify-content-center">
                    <CircleButton
                      iconType={handleFS.active ? IconType.FullScreenExit : IconType.FullScreen}
                      text={handleFS.active ? 'Exit Full Screen' : 'Full Screen'}
                      variant="app-white"
                      btnClick={handleFS.active ? handleFS.exit : handleFS.enter}
                    ></CircleButton>
                    <CircleButton
                      iconType={IconType.Dice}
                      text={this.state.isAutoRoll ? 'Disable Auto' : 'Enable Auto'}
                      variant="app-white"
                      checked={this.state.isAutoRoll}
                      btnClick={this.changeAutoRoll}
                    ></CircleButton>
                    {/* <CircleButton iconType={IconType.Rotate} text="Rotate" variant="app-white" btnClick={this.rotateBoard}></CircleButton> */}
                    {/* <CircleButton iconType={IconType.Double} text="Double" disabled={true} variant="app-white"></CircleButton> */}
                    <CircleButton
                      iconType={IconType.Help}
                      text="Help"
                      disabled={!game.isMyTurn || game.status !== GameStatus.Playing}
                      btnClick={() => this.setHelpModalShow(true)}
                      variant="app-white"
                    ></CircleButton>
                    <CircleButton
                      iconType={IconType.Leave}
                      text="Leave"
                      disabled={!game.active.gameId}
                      variant="app-danger"
                      btnClick={() => this.setLeaveModalShow(true)}
                    ></CircleButton>
                  </Row>
                </div>
              </Col>
              <Col xs lg={2} className="p-sm-0">
                {myUserInfo}
                <div className="side_buttons">
                  <Row className="game-buttons-area flex-nowrap justify-content-center">
                    {/* <CircleButton iconType={IconType.Rotate} text="Rotate" variant="app-white" btnClick={this.rotateBoard}></CircleButton> */}
                    {/* <CircleButton iconType={IconType.Double} text="Double" disabled={true} variant="app-white"></CircleButton> */}
                    <CircleButton
                      iconType={IconType.Help}
                      text="Help"
                      disabled={!game.isMyTurn || game.status !== GameStatus.Playing}
                      btnClick={() => this.setHelpModalShow(true)}
                      variant="app-white"
                    ></CircleButton>
                    <CircleButton
                      iconType={IconType.Leave}
                      text="Leave"
                      disabled={!game.active.gameId}
                      variant="app-danger"
                      btnClick={() => this.setLeaveModalShow(true)}
                    ></CircleButton>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      ) : null;

    let oppUserInfoMobile =
      this.props.match.params.id === PLAYBOTROUTE ? (
        oppUserInfo
      ) : (
        <Button variant="app-empty" className="p-0 w-100" onClick={() => this.setOpponentSidebarShow(true)}>
          {oppUserInfo}
        </Button>
      );

    let gameMobileContent =
      game.status !== GameStatus.CreatedLobby ? (
        <Container fluid className={(isMobileOnly || isTablet) && isPortrait ? 'hidden-game-content' : ''}>
          <Row className="flex-nowrap justify-content-center mobile">
            <Col className="p-0 d-flex flex-column">
              <a className="mb-2 p-2 d-flex justify-content-center" href="#">
                <Image src={logoGPImg} fluid />
              </a>
              {oppUserInfoMobile}
              <div className="side_buttons h-100">
                <Col className="game-buttons-area flex-nowrap justify-content-center mb-2 р-100">
                  <Row className="flex-nowrap justify-content-center ">
                    <CircleButton
                      iconType={handleFS.active ? IconType.FullScreenExit : IconType.FullScreen}
                      text={handleFS.active ? 'Exit Full Screen' : 'Full Screen'}
                      variant="app-white"
                      btnClick={handleFS.active ? handleFS.exit : handleFS.enter}
                    ></CircleButton>
                    <CircleButton
                      iconType={IconType.Dice}
                      text={this.state.isAutoRoll ? 'Disable Auto' : 'Enable Auto'}
                      variant="app-white"
                      checked={this.state.isAutoRoll}
                      btnClick={this.changeAutoRoll}
                    ></CircleButton>
                  </Row>
                  <Row className="flex-nowrap justify-content-center align-items-center">
                    <BsFillVolumeDownFill size={32} className="p-1" />
                    <RangeSlider
                      value={this.state.volume}
                      step={0.1}
                      max={1}
                      tooltip="off"
                      onChange={(e) => {
                        this.setState({
                          volume: +e.target.value,
                        });
                        this.state.player.setVolume(+e.target.value);
                      }}
                      variant="success"
                    />
                    <BsFillVolumeUpFill size={32} className="p-1" />
                  </Row>
                </Col>
              </div>
            </Col>
            <Col className="d-flex align-items-center vh-100 p-0">{(isMobileOnly || isTablet) && isPortrait ? null : app}</Col>
            <Col className="p-0 d-flex flex-column">
              <div className="mb-2 p-2 d-flex justify-content-center">
                <MenuIcon role="button" onClick={() => this.setMySidebarShow(true)} />
              </div>
              <Button variant="app-empty" className="p-0 w-100" onClick={() => this.setMySidebarShow(true)}>
                {myUserInfo}
              </Button>
              <div className="side_buttons h-100">
                <Col className="game-buttons-area flex-nowrap justify-content-center mb-2 р-100">
                  <Row className="">
                    {/* <Image className="circle-button" src={emojiImg} role="button" /> */}
                    {/* <CircleButton
                    iconType={IconType.Rotate}
                    text="Rotate"
                    variant="app-white"
                    btnClick={this.rotateBoard}
                  ></CircleButton> */}
                  </Row>
                  <Row className="d-flex justify-content-end align-items-center h-100 flex-column">
                    {/* <CircleButton iconType={IconType.Double} text="Double" disabled={true} variant="app-white"></CircleButton> */}
                    <CircleButton
                      iconType={IconType.Help}
                      text="Help"
                      disabled={!game.isMyTurn || game.status !== GameStatus.Playing}
                      btnClick={() => this.setHelpModalShow(true)}
                      variant="app-white"
                    ></CircleButton>
                    <CircleButton
                      iconType={IconType.Leave}
                      disabled={!game.active.gameId}
                      text="Leave"
                      variant="app-danger"
                      btnClick={() => this.setLeaveModalShow(true)}
                    ></CircleButton>
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
          <SidebarMenu
            show={this.state.mySidebarShow}
            onHide={() => this.setMySidebarShow(false)}
            profile={user.profile}
            history={this.props.history}
            isFromGame={true}
            parentRef={this.parentRef}
            onLogout={this.props.clearGame}
          />
          <SidebarMenu
            show={this.state.opponentSidebarShow}
            onHide={() => this.setOpponentSidebarShow(false)}
            profile={game.opponentProfile}
            isOpponent={true}
          />
        </Container>
      ) : null;

    return auth.uid ? (
      <Container fluid className={isLandscape ? 'm-0 p-0' : 'p-0'} ref={this.parentRef}>
        {isMobileOnly ? null : header}
        {isMobileOnly || (isTablet && isPortrait) ? null : gameContent}

        {(isMobileOnly || isTablet) && isPortrait ? (
          <div className="rotation-info d-flex justify-content-center align-items-center flex-column">
            <div className="text-center m-4">
              <p>To start playing -</p>
              <p>please turn the phone</p>
              <p>to landscape orientation</p>
            </div>
            <Image className="h-100" src={rotationImg} />
          </div>
        ) : null}

        {isMobileOnly ? gameMobileContent : null}
        <Modals
          parentRef={this.parentRef}
          game={game}
          match={this.props.match}
          state={this.state}
          declineHandler={this.declineHandler}
          acceptHandler={this.acceptHandler}
          primaryHandler={this.primaryHandler}
          secondaryHandler={this.secondaryHandler}
          cancelGameHandler={this.cancelGameHandler}
          setAcceptInfoModalShow={this.setAcceptInfoModalShow}
          setGameInfoModalShow={this.setGameInfoModalShow}
          setHelpModalShow={this.setHelpModalShow}
          setLeaveModalShow={this.setLeaveModalShow}
        />

        {process.env.REACT_APP_SHOW_DEBUG === '1' && game ? (
          <DebugVariables
            canDrag={this.state.canDrag}
            toggleDebug={this.toggleDebug}
            showDebug={this.state.showDebug}
            uid={this.props.auth.uid}
            lobbyId={this.props.match.params.id}
            isTablet={isTablet.toString()}
            isLandscape={isLandscape.toString()}
            isPortrait={isPortrait.toString()}
            profileId={this.props.user.profile.userId}
            isMyTurn={game.isMyTurn.toString()}
            activeLobbyId={activeLobby ? activeLobby.id : ''}
            isRotated={game.isRotated}
            possiblyUseDoublingCube={this.state.possiblyUseDoublingCube}
            player1={game.active.player1}
            player2={game.active.player2}
            gameId={game.active.gameId}
            firstDicePlayer1={game.active.firstDicePlayer1}
            firstDicePlayer2={game.active.firstDicePlayer2}
            firstMovePlayerId={game.active.firstMovePlayerId}
            isMyFirstTurn={game.active.isMyFirstTurn.toString()}
            meIsPlayer1={game.active.meIsPlayer1.toString()}
            doublingCube={game.active.doublingCube.toString()}
            status={game.status}
            dice={game.dice}
            checkersOnBarFirstPlayer={game.checkersOnBar.firstPlayer}
            checkersOnBarSecondPlayer={game.checkersOnBar.secondPlayer}
            outsideCheckersFirstPlayer={game.outsideCheckers.firstPlayer}
            outsideCheckersSecondPlayer={game.outsideCheckers.secondPlayer}
            moves={game.moves}
            opponentNoMove={game.opponentNoMove.toString()}
            myColor={game.myColor.toString()}
            opponentColor={game.opponentColor.toString()}
            currentPlayerId={game.currentPlayerId ? game.currentPlayerId.toString() : ''}
            currentPosition={this.state.currentPosition}
            history={this.state.history}
          >
            <Form.Check
              type="switch"
              id="custom-switch"
              defaultChecked={this.state.timerEnabed}
              label="Player's Turn Timer"
              onChange={() => {
                this.setState({
                  timerEnabed: !this.state.timerEnabed,
                });
              }}
            />
            {this.props.setIsMyTurn && this.props.game.status === GameStatus.Playing ? (
              <div>
                <input
                  type="text"
                  className="debug-dice debug-input"
                  defaultValue={this.state.debugDice}
                  onChange={(e) => {
                    this.setState({
                      debugDice: parseInt(e.target.value),
                    });
                  }}
                />
                <Button
                  className="debug-dice"
                  onClick={() => {
                    let aa = this.props.game.dice;
                    aa.push(this.state.debugDice);
                    this.props.changeDiceValues(aa);
                  }}
                >
                  Add Dice
                </Button>
                <Button
                  className="debug-dice"
                  onClick={() => {
                    this.props.changeDiceValues([]);
                  }}
                >
                  Clear Dice
                </Button>
              </div>
            ) : null}
          </DebugVariables>
        ) : null}
      </Container>
    ) : (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }
}

const mapStateToProps = ({ game, user, firebase }) => {
  return {
    game: {
      opponentProfile: game.opponentProfile,
      isMyTurn: game.isMyTurn,
      active: game.activeGame,
      status: game.gameStatus,
      points: game.points,
      dice: game.dice,
      checkersOnBar: game.checkersOnBar,
      outsideCheckers: game.outsideCheckers,
      moves: game.moves,
      opponentNoMove: game.opponentNoMove,
      myColor: game.myColor,
      opponentColor: game.opponentColor,
      currentPlayerId: game.currentPlayerId,
      isRotated: game.isRotated,
    },
    user: {
      profile: user.myProfile,
      token: user.token,
      isNewUser: user.isNewUser,
    },

    lobbyList: game.lobbyList,
    activeLobby: game.activeLobby,
    auth: firebase.auth,
  };
};

export default connect(mapStateToProps, {
  setActiveGame,
  playWithBot,
  changeGameStatus,
  changeGamePoints,
  prepareNewGame,
  setupNewGame,
  getRollDiceValues,
  changeDiceValues,
  setIsMyTurn,
  updateBoard,
  updateMoves,
  changeGrayBar,
  changeOutSideBar,
  joinLobby,
  getLobby,
  changeFlagNoMove,
  playerTurn: performPlayerTurn,
  clearGame,
  leaveGame,
  leaveLobby,
  getOpponentProfile,
  offerDoubleStake,
  acceptDubleStake,
  changeDoublingCube,
  changeDoneClicked,
})(withRouter(App));
