import React, { Component } from 'react';
import { GameStatus } from '../../api/enums';
import { AppModal } from '../Modals/AppModal';

interface Props {
  gameStatus: GameStatus;
  okHandler: () => void;
  parentRef: React.RefObject<HTMLDivElement>;
}

class GameOver extends Component<Props> {
  getWinLose = () => {
    if (this.props.gameStatus === GameStatus.PlayerWin) {
      return 'Win';
    }
    return 'Lose';
  };

  render() {
    return (
      <AppModal
        show={this.props.gameStatus === GameStatus.PlayerWin || this.props.gameStatus === GameStatus.PlayerLose}
        backdrop="static"
        parentRef={this.props.parentRef}
        modalConfig={{
          headText: 'Game Over',
          bodyText: this.getWinLose(),
          primaryBtnText: 'OK',
          primaryHandle: this.props.okHandler,
        }}
      />
    );
  }
}
export default GameOver;
