import { isSafari } from 'react-device-detect';
import { DEFAULT_VOLUME } from "../api/constants";
import { Sound } from "./soundEnum";

export class SoundPlayer {
  private static _instance: SoundPlayer = new SoundPlayer();
  private _volume: number = DEFAULT_VOLUME;

  constructor() {
    SoundPlayer._instance = this;
  }

  public static getInstance(): SoundPlayer {
    return SoundPlayer._instance;
  }

  public setVolume(value: number): void {
    this._volume = value;
  }

  public getVolume(): number {
    return this._volume;
  }

  public play(sound: Sound) {
    if (!isSafari) {
      const audio = this.getAudio(sound);
      const htmlaudio: HTMLAudioElement = new Audio(audio);
      htmlaudio.volume = this._volume;
      htmlaudio.play();
    }
  }

  private getAudio = (sound: Sound) => {
    switch (sound) {
      case Sound.Drop:
        return require('../assets/sounds/drop.mp3');
      case Sound.Drag:
        return require('../assets/sounds/drag.mp3');
      case Sound.Dice:
        return require('../assets/sounds/dice.mp3');
      case Sound.Win:
        return require('../assets/sounds/win.mp3');
      case Sound.Lose:
        return require('../assets/sounds/lose.mp3');
      case Sound.Done:
        return require('../assets/sounds/done.mp3');
      case Sound.Undo:
        return require('../assets/sounds/undo.mp3');
      case Sound.Out:
        return require('../assets/sounds/outcheckers.mp3');
    }
  }
}
