import React, { FunctionComponent } from 'react';

type Props = {
  show: boolean;
  disabledCube: boolean;
  maxDisableCube: boolean;
  valueCube: number;
  cube: () => void;
};

export const DoublingCube: FunctionComponent<Props> = ({ disabledCube, maxDisableCube, valueCube, show, cube, ...props }) => {
  return show ? (
    <div
      className={`dice_blue ${disabledCube ? 'dice_blue--disabled' : ''} ${maxDisableCube ? 'max-cube' : ''}`}
      role="button"
      onClick={cube}
    >
      <div className="diceContent_blue">
        <div className="dice_blue__value">{valueCube}</div>
      </div>
    </div>
  ) : null;
};
