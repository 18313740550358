import React, { Component } from 'react';

interface Props {
  diceNumber: string;
  number: number;
  isWhite: boolean;
  clicked?: () => void;
}

class Dice extends Component<Props> {
  render() {
    const dots = Array(9).fill(null);
    switch (this.props.number) {
      case 1:
        dots[4] = true;
        break;
      case 2:
        dots[0] = true;
        dots[8] = true;
        break;
      case 3:
        dots[0] = true;
        dots[4] = true;
        dots[8] = true;
        break;
      case 4:
        dots[0] = true;
        dots[2] = true;
        dots[6] = true;
        dots[8] = true;
        break;
      case 5:
        dots[0] = true;
        dots[2] = true;
        dots[4] = true;
        dots[6] = true;
        dots[8] = true;
        break;
      case 6:
        dots[0] = true;
        dots[2] = true;
        dots[3] = true;
        dots[5] = true;
        dots[6] = true;
        dots[8] = true;
        break;
      default:
        break;
    }

    let diceFilled = `diceFilled diceFilled_${this.props.isWhite ? 'white' : 'black'}`;
    let dice = `dice dice_${this.props.isWhite ? 'white' : 'black'}`;
    let diceContent = `diceContent diceContent_${this.props.isWhite ? 'white' : 'black'}`;
    const die = [...Array(3)].map((_, indexRow) => {
      return (
        <div className="diceRow" key={'dr' + indexRow}>
          {[...Array(3)].map((_, indexCol) => {
            return (
              <div className="diceColum" key={'dc' + indexRow * 3 + indexCol}>
                {dots[indexRow * 3 + indexCol] ? <div className={diceFilled}></div> : ''}
              </div>
            );
          })}
        </div>
      );
    });

    let clickable = '';
    if (this.props.clicked) {
      clickable = ' clickable';
    }

    return (
      <div className={dice + clickable} onClick={this.props.clicked}>
        <div className={diceContent}>{die}</div>
      </div>
    );
  }
}

export default Dice;
