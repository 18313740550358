import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '../dnd/ItemTypes';
import Col from 'react-bootstrap/Col';
import { CheckerFlat } from './CheckerFlat/CheckerFlat';
import { DoublingCube } from '../DoublingCube/DoublingCube';
import { selectClass } from '../../helpers';
import { SoundPlayer } from '../../sounds/soundPlayer';
import { Sound } from '../../sounds/soundEnum';
import { CheckerFlatPlayer } from '../../api/enums';

export interface DropOutSideBarProps {
  allowedDropEffect: string;
  replacerColor: boolean;
  checkers: any;
  currentPosition: number;
  isRotated: boolean;
  valueCube: number;
  disabledCube: boolean;
  ismyFirstTurn: boolean;
  maxDisableCube: boolean;
  cube: () => void;
}

export const DropOutSideBar: React.FC<DropOutSideBarProps> = ({
  allowedDropEffect,
  replacerColor,
  checkers,
  currentPosition,
  isRotated,
  valueCube,
  disabledCube,
  ismyFirstTurn,
  maxDisableCube,
  cube,
}) => {
  const player = SoundPlayer.getInstance();

  const getFlatCheckers = (player: CheckerFlatPlayer, numberOfCheckers: number) => {
    const checkers = [];
    for (let i = 0; i < numberOfCheckers; i++) {
      checkers.push(<CheckerFlat player={player} key={'OSBP' + player + 'C' + i} replacerColor={replacerColor} />);
    }
    return checkers;
  };

  let classReceivableP1 = '';
  let classReceivableP2 = '';
  if (checkers.p1CanReceive) {
    classReceivableP1 = ' outSide--receivable';
  }
  if (checkers.p2CanReceive) {
    classReceivableP2 = ' outSide--receivable';
  }

  const firstPlayer = getFlatCheckers(CheckerFlatPlayer.P1, checkers.firstPlayer);
  const secondPlayer = getFlatCheckers(CheckerFlatPlayer.P2, checkers.secondPlayer);
  console.log('currentPosition!!!', currentPosition);

  const positionClass = isRotated ? 'outSide--left' : 'outSide--right';

  const [{ canDropP1, isOverP1 }, dropP1] = useDrop({
    accept: ItemTypes.BOX,
    canDrop: (item, monitor) => checkers.p1CanReceive,
    drop: () => {
      player.play(Sound.Out);
      if (checkers.p1CanReceive) {
        checkers.p1CanReceive();
      }
      return {
        name: `${allowedDropEffect} DropTriangle`,
        allowedDropEffect,
      };
    },
    collect: (monitor: any) => ({
      isOverP1: monitor.isOver(),
      canDropP1: monitor.canDrop(),
    }),
  });

  const [{ canDropP2, isOverP2 }, dropP2] = useDrop({
    accept: ItemTypes.BOX,
    canDrop: (item, monitor) => checkers.p2CanReceive,
    drop: () => {
      player.play(Sound.Out);
      if (checkers.p2CanReceive) {
        checkers.p2CanReceive();
      }
      return {
        name: `${allowedDropEffect} DropTriangle`,
        allowedDropEffect,
      };
    },
    collect: (monitor: any) => ({
      isOverP2: monitor.isOver(),
      canDropP2: monitor.canDrop(),
    }),
  });

  const isActiveP1 = canDropP1 && isOverP1;
  const isActiveP2 = canDropP2 && isOverP2;
  return (
    <Col
      className={`outSide d-flex flex-column justify-content-between p-0 ${positionClass} ${
        ismyFirstTurn ? 'outSide--upside-down' : ''
      }`}
    >
      <div className="outSide__up">
        <div className="shadowBox"></div>
        <div
          className={`outsideContainer pointContainerDown + ${classReceivableP1} ${
            ismyFirstTurn ? 'outSide--upside-down' : ''
          } ${selectClass(isActiveP1, canDropP1)}`}
          ref={dropP1}
          onClick={() => {
            if (checkers.p1CanReceive) {
              player.play(Sound.Out);
              checkers.p1CanReceive();
            }
          }}
        >
          {firstPlayer}
        </div>
      </div>
      <div className="outSide__middle">
        <DoublingCube
          show={valueCube === 1}
          disabledCube={disabledCube}
          maxDisableCube={maxDisableCube}
          valueCube={valueCube}
          cube={cube}
        />
      </div>
      <div className="outSide__down">
        <div className="shadowBox"></div>
        <div
          className={`outsideContainer pointContainerDown + ${classReceivableP2} ${
            ismyFirstTurn ? 'outSide--upside-down' : ''
          } ${selectClass(isActiveP2, canDropP2)}`}
          ref={dropP2}
          onClick={() => {
            if (checkers.p2CanReceive) {
              player.play(Sound.Out);
              checkers.p2CanReceive();
            }
          }}
        >
          {secondPlayer}
        </div>
      </div>
    </Col>
  );
};
