import React, { FunctionComponent } from 'react';
import { isMobileOnly, withOrientationChange, isTablet, isSafari } from 'react-device-detect';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import App from '../../container/App';
import Image from 'react-bootstrap/Image';

import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { usePreview } from 'react-dnd-preview';

type Props = {
  isLandscape: boolean;
  isPortrait: boolean;
  match: any;
};

const MyPreview = () => {
  const { display, itemType, item, style } = usePreview();
  if (!display) {
    return null;
  }
  return (
    <div className="item-list__item" style={style}>
      <Image src={item.prev} fluid />
    </div>
  );
};

const AppFullScreen: FunctionComponent<Props> = ({ isLandscape, isPortrait, match, ...props }) => {
  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <DndProvider backend={isMobileOnly || isSafari ? TouchBackend : HTML5Backend} options={{ enableMouseEvents: true }}>
        <App
          isLandscape={isLandscape}
          isPortrait={isPortrait}
          isMobileOnly={isMobileOnly}
          isTablet={isTablet}
          handleFS={handle}
        ></App>
        {isMobileOnly || isSafari ? <MyPreview /> : null}
      </DndProvider>
    </FullScreen>
  );
};

export default withOrientationChange(AppFullScreen);
