import React, { Component } from 'react';
import Dice from './Dice/Dice';
import { RollButton } from './RollButton/RollButton';
import { GameStatus } from '../../api/enums';
import Button from 'react-bootstrap/Button';

interface Props {
  dice?: number[];
  isWhite: boolean;
  clicked?: () => void;
  gameStatus: GameStatus;
  opponentNoMove?: boolean;
}

interface State {
  isClicked: boolean;
}

class DiceArea extends Component<Props, State> {
  getInitState = () => {
    return {
      isClicked: false,
    };
  };

  state = this.getInitState();

  setIsClicked = (isClicked: boolean) => this.setState({ isClicked });

  render() {
    let dice = null;
    let waiting = null;
    if (this.props.gameStatus === GameStatus.GamePreparation) {
      // Prepare game
      dice = <RollButton label="Roll Die" clicked={this.props.clicked} />;
    } else {
      dice = this.props.dice.map((number, index) => {
        if (this.props.gameStatus === GameStatus.OpponentDice) {
          // opponent dice
          return number !== 0 ? (
            <Dice diceNumber="index" number={number} key={'dice' + index} isWhite={this.props.isWhite} />
          ) : null;
        }
        if (this.props.gameStatus !== GameStatus.PlayersRollDice) {
          if (number === 0) {
            return (
              <Button variant="app-lite-green" className={'roll-button'} key={'RollButton' + index} disabled={this.state.isClicked} onClick={() => {this.setIsClicked(true); this.props.clicked();}}>
                Roll
              </Button>
            );
          } else {
            return <Dice diceNumber="index" number={number} key={'dice' + index} isWhite={this.props.isWhite} />;
          }
        }
        return (
          <Dice
            diceNumber="index"
            number={number}
            key={'dice' + index}
            isWhite={this.props.isWhite}
            clicked={this.props.clicked}
          />
        );
      });
    }

    return (
      <div className={'diceArea__wrapper'}>
        <div className="diceArea">
          {waiting}
          {dice}
        </div>
        {this.props.gameStatus === GameStatus.NoMoveAvailable && (
          <div className="d-flex justify-content-center">
            <span className="information-message">No moves available</span>
          </div>
        )}
        {this.props.opponentNoMove && (
          <div className="d-flex justify-content-center">
            <span className="information-message">No Moves for Opponent</span>
          </div>
        )}
      </div>
    );
  }
}

export default DiceArea;
