import React, { FunctionComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

type ModalProps = {
  show: boolean;
  onHide: () => void;
  modalConfig: GameInfoModalConfig;
  parentRef?: React.RefObject<HTMLDivElement>;
};

export const GameInfoModal: FunctionComponent<ModalProps> = ({ modalConfig, parentRef, ...props }) => {
  const primaryButton = modalConfig.primaryBtnText ? (
    <Button variant="app-outline" onClick={props.onHide}>
      {modalConfig.primaryBtnText}
    </Button>
  ) : null;

  const headerText = modalConfig.headText ? (
    <div className="modal-header">
      <div className="modal-title">{modalConfig.headText}</div>
    </div>
  ) : null;

  return (
    <Modal
      {...props}
      dialogClassName="game-info-dialog modal-40w"
      className="app-modal game-info"
      centered
      container={parentRef}
      backdropClassName="game-info-backdrop"
      onClick={props.onHide}
    >
      {headerText}
      <Modal.Body>
        <p>{modalConfig.bodyText}</p>
        {props.children}
      </Modal.Body>
      <Modal.Footer>{primaryButton}</Modal.Footer>
    </Modal>
  );
};

export type GameInfoModalConfig = {
  headText?: string;
  bodyText?: string;
  primaryBtnText?: string;
};
