import React, { Component } from 'react';
import { History } from 'history';

import CustomHeader from '../CustomHeader/CustomHeader';
import CustomFooter from '../CustomFooter/CustomFooter';
import { ReactComponent as ButtonBack } from '../../assets/arrow.svg';

interface Props {
  history: History;
}

class PrivacyAndTerms extends Component<Props> {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <>
        <div className="flex-grow-1">
          <CustomHeader history={this.props.history} statusAndDisableAd={true} />
          <div className="p-4">
            <div className="last-page-block d-flex flex-column flex-wrap position-absolute f-left">
              <span className="page-name">Privacy and Terms of Service</span>
              <ButtonBack role="button" className="button-back" onClick={this.goBack} />
            </div>

            <div className="privacy">
              <div className="title">Privacy Policy</div>
              <div className="paragraph">
                GammonPro, as regards users originating from within the United States, and GammonPro as regards users originating
                from outside the United States, ("GammonPro") respects the privacy of its users and has developed this Privacy
                Policy to demonstrate its commitment to protecting your privacy. This Privacy Policy describes the information we
                collect, how that information may be used, with whom it may be shared, and your choices about such uses and
                disclosures. We encourage you to read this Privacy Policy carefully when using our website or services or
                transacting business with us. By using our website, application, or other online services, you are accepting the
                practices described in this Privacy Policy.
                <div className="item-content">
                  If you have any questions about our privacy practices, please refer to the end of this Privacy Policy for
                  information on how to contact us. Information we collect about you
                </div>
                <div className="item-content">
                  In General. We may collect Personal Information, including Sensitive Data, and other information. “Personal
                  Information” means individually identifiable information that would allow us to determine the actual identity
                  of, and contact, a specific living person. Sensitive Data includes information, comments or content (e.g.
                  photographs, video, profile, lifestyle) that you optionally provide that may reveal your ethnic origin,
                  nationality, religion and/or sexual orientation. By providing Sensitive Data to us, you consent to the
                  collection, use and disclosure of Sensitive Data as permitted by applicable privacy laws. We may also collect
                  your geolocation information with your consent. We may collect this information through a website, mobile
                  application or other online service. When you provide personal information, the information may be sent to
                  servers located in the United States and other countries around the world.
                </div>
                <div className="item-content">
                  Information you provide. We may collect and store any personal information you enter on our website or provide
                  to us in some other manner. This includes identifying information, such as your name, address, email address,
                  and telephone number, and, if you transact business with us, financial information such as your payment method
                  (valid credit card number, type, expiration date or other financial information). We also request information
                  about your interests and activities, your gender and age, and other demographic information such as your
                  hometown or your username. We may also receive information about our users from third party social platforms,
                  such as Facebook. When you access or use our website or mobile application through such a site, you allow us to
                  access or collect information made available by the third party site in accordance with its privacy policy. This
                  information may be available from your profile or account with such site or from cookies placed on your device
                  by the third party platform. Information about others. We may collect and store personal information about other
                  people that you provide to us, such as their name, address and email address. If you use our website or
                  application to send others (friends, relatives, colleagues, etc.) a product as a gift, we may store your
                  personal information, and the personal information of each such recipient in order to process those requests or
                  facilitate future activities.
                </div>
                <div className="item-content">
                  Use of cookies and other technologies to collect information.We use various technologies to collect information
                  from your device and about your activities on our site or application.
                </div>
                <div className="item-content">
                  Information collected automatically. We automatically collect information from your browser or device when you
                  visit our website or application. This information could include your IP address, device ID, your browser type
                  and language, access times, the content of any undeleted cookies that your browser previously accepted from us
                  (See "Cookies" below), and the referring website address. Cookies and Use of Cookie Data. When you visit our
                  website or application, we may assign your device one or more cookies to facilitate access to our site and to
                  personalize your online experience. Through the use of a cookie, we also may automatically collect information
                  about your online activity on our site, such as the web pages you visit, the time and date of your visits, the
                  links you click, and the searches you conduct on our site. During your visits to other websites where we
                  advertise, we (or third parties) may use certain data collected on our site to show you the type of CanDoBetter
                  advertisements likely to be of greater interest to you. Although you may not opt out of receiving online
                  advertisements generally, you may control the collection of data on our site used for targeted GammonPro
                  advertising during your visits to other websites. To opt-out of GammonPro data collection for targeted
                  advertising on other sites, click here Opt Out Now. Most browsers automatically accept cookies, but you can
                  usually modify your browser setting to decline cookies. If you choose to decline cookies, please note that you
                  may not be able to sign in or use some of the interactive features offered on our website. Other Technologies.
                  We may use standard Internet technology, such as web beacons, pixel tags, local storage and other technologies
                  that facilitate personalization to track your use of our site. We also may use these technologies in
                  advertisements or email messages to determine whether messages have been opened and acted upon. The information
                  we obtain in this manner enables us to customize the services we offer our website visitors to deliver targeted
                  advertisements and to measure the overall effectiveness of our online advertising, content, programming or other
                  activities. Information collected by third-parties. We may allow service providers, Match Group companies,
                  advertising companies and ad networks, and other third parties to display advertisements on our site. These
                  companies may use tracking technologies, such as cookies, to collect information about users who view or
                  interact with their advertisements. Our website does not provide any personal information to these third
                  parties. This information allows them to deliver targeted advertisements and gauge their effectiveness.
                </div>
              </div>
              <div className="title">Terms of Conditions</div>
              <div className="paragraph">
                Listed below are the “Terms and Conditions of Use” of GammonPro (the “Company”) and the Company’s website located
                at www.gammonpro.com (hereinafter collectively with any of its sub-domains, referred to as the “Web Site” or
                “Website”). Do not use this Web Site and/or the related services if You do not accept these Terms and Conditions
                of Use (“Terms and Conditions”). By using this Web Site, you are indicating your acceptance of these Terms and
                Conditions and agree to be bound by them. GammonPro may revise these Terms and Conditions at any time by updating
                this posting without further notice to you. You should review these Terms and Conditions periodically to determine
                whether any revisions may affect Your use. By using the Web Site, you agree to and acknowledge these Terms and
                Conditions and further agree to remain in compliance with these Terms and Conditions. Should you breach these
                Terms and Conditions, GammonPro reserves the right to restrict or limit your access (and your IP address) to the
                Web Site. The terms "You" and “Your” refer to all individuals and/or entities accessing this web site for any
                reason, whether or not you are a patient of the Company.
                <div className="item-title">Use of the Web Site.</div>
                <div className="item-content">
                  You may not use this Web Site to transmit, distribute, store or destroy material in violation of any applicable
                  law or regulation; (a) in a manner that will infringe the copyright, trademark, trade secret or other
                  intellectual property rights of others; (b) in a manner that violates the privacy, publicity or other personal
                  rights of others; or (c) that is defamatory, obscene, illegal, threatening, abusive or hateful.
                </div>
                <div className="item-content">
                  You may not violate or attempt to violate the security of the Web Site, including, but not limited to:
                </div>
                <div className="item-content">
                  a: accessing data not intended for You or by logging into a server or account which You are not authorized to
                  access;
                </div>
                <div className="item-content">
                  b: attempting to probe, scan or test the vulnerability of a system or network or to breach security or
                  authentication measures without proper authorization;
                </div>
                <div className="item-content">
                  c: attempting to interfere with service to any user, host or network, via means of submitting a virus to the Web
                  Site, overloading, "flooding", "spamming", “mailbombing,” or "crashing" or any similar introduction of malicious
                  code;
                </div>
                <div className="item-content">
                  d: sending unsolicited e-mail, including promotions and/or advertising of products or services; or
                </div>
                <div className="item-content">
                  e: forging any TCP/IP packet header or any part of the header information in any e-mail or newsgroup posting.
                </div>
                <div className="item-content">
                  GammonPro will investigate occurrences which may involve such violations and may cooperate with law enforcement
                  authorities in prosecuting any such violations. Violations of system or network security may result in civil or
                  criminal liability.
                </div>
                <div className="item-title">Prohibited Uses.</div>
                <div className="item-content">GammonPro prohibits, and You agree not to use the Web Site to:</div>
                <div className="item-content">a: Delete or revise any material posted by any other user or entity.</div>
                <div className="item-content">
                  b: Use any device, software or routine to interfere or attempt to interfere with the proper working of this Web
                  Site.
                </div>
                <div className="item-content">
                  c: Take any action which imposes an unreasonable or disproportionately large load on this Web Site’s
                  infrastructure.
                </div>
                <div className="item-content">
                  d: If you have a password allowing access to a non-public area of this Web Site, disclosing to or sharing your
                  password with any third parties or using your password for any unauthorized purpose. You are responsible for all
                  use of your registration, whether or not you authorize such use and You agree to immediately notify GammonPro of
                  any unauthorized use of Your registration or password.
                </div>
                <div className="item-content">
                  e: Frame or link to any of the Material or information available from the Web Site.
                </div>
                <div className="item-content">
                  f: Post material that is copyrighted, unless You are the copyright owner or have the permission of the copyright
                  owner to post it; post material that reveals trade secrets, unless You own them or have the permission of the
                  owner.
                </div>
                <div className="item-content">
                  g: Post material that contains viruses, Trojan horses, worms, time bombs, cancel bots or other computer
                  programming routines or engines that are intended to damage, detrimentally interfere with, surreptitiously
                  intercept or expropriate any system, data or information.
                </div>
                <div className="item-content">In addition to the foregoing, GammonPro prohibits You from:</div>
                <div className="item-content">
                  a: Sending unsolicited mail or e-mail, or other communications, making unsolicited phone calls, sending
                  unsolicited faxes, or sending other communications through the Web Site regarding promotions and/or advertising
                  of products or services to other Users;
                </div>
                <div className="item-content">
                  b. Notwithstanding anything to the contrary contained herein, using or attempting to use any engine, software,
                  tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or
                  intelligent agents) to navigate or search the Web Site other than the search engine and search agents available
                  from the Web Site and other than generally available third party web browsers (e.g., Internet Explorer, Firefox,
                  Safari);
                </div>
                <div className="item-content">
                  c. Deciphering, decompiling, disassembling or reverse engineering any of the software comprising or in any way
                  making up a part of the Web Site, including without limitation, the Company’s applications (each application is
                  hereinafter referred to as an “App.”); or
                </div>
                <div className="item-content">
                  d. Aggregating, copying or duplicating in any manner all or part of the Web Site Content or information
                  available from the Web Site, without express written consent from GammonPro.
                </div>
                <div className="item-title">Use of Material.</div>
                <div className="item-content">
                  GammonPro authorizes You to view and download a single copy of the Material on the Website solely for your
                  personal, noncommercial use. In the event any material on the Website is not owned by the Company, such material
                  has been specifically licensed for use by GammonPro. Any and all Material on the Website is property of
                  GammonPro (or specifically licensed to GammonPro) and therefore not permitted to be copied, modified, amended or
                  reproduced in any way without the prior, written consent of the Rights’ Holder(s).GammonPro reserves the right
                  to monitor analytics to determine abuses of these terms and conditions and to restrict access or take other
                  legal action against abusers of this Policy.
                </div>
                <div className="item-content">
                  The contents of the Web Site, such as text, graphics, images, logos, button icons, software and other materials
                  (collectively "Material") are property of GammonPro or its content suppliers or clients and may be protected
                  under both United States and foreign copyright, trademark and other laws. The collection, arrangement, assembly,
                  display and appearance of all content on this Web Site is the exclusive property of GammonPro and are protected
                  by United States and international copyright laws. Unauthorized use of the Material may violate copyright,
                  trademark, and other privacy laws. You must retain all original copyright, trademark, service-mark and other
                  proprietary notices on any copy you make of the Material. You may not sell or modify the Material or reproduce,
                  display, publicly perform, distribute, or otherwise use the Material on any other web site or in any way for any
                  reason, whether private, public or commercial. You may not copy or adapt the HTML code that GammonPro creates to
                  generate its pages.
                </div>
                <div className="item-title">User Information.</div>
                <div className="item-content">
                  If and when You register for the Web Site (or if you are a patient, for a patient login, when available), You
                  may be asked to provide certain information including, without limitation, a valid email address (Your
                  "Information"). In addition to the terms and conditions that may be set forth in any privacy policy on this Web
                  Site, You understand and agree that GammonPro may disclose to third parties, on an anonymous basis, certain
                  aggregate information contained in Your registration application. GammonPro will not disclose to any third party
                  Your name, address, e-mail address or telephone number without Your prior consent, except to the extent
                  necessary or appropriate to comply with applicable laws or in legal proceedings where such information is
                  relevant. GammonPro reserves the right to offer third party services and products to You based on the
                  preferences that You identify in your registration and at any time thereafter, subject to your ability to opt
                  out and to limit such marketing as is provided in our Privacy Policy, which is incorporated herein by reference;
                  such offers may be made by GammonPro or by third parties. Information will be stored in the United States in
                  accordance with United States Privacy Laws. Please see GammonPro’s Privacy Policy for further details regarding
                  your Information.
                </div>
                <div className="item-title">Submissions to the Web Site.</div>
                <div className="item-content">
                  You are responsible for Your own communications and for the consequences of their posting. GammonPro does not
                  represent or guarantee the truthfulness, accuracy, or reliability of communications posted by users, nor does it
                  endorse any opinions expressed by users. You acknowledge that you rely on any material posted by other users at
                  your own risk.
                </div>
                <div className="item-content">
                  GammonPro acts only as a passive conduit for the online distribution and publication of user-submitted
                  information and has no obligation to screen communications or information in advance.GammonPro is not
                  responsible for screening or monitoring material posted by users.GammonPro may investigate and determine in good
                  faith and its sole discretion whether to remove or request the removal of a communication that does not conform,
                  or is alleged not to conform, with these Terms and Conditions. GammonPro has no liability or responsibility to
                  You for its performance or non-performance of such investigations or removal. GammonPro reserves the right to
                  prevent Your further access to the Web Site for violating the Terms and Conditions or the law and the right to
                  remove communications which violate these Terms and Conditions. GammonPro may take any action with respect to
                  user-submitted information that it deems necessary or appropriate in its sole discretion if it believes it may
                  create liability for GammonPro or may cause GammonPro to lose (in whole or in part) the services of its ISPs or
                  other suppliers.
                </div>
                <div className="item-content">
                  By submitting content to any area of the Web Site, including message boards, forums, blogs, contests and chat
                  rooms, if any, You grant GammonPro and its affiliates the royalty-free, perpetual, irrevocable, sub-licensable,
                  non-exclusive right (including any moral rights) and license to use, reproduce, modify, adapt, publish,
                  translate, create derivative works from, distribute, communicate to the public, perform and display the content
                  (in whole or in part) worldwide and/or to incorporate it in other works in any form, media, or technology now
                  known or later developed, for the full term of any rights that may exist in such content. You also warrant that
                  the holder of any rights, if not You, including moral rights in such content, has completely and effectively
                  waived all such rights and validly and irrevocably granted to You the right to grant the license stated above.
                  You also permit any other user to access, display, view, store and reproduce such content for personal use.
                  Subject to the foregoing, the owner of such content placed on the Web Site retains any and all rights that may
                  exist in such content.
                </div>
                <div className="item-content">
                  Any hyperlinks contained in posting will be disabled when posted on the Web Site.
                </div>
                <div className="item-title">Links to Other Sites.</div>
                <div className="item-content">
                  Links to third party web sites are provided on the Website as a convenience to you and not as an endorsement by
                  GammonPro of the content on third-party Web sites. GammonPro is not responsible for the content of linked
                  third-party sites and does not make any representations regarding the content or accuracy of materials on such
                  third party Web sites. You hereby acknowledge that you access linked third party web sites at your own risk.
                </div>
                <div className="item-title">Copyright Infringement.</div>
                <div className="item-content">
                  GammonPro respects the intellectual property rights of others and request that the people who use the Web Site
                  do the same. If you believe that your work has been reproduced and is accessible on the Web Site in a way that
                  constitutes copyright infringement, you may notify us by providing our copyright agent with the following
                  information in writing:
                </div>
                <div className="item-content">
                  a. the electronic or physical signature of the owner of the copyright or the person authorized to act on the
                  owner’s behalf;
                </div>
                <div className="item-content">b. identification of the copyrighted work that you claim has been infringed;</div>
                <div className="item-content">
                  c. indemnification of the material that is claimed to be infringing and information reasonably sufficient to
                  permit Hyperlink Inc to locate the material (for example, by providing a URL to the material);
                </div>
                <div className="item-content">d. your name, address, telephone number, and email address;</div>
                <div className="item-content">
                  e. a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright
                  owner, its agent, or the law; and
                </div>
                <div className="item-content">
                  f. a statement that the information in your notification is accurate and a statement, made under penalty of
                  perjury, that you are the copyright owner or are authorized to act on the copyright owner’s behalf.
                </div>
                <div className="item-content">
                  Our designated agent to receive notification of claimed infringement can be reached at: info@GammonPro.com.
                </div>
                <div className="item-title">Indemnity.</div>
                <div className="item-content">
                  You agree to defend, indemnify, and hold harmless GammonPro, its officers, directors, employees, representatives
                  and agents, from and against any claims, actions or demands, including without limitation reasonable legal and
                  accounting fees, resulting from your use of the Material or alleging Your breach of the terms of these Terms and
                  Conditions. GammonPro will provide prompt notice to You of any such claim, suit, or proceeding and shall assist
                  You, at your sole expense, in defending any such claim, suit or proceeding.
                </div>
                <div className="item-content">
                  No WARRANTIES.GammonPro DOES NOT WARRANT THAT THE WEB SITE WILL OPERATE ERROR-FREE OR THAT THE WEB SITE AND ITS
                  SERVER ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL MECHANISMS. IF YOUR USE OF THE WEB SITE OR THE MATERIAL
                  RESULTS IN DAMAGE TO YOUR EQUIPMENT OR THE LOSS OR CORRUPTION OF DATA, GammonPro IS NOT RESPONSIBLE FOR SUCH
                  DAMAGE OR FOR ANY ASSOCIATED COSTS.
                </div>
                <div className="item-content">
                  THE WEB SITE AND MATERIAL ARE PROVIDED "AS IS" WITHOUT ANY WARRANTIES OF ANY KIND. GammonPro, TO THE EXTENT
                  PERMITTED BY U.S. LAW, DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTY OF
                  MERCHANTABILITY, QUALITY, FITNESS FOR PARTICULAR PURPOSE AND NON-INFRINGEMENT. GammonPro MAKES NO WARRANTIES
                  ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE MATERIAL, SERVICES, SOFTWARE, TEXT,
                  GRAPHICS, AND LINKS.
                </div>
                <div className="item-title">GammonPro Limitation on Liability.</div>
                <div className="item-content">
                  Because GammonPro is not involved in user-to-user dealings and does not control the behavior of participants on
                  the Web Site and in the Blog, in the event that You have a dispute with one or more other users, You release
                  GammonPro (and our agents and employees) from claims, demands and damages (contractual, actual and
                  consequential, direct and indirect, punitive) of every kind and nature, known and unknown, suspected and
                  unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.
                </div>
                <div className="item-content">
                  GammonPro is under no legal obligation to, and generally does not, control the information provided by other
                  users which is made available through the Web Site. Other user’s information may, by its nature, be offensive,
                  harmful or inaccurate, and in some cases will be mislabeled or deceptively labeled. We expect that You will use
                  caution and common sense when using this Web Site.
                </div>
                <div className="item-content">
                  GammonPro makes no representations about the accuracy, reliability, completeness, or timeliness of the Web Site
                  or the Material. You use the Web Site and the Material at your own risk. You are solely responsible for the
                  form, content and accuracy of any information contained in resumes or other material submitted by You to the Web
                  Site.
                </div>
                <div className="item-title">Disclaimer of Consequential Damages.</div>
                <div className="item-content">
                  IN NO EVENT SHALL GammonPro, ITS SUPPLIERS, OR ANY THIRD PARTIES MENTIONED ON THE WEB SITE BE LIABLE FOR ANY
                  DAMAGES WHATSOEVER, WHETHER FOR BODILY, MORAL OR MATERIAL INJURY (INCLUDING, BUT NOT LIMITED TO, INDIRECT,
                  PUNITIVE, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
                  INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE WEB SITE AND THE MATERIAL, WHETHER BASED ON
                  WARRANTY, CONTRACTUAL OR EXTRA-CONTRACTUAL LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT GammonPro IS
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </div>
                <div className="item-title">Limitation of Liability.</div>
                <div className="item-content">
                  GammonPro’s aggregate liability to You for all claims arising from the use of the Materials and the Web Site is
                  limited to one hundred dollars ($100.00).
                </div>
                <div className="item-title">Termination.</div>
                <div className="item-content">
                  If You breach any of these Terms and Conditions or if GammonPro is unable to verify or authenticate any
                  information you submit to the Web Site, GammonPro may pursue all of its legal or equitable remedies including,
                  but not limited to, deletion of Your postings from this Web Site and immediate termination of your registration,
                  account or ability to access the Web Site and/or any other service provided to You by GammonPro without further
                  notice to you.
                </div>
                <div className="item-title">General.</div>
                <div className="item-content">
                  GammonPro makes no claims that the Materials may be lawfully viewed or downloaded outside of the United States.
                  Access to the Materials may not be legal by certain persons or in certain countries. If you access the Web Site
                  from outside of the United States, you do so at your own risk and are responsible for compliance with the laws
                  of your jurisdiction. These Terms and conditions are governed by the laws of the State of New York, without
                  respect to its conflict of laws principles. Jurisdiction for any claims arising under this agreement shall lie
                  exclusively with the courts having jurisdiction within the State of New York, County of New York. If any
                  provision of these Terms and Conditions are found to be invalid by any court having competent jurisdiction, the
                  invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and
                  Conditions, which shall remain in full force and effect. No waiver of any term of these Terms and Conditions
                  shall be deemed a further or continuing waiver of such term or any other term. Except as expressly provided in
                  additional terms of use for areas of the Web Site a particular "Legal Notice," or Software License or material
                  on particular Web pages, these Terms and Conditions constitute the entire agreement between you and GammonPro
                  with respect to the use of the Web Site. No changes to these Terms and Conditions shall be made except by a
                  revised posting on this page.
                </div>
                <div className="item-title">Additional Terms of Use.</div>
                <div className="item-content">
                  Certain areas of this Web Site may be subject to additional terms of use. By using such areas, or any part
                  thereof, You agree to be bound by the additional terms of use applicable to such areas.
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomFooter history={this.props.history} />
      </>
    );
  }
}

export default PrivacyAndTerms;
