import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { GameColor } from '../../api/enums';
import UserStatus from '../UserStatus/UserStatus';
import { Checker } from '../Checker/Checker';

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/image';
import TimerProvider from '../../providers/TimerProvider';
import emptyAvatar from '../../assets/empty-avatar.png';
import { PlayerProfile } from '../../api/models';
import Row from 'react-bootstrap/Row';
import botAvatar from '../../assets/bot.jpg';

interface Props {
  profile: PlayerProfile;
  isActive: boolean;
  score: number;
  isBot?: boolean;
  isMobile: boolean;
  isOverPlayerTimer?: () => void;
  player: GameColor;
  timelimit: number;
}

class UserInfo extends Component<Props> {

  BAR_STYLES = {
    root: {},
    path: {
      stroke: '#FFAD31',
      transition: 'stroke-dashoffset 0.5s ease 0s',
      transform: 'rotate(0.25turn)',
      transformOrigin: 'center center',
    },
    trail: {
      stroke: 'transparent',
    },
    text: {
      fill: 'white',
      fontSize: this.props.isMobile ? '2em' :'200%',
      fontFamily: 'Roboto Slab',
    },
    background: {
      fill: 'rgba(37, 37, 37, 0.76)',
    },
  };

  childRef = React.createRef<TimerProvider>();

  // DO NOT DELETE FOR NOW
  // callStart = () => {
  //   if (!this.childRef.current) {
  //     return;
  //   }
  //   this.childRef.current.startTimer();
  // };

  render() {
    return (
      <Card className="userInfo">
        {/* <button type="button" onClick={this.callStart}>
          Call Start
        </button> */}
        <div className="user-img">
          <div className="avatar-group userAvatar rounded-circle">
            <Image
              src={
                this.props.isBot
                  ? botAvatar
                  : this.props.profile && this.props.profile.avatar && this.props.profile.avatar != 'bot'
                  ? this.props.profile.avatar
                  : emptyAvatar
              }
              className="rounded-circle"
            />
            {this.props.isActive ? (
              <TimerProvider
                startTimeInSeconds={this.props.timelimit}
                interval={1000}
                autoStart={true}
                isOver={this.props.isOverPlayerTimer}
                ref={this.childRef}
              >
                {(sec: number) => (
                  <CircularProgressbar
                    className="timer-bar"
                    maxValue={this.props.timelimit}
                    value={sec}
                    text={sec.toString()}
                    background
                    backgroundPadding={0}
                    styles={this.BAR_STYLES}
                  />
                )}
              </TimerProvider>
            ) : null}
          </div>
          <Checker replacerColor={false} player={this.props.player} />
          {!this.props.isBot ? (
            <div className="pl-1 pr-1 user-rating"> {this.props.profile ? this.props.profile.rating.toString() : ''} </div>
          ) : null}
        </div>

        <Row className="m-0">
          <div className="user-name">
            {this.props.isBot
              ? 'Gammon Bot'
              : this.props.profile
              ? this.props.profile.displayName || this.props.profile.email
              : ''}{''}
          </div>
        </Row>

        <div className="userScore">{this.props.score}</div>
        {(!this.props.isBot && !this.props.isMobile) ? (
          <UserStatus
            allGames={this.props.profile ? this.props.profile.totalGames : 0}
            winsGames={this.props.profile ? this.props.profile.wonGames : 0}
            lossesGames={this.props.profile ? this.props.profile.looseGames : 0}
          />
        ) : (
          <div className="p-2"></div>
        )}
      </Card>
    );
  }
}

export default UserInfo;
