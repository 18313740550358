import React from 'react';
import { useDrop } from 'react-dnd';
import { GameColor, PositionType } from '../../api/enums';
import { ItemTypes } from './ItemTypes';
import Col from 'react-bootstrap/Col';
import { selectClass } from '../../helpers';
import { SoundPlayer } from '../../sounds/soundPlayer';
import { Sound } from '../../sounds/soundEnum';

export interface DropTriangleProps {
  allowedDropEffect: string;
  position: PositionType;
  color: GameColor;
  canReceive: any;
  canMove: any;
  children?: React.ReactNode;
}

export const DropTriangle: React.FC<DropTriangleProps> = ({
  allowedDropEffect,
  children,
  position,
  color,
  canReceive,
  canMove,
}) => {
  const player = SoundPlayer.getInstance();

  let classOrientation = '';
  let classColor = '';
  let classReceivable = '';
  let pointContainerClasses = '';

  if (position === PositionType.Top) {
    classOrientation = 'Up';
  } else {
    classOrientation = 'Down';
    pointContainerClasses = ' pointContainerDown';
  }

  if (color !== GameColor.White) {
    classColor += 'C2';
  }

  if (canReceive) {
    pointContainerClasses += ' containerClickable';
    classReceivable = 'Receivable';
    classColor = '';
  }
  if (canMove) {
    pointContainerClasses += ' containerClickable';
  }

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.BOX,
    canDrop: (item, monitor) => canReceive,
    drop: () => {
      player.play(Sound.Drop);
      canReceive();
      return {
        name: `${allowedDropEffect} DropTriangle`,
        allowedDropEffect,
      };
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  return (
    <Col xs={2} className={`triangle ${selectClass(isActive, canDrop)}`} ref={drop}>
      <div className={'trianglePart triangleLeft' + classOrientation + classColor + classReceivable}></div>
      <div className={'trianglePart triangleRight' + classOrientation + classColor + classReceivable}></div>
      <div
        className={'pointContainer ' + pointContainerClasses}
        onClick={(e) => {
          classOrientation = '';
          classColor = '';
          classReceivable = '';
          pointContainerClasses = '';
          if (!isActive) {
            if (canReceive) {
              player.play(Sound.Drop);
              canReceive();
              pointContainerClasses += ' containerClickable';
              classReceivable = 'Receivable';
              classColor = '';
            }
            if (canMove) {
              player.play(Sound.Drag);
              canMove();
              pointContainerClasses += ' containerClickable';
            }
          }
        }}
      >
        {children}
      </div>
    </Col>
  );
};
