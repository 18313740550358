import axios, { AxiosResponse, AxiosError } from 'axios';
import {
  Game,
  Lanes,
  BarCheckers,
  BoardModel,
  PlayerProfile,
  Token,
  GameLobby,
  Move,
  PlayerAction,
  CreatedLobby,
  LobbyList,
} from '../../api/models';
import { InitialGameStateType } from './gameReducer';
import { GameStatus, GameColor } from '../../api/enums';
import { FirebaseReducer } from 'react-redux-firebase';
import { store } from '../store';

const URL = process.env.REACT_APP_API_URL;
const API = URL + 'api/';

// action types
export const GET_TOKEN = 'GET_TOKEN';
export const CREATE_LOBBY = 'CREATE_LOBBY';
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const SET_MY_PICTURE = 'SET_MY_PICTURE';
export const JOIN_LOBBY = 'JOIN_LOBBY';
export const GET_LOBBY = 'GET_LOBBY';
export const GET_LOBBY_LIST = 'GET_LOBBY_LIST';
export const SET_ACTIVE_GAME = 'SET_ACTIVE_GAME';
export const CHANGE_NEXT = 'CHANGE_NEXT';
export const PREPARE_NEW_GAME = 'PREPARE_NEW_GAME';
export const CLEAR_GAME = 'CLEAR_GAME';
export const SETUP_NEW_GAME = 'SETUP_NEW_GAME';
export const CHANGE_GAME_STATUS = 'CHANGE_GAME_STATUS';
export const CHANGE_GAME_POINTS = 'CHANGE_GAME_POINTS';
export const CHANGE_ON_BAR = 'CHANGE_ON_BAR';
export const CHANGE_OUTSIDE_BAR = 'CHANGE_OUTSIDE_BAR';
export const UPDATE_BOARD = 'UPDATE_BOARD';
export const CHANGE_DICE_VALUES = 'CHANGE_DICE_VALUES';
export const UPDATE_MOVES = 'UPDATE_MOVES';
export const CHANGE_CURRENT_PLAYER_ID = 'CHANGE_CURRENT_PLAYER_ID';
export const CHANGE_FLAG_NO_MOVE = 'CHANGE_FLAG_NO_MOVE';
export const CHANGE_COLOR = 'CHANGE_COLOR';
export const ROTATE_BOARD = 'ROTATE_BOARD';
export const GET_OPPONENT_PROFILE = 'GET_OPPONENT_PROFILE';
export const PLAY_WITH_BOT = 'PLAY_WITH_BOT';
export const OFFER_DOUBLE_STAKE = 'OFFER_DOUBLE_STAKE';
export const ACCEPT_DOUBLE_STAKE = 'ACCEPT_DOUBLE_STAKE';
export const CHANGE_DOUBLING_CUBE = 'CHANGE_DOUBLING_CUBE';
export const CHANGE_DONE_CLICKED = 'CHANGE_DONE_CLICKED';

export type InferActionsTypes<T> = T extends { [keys: string]: (...args: any[]) => infer U } ? U : never;
export type ActionsType = InferActionsTypes<typeof gameActions>;
export type StateFunc = () => { firebase: FirebaseReducer.Reducer; game: InitialGameStateType };

// action creators
export const gameActions = {
  createLobby: (lobbyId: number) => ({ type: CREATE_LOBBY, lobbyId } as const),
  joinLobby: (lobbyId: number, isRotated: boolean) => ({ type: JOIN_LOBBY, lobbyId, isRotated } as const),
  joinBotLobby: () => ({ type: JOIN_LOBBY } as const),
  playWithBot: () => ({ type: PLAY_WITH_BOT } as const),
  setLobby: (lobby: GameLobby, meIsPlayer1: boolean) => ({ type: GET_LOBBY, lobby, meIsPlayer1 } as const),
  setLobbyList: (lobbyList: LobbyList) => ({ type: GET_LOBBY_LIST, lobbyList } as const),
  setActiveGame: (game: Game, isMyTurn: boolean) => ({ type: SET_ACTIVE_GAME, game, isMyTurn } as const),
  setIsMyTurn: (isMyTurn: boolean) => ({ type: CHANGE_NEXT, isMyTurn } as const),
  prepareGame: () => ({ type: PREPARE_NEW_GAME } as const),
  clearGame: () => ({ type: CLEAR_GAME } as const),
  setupGame: (points: Lanes[]) => ({ type: SETUP_NEW_GAME, points } as const),
  changeGameStatus: (status: GameStatus) => ({ type: CHANGE_GAME_STATUS, status } as const),
  changePoints: (points: Lanes[]) => ({ type: CHANGE_GAME_POINTS, points } as const),
  changeCheckersOnBar: (checkersOnBar: BarCheckers) => ({ type: CHANGE_ON_BAR, checkersOnBar } as const),
  changeCheckersOutSideBar: (outsideCheckers: BarCheckers) => ({ type: CHANGE_OUTSIDE_BAR, outsideCheckers } as const),
  changeBoard: (board: BoardModel) => ({ type: UPDATE_BOARD, board } as const),
  changeDice: (dice: number[]) => ({ type: CHANGE_DICE_VALUES, dice } as const),
  changeMoves: (moves: Move[]) => ({ type: UPDATE_MOVES, moves } as const),
  changeCurrentPlayerId: (currentPlayerId: string) => ({ type: CHANGE_CURRENT_PLAYER_ID, currentPlayerId } as const),
  changeFlagNoMove: (opponentNoMove: boolean) => ({ type: CHANGE_FLAG_NO_MOVE, opponentNoMove } as const),
  changeColor: (color: GameColor) => ({ type: CHANGE_COLOR, color } as const),
  rotateBoard: (value: boolean) => ({ type: ROTATE_BOARD, value } as const),
  setOpponentProfile: (opponenProfile: PlayerProfile) => ({ type: GET_OPPONENT_PROFILE, opponenProfile } as const),
  offerDoubleStake: () => ({ type: OFFER_DOUBLE_STAKE } as const),
  acceptDubleStake: () => ({ type: ACCEPT_DOUBLE_STAKE } as const),
  changeDoublingCube: (value: number) => ({ type: CHANGE_DOUBLING_CUBE, value } as const),
  changeDoneClicked: (clicked: boolean) => ({ type: CHANGE_DONE_CLICKED, clicked} as const),
};

export const offerDoubleStake = () => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    return axios
      .post(`${API}Games/${getState().game.activeGame.gameId}/cube/offerdoublestake`, '', {
        headers: {
          Authorization: `Bearer ${userStore.token.accessToken}`,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          dispatch(gameActions.offerDoubleStake());
        }
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

export const acceptDubleStake = () => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    return axios
      .post(`${API}Games/${getState().game.activeGame.gameId}/cube/acceptdoublestake`, '', {
        headers: {
          Authorization: `Bearer ${userStore.token.accessToken}`,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          dispatch(gameActions.offerDoubleStake());
        }
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

// export const rejectDoubleStake = () => {
//   return (dispatch: any, getState: StateFunc) => {
//     const userStore = store.getState().user;
//     return axios
//       .post(`${API}Games/${getState().game.activeGame.gameId}/cube/rejectdoublestake`, '', {
//         headers: {
//           Authorization: `Bearer ${userStore.token.accessToken}`,
//         },
//       })
//       .then((response) => {
//         if (response.status == 200) {
//           dispatch(gameActions.offerDoubleStake());
//         }
//       })
//       .catch((err: AxiosError) => console.log(err));
//   };
// };

export const createLobby = () => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    return axios
      .post(`${API}Lobbies/`, { userId: userStore.myProfile.userId, isHostRotated: getState().game.isRotated })
      .then((response: AxiosResponse<CreatedLobby>) => {
        dispatch(gameActions.createLobby(response.data.lobbyId));
        console.log(response.data.lobbyId);
        return Promise.resolve(response.data);
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

export const joinLobby = (lobbyId: number, isRotated: boolean) => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    return axios
      .post(`${API}Lobbies/${lobbyId}/join`, {
        userId: userStore.myProfile.userId,
      })
      .then((response: AxiosResponse<GameLobby>) => {
        dispatch(gameActions.joinLobby(lobbyId, isRotated));
        console.log('Joined To Lobby!!!', response);
        return response;
      })
      .catch((err: AxiosError) => {
        // lobby is closed
        return Promise.reject(err);
      });
  };
};

export const getLobby = (id: number) => {
  return (dispatch: any) => {
    return axios
      .get(`${API}Lobbies/${id}`)
      .then((response: AxiosResponse<GameLobby>) => {
        const userStore = store.getState().user;
        const meIsPlayer1 = response.data.hostUser.userId === userStore.myProfile.userId;
        dispatch(gameActions.setLobby(response.data, meIsPlayer1));
        return Promise.resolve(response.data);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  };
};

export const getLobbyList = ({ page, status, ascending }) => {
  const begin = page * 10;
  const end = page * 10 + 10;
  return (dispatch: any) => {
    return axios
      .get(`${API}Lobbies?begin=${begin}&end=${end}&status=${status}&ascending=${ascending}`) // temp
      .then((response: AxiosResponse<any>) => {
        dispatch(gameActions.setLobbyList(response.data));
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  };
};

export const setActiveGame = (game: Game) => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    game.isMyFirstTurn = userStore.myProfile.userId === game.firstMovePlayerId;
    game.meIsPlayer1 = userStore.myProfile.userId === game.player1;
    game.doublingCube = 1;
    const isMyTurn = userStore.myProfile.userId === game.firstMovePlayerId;
    dispatch(gameActions.setActiveGame(game, isMyTurn));
    dispatch(gameActions.changeCurrentPlayerId(game.firstMovePlayerId));
  };
};

export const setIsMyTurn = (isMyTurn: boolean) => {
  return (dispatch: any) => {
    dispatch(gameActions.setIsMyTurn(isMyTurn));
  };
};

export const prepareNewGame = () => {
  return (dispatch: any) => {
    dispatch(gameActions.prepareGame());
  };
};

export const clearGame = () => {
  return (dispatch: any) => {
    dispatch(gameActions.clearGame());
  };
};

export const setupNewGame = () => {
  return (dispatch: any) => {
    // setup points
    let points = Array(24).fill({ playerColor: null, checkers: 0 });
    //set points
    points[0] = { playerColor: GameColor.White, checkers: 2 };
    points[11] = { playerColor: GameColor.White, checkers: 5 };
    points[16] = { playerColor: GameColor.White, checkers: 3 };
    points[18] = { playerColor: GameColor.White, checkers: 5 };

    points[23] = { playerColor: GameColor.Black, checkers: 2 };
    points[12] = { playerColor: GameColor.Black, checkers: 5 };
    points[7] = { playerColor: GameColor.Black, checkers: 3 };
    points[5] = { playerColor: GameColor.Black, checkers: 5 };

    dispatch(gameActions.setupGame(points));
  };
};

export const changeGameStatus = (status: GameStatus) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeGameStatus(status));
  };
};

export const changeDoublingCube = (value: number) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeDoublingCube(value));
  };
};

export const changeGamePoints = (points: Lanes[]) => {
  return (dispatch: any) => {
    dispatch(gameActions.changePoints(points));
  };
};

export const changeGrayBar = (checkersOnBar: BarCheckers) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeCheckersOnBar(checkersOnBar));
  };
};

export const changeOutSideBar = (outsideCheckers: BarCheckers) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeCheckersOutSideBar(outsideCheckers));
  };
};

export const getRollDiceValues = (userId: string) => {
  return (dispatch: any, getState: StateFunc) => {
    return axios
      .post(`${API}Games/${getState().game.activeGame.gameId}/rolldice?userId=${userId}`)
      .then((response: AxiosResponse<any>) => {
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

export const updateBoard = (board: BoardModel) => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    board.currentPlayerId = userStore.myProfile.userId;
    dispatch(gameActions.changeBoard(board));
  };
};

export const changeDiceValues = (dice: number[]) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeDice(dice));
  };
};

export const updateMoves = (moves: Move[]) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeMoves(moves));
    return Promise.resolve();
  };
};

export const performPlayerTurn = (playerAction: PlayerAction) => {
  return (dispatch: any, getState: StateFunc) => {
    return axios
      .post(`${API}Games/${getState().game.activeGame.gameId}/playerturn`, playerAction)
      .then((response: AxiosResponse<any>) => {
        const playerId = playerAction.currentPlayerId === getState().game.activeGame.player1 ? getState().game.activeGame.player2 : getState().game.activeGame.player1;
        dispatch(gameActions.changeCurrentPlayerId(playerId));
        dispatch(gameActions.changeMoves([]));
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

export const changeFlagNoMove = (opponentNoMove: boolean) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeFlagNoMove(opponentNoMove));
  };
};

export const leaveGame = () => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    return axios
      .post(`${API}Games/${getState().game.activeGame.gameId}/leave`, '', {
        headers: {
          Authorization: `Bearer ${userStore.token.accessToken}`,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          dispatch(gameActions.changeGameStatus(GameStatus.PlayerLose));
        }
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

export const leaveLobby = () => {
  return (dispatch: any, getState: StateFunc) => {
    const userStore = store.getState().user;
    return axios
      .post(`${API}Lobbies/${getState().game.activeLobby.id}/leave`,
        {
          userId: userStore.myProfile.userId,
        })
      .then((response) => {
        if (response.status == 200) {
          dispatch(gameActions.changeGameStatus(GameStatus.NoStarted));
        }
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

export const playWithBot = (uid: string) => {
  return (dispatch: any, getState: StateFunc) => {
    return axios
      .post(`${API}Games/playwithbot`, {userId: uid})
      .then((response: AxiosResponse<any>) => {
        dispatch(gameActions.playWithBot());
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => console.log(err));
  };
};

export const changeColor = (color: GameColor) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeColor(color));
  };
};

export const rotateBoard = (value: boolean) => {
  return (dispatch) => {
    dispatch(gameActions.rotateBoard(value));
  };
};

export const getOpponentProfile = (playerId: string) => {
  return (dispatch: any) => {
    return axios
      .get(`${API}Users/${playerId}`)
      .then((response: AxiosResponse<PlayerProfile>) => {
        dispatch(gameActions.setOpponentProfile(response.data));
        return Promise.resolve(response.data);
      })
      .catch((err: AxiosError) => {
        return Promise.reject(err);
      });
  };
};

export const changeDoneClicked = (clicked: boolean) => {
  return (dispatch: any) => {
    dispatch(gameActions.changeDoneClicked(clicked));
  }; 
}
