export const getGameStatusDescription = (status) => {
  switch (status) {
    case 2:
      return 'Lobby was created';
    case 8:
      return 'Game preparation';
    case 10:
      return 'Players roll die';
    case 11:
      return 'New game';
    case 20:
      return 'Roll dice';
    case 30:
      return 'Playing';
    case 31:
      return 'Playing from checkersOnBar';
    case 32:
      return 'Bearing off';
    case 40:
      return 'No die to play';
    case 50:
      return 'No moves available';
    case 60:
      return 'You Win';
    case 70:
      return 'You Lose';
    case 80:
      return 'Not started';
    default:
      return status;
  }
}
