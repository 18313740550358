import React, { Component } from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { changeColor, createLobby, getLobbyList, rotateBoard } from '../../redux/game/gameActions';
import { getMyProfile } from '../../redux/user/userActions';
import { AppModal } from '../Modals/AppModal';
import ColorPicker from '../ColorPicker/ColorPicker';
import { CreatedLobby, GameLobby, LobbyList, PlayerProfile } from '../../api/models';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import DirectPicker from '../DirectPicker/DirectPicker';
import { GameColor, LobbyStatus } from '../../api/enums';
import { ContentButton } from '../ContentButton/ContentButton';
import { BsPlusCircleFill } from 'react-icons/bs';
import { BiHelpCircle } from 'react-icons/bi';
import { Pagination } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import emptyAvatar from '../../assets/empty-avatar.png';
import { ReactComponent as SortArrow } from '../../assets/sort-arrow.svg';
import CustomHeader from '../CustomHeader/CustomHeader';
import CustomFooter from '../CustomFooter/CustomFooter';
import { FirebaseReducer } from 'react-redux-firebase/index.d';
import { withOrientationChange } from 'react-device-detect';
import rotationImg from '../../assets/screen_rotation.svg';
import { GameInfoModal } from '../Modals/GameInfoModal';

interface IGetLobbyList {
  page: number,
  status: number,
  ascending: boolean,
}

interface Props {
  history: History;
  lobbyList?: LobbyList;
  profile?: PlayerProfile;
  myColor?: GameColor;
  isRotated?: boolean;
  isNewUser: boolean;
  auth: FirebaseReducer.AuthState;

  getLobbyList: (props: IGetLobbyList) => Promise<any>;
  getMyProfile: (playerId: string) => void;
  createLobby: () => Promise<CreatedLobby>;
  changeColor: (color: GameColor) => void;
  rotateBoard: (value: boolean) => void;
  isPortrait: boolean
}

interface State {
  loginModalShow: boolean;
  newGameModalShow: boolean;
  joinGameModalShow: boolean;
  lobbyData: GameLobby[];
  page: number;
  isOpenMenu: boolean;
  ascending: boolean;
  joinGameId: number;
  showHelp: boolean;
}

const gamesOnPage = 10;

let getPaginationList = (length, currentPage, onChange) => {
  const paginationLength = 4;
  const pageCount = Math.floor(length / gamesOnPage);
  const items = [];

  const addItem = (i) => {
    items.push(<Pagination.Item
      key={i}
      active={i === currentPage}
      onClick={() => onChange(i)}
    >{i + 1}</Pagination.Item>);
  };
  const addDisabledItem = () => {
    items.push(<Pagination.Item
      key={pageCount - 1}
      active={false}
      onClick={() => onChange(pageCount - 1)}
    >{pageCount}</Pagination.Item>);
  };

  const addEllipsis = (key) => {
    items.push(<Pagination.Ellipsis className={'more'} key={key} />);
  };

  if (pageCount <= paginationLength) {
    for (let i = 0; i <= pageCount; i++) {
      addItem(i);
    }
  } else {
    if (currentPage + 1 < paginationLength - 1) {
      for (let i = 0; i < paginationLength - 1; i++) {
        addItem(i);
      }
      addEllipsis(pageCount - 2);
      addDisabledItem();
    } else {
      addEllipsis(currentPage - 2);
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        if (i !== pageCount) {
          addItem(i);
        }
      }
      if (currentPage + 1 < pageCount - 1 && currentPage + 2 !== pageCount - 1) {
        addEllipsis(currentPage + 2);
      }
      if (currentPage + 1 !== pageCount - 1 && currentPage !== pageCount - 1) {
        addDisabledItem();
      }
    }
  }

  return items;
};

class Lobbies extends Component<Props, State> {
  state = {
    loginModalShow: true,
    newGameModalShow: false,
    joinGameModalShow: false,
    page: 0,
    joinGameId: null,
    lobbyData: [],
    isOpenMenu: false,
    ascending: true,
    showHelp: this.props.isNewUser,
  };

  parentRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.loadPageData();
    this.setState({
      lobbyData: [...this.props.lobbyList.lobbies.filter((lobby) => lobby.hostUser.userId !== this.props.auth.uid)],
    });
    // this.updateDimensions();
    // window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (
      this.state.ascending !== prevState.ascending ||
      this.props.lobbyList.lobbies.length !== prevProps.lobbyList.lobbies.length ||
      this.props.lobbyList.lobbies[0]?.id !== prevProps.lobbyList.lobbies[0]?.id
    ) {
      this.setState({
        lobbyData: [...this.props.lobbyList.lobbies.filter((lobby) => lobby.hostUser.userId !== this.props.auth.uid)],
      });
    }
    if (this.state.page !== prevState.page) {
      this.loadPageData();
    }
    if (this.props.lobbyList.lobbies.length === 0 && this.props.lobbyList.count !== 0) {
      this.setState({ page: Math.floor(this.props.lobbyList.count / gamesOnPage) });
    }
    // this.updateDimensions();
    // window.addEventListener('resize', this.updateDimensions);
  }

  setNewGameModalShow = (newGameModalShow: boolean) => {
    this.setState({ newGameModalShow });
  };

  setShowHelp = (showHelp: boolean) => {
    this.setState({ showHelp });
  };

  setJoinGameModalShow = (joinGameModalShow: boolean) => {
    this.setState({ joinGameModalShow });
  };

  cancelJoin(){
    this.setJoinGameModalShow(false);
    this.setJoinGameId(null);
  }

  setJoinGameId = (joinGameId: number) => {
    this.setState({ joinGameId });
  };

  chooseColor = (color: GameColor) => {
    this.props.changeColor(color);
  };

  chooseDirect = (isRotate: boolean) => {
    this.props.rotateBoard(isRotate);
  };

  createLobbyHandle = () => {
    this.props.createLobby().then((lobby) => {
      this.props.history.push(`/lobby/${lobby.lobbyId}`);
    });
  };

  joinLobbyHandle = () => {
    if (this.state.joinGameId) {
      this.props.history.push(`/lobby/${this.state.joinGameId}`);
    }
  };

  reverseSort = () => {
    this.loadPageData(!this.state.ascending);
    this.setState({
      ascending: !this.state.ascending,
    });
  };

  loadPageData = (ascending = this.state.ascending) => {
    this.props.getLobbyList({
      ascending: ascending,
      page: this.state.page,
      status: LobbyStatus.HostWaitingOpponent,
    });
  };

  render() {
    const { myColor, isRotated, history, auth, isPortrait } = this.props;

    let newGameModal = (
      <AppModal
        show={this.state.newGameModalShow}
        onHide={() => this.setNewGameModalShow(false)}
        modalConfig={{
          showClose: true,
          headText: 'New Game',
          primaryBtnText: 'Create',
          primaryHandle: this.createLobbyHandle,
        }}
      >
        <Row className="justify-content-center">
          <ColorPicker className="pr-3" selectedColor={myColor} onPick={this.chooseColor} />
          <DirectPicker className="pl-3" isRotatedSelected={isRotated} onPick={this.chooseDirect} />
        </Row>
      </AppModal>
    );

    let joinGameModal = (
      <AppModal
        show={this.state.joinGameModalShow}
        onHide={() => this.cancelJoin()}
        modalConfig={{
          showClose: true,
          headText: 'Join Game',
          primaryBtnText: 'Join',
          primaryHandle: this.joinLobbyHandle,
        }}
      >
        <Row className="justify-content-center">
          <ColorPicker className="pr-3" selectedColor={myColor} onPick={this.chooseColor} />
        </Row>
      </AppModal>
    );

    if (isPortrait) {
      return (
        <div className="rotation-info d-flex justify-content-center align-items-center flex-column">
          <div className="text-center m-4">
            <p>To start playing -</p>
            <p>please turn the phone</p>
            <p>to landscape orientation</p>
          </div>
          <Image className="h-100" src={rotationImg} />
        </div>
      );
    }


    let gameInfoModal = (
      <GameInfoModal
        show={this.state.showHelp}
        onHide={() => this.setShowHelp(false)}
        parentRef={this.parentRef}
        modalConfig={{
          headText: 'Help',
          primaryBtnText: 'Got It!',
        }}
      >
        <p className="text-left mb-3"><strong>"Create New"</strong> allows you to create a new game against a HUMAN opponent. Click on <strong>"Create New"</strong> select colour and board direction, click <strong>"Create"</strong> and then wait for an opponent to accept your challenge.</p>
        <p className="text-left mb-3"><strong>"Update List"</strong> displays all games available both by Humans and Bots. Click this button for the latest list.</p>
        <p className="text-left mb-3"><strong>"Single Player"</strong> starts a new game against GammonBot, our house Bot.</p>
        <p className="text-left mb-4"><strong>"Join Game"</strong> starts a new game against the player (Human or Bot) displayed on the left side of the screen.</p>
        <p className="text-left mb-3">Currently you can only move your chips by clicking on them, and then click on the destination spots where the chips can be moved to. Destination spots will illuminate in Green to make this process simple.</p>
      </GameInfoModal>
    );

    return (
      <>
        <div className="flex-grow-1" ref={this.parentRef}>
          <CustomHeader history={history} statusAndDisableAd={true} />
          <div className="table__container">
              <div className={'table-header'}>
                <Row className="p-0 m-0">
                  <span className={'table-header__title'}>Available games</span>
                  <div className={'table-header__controls'}>
                    <div className={'controls__wrapper'}>
                      <ContentButton  variant="app-blue" text="Create New" btnClick={() => this.setNewGameModalShow(true)}>
                        <BsPlusCircleFill className="create-game w-100 h-100" />
                      </ContentButton>
                      <Button
                        variant="app-outline"
                        className="p-0 update-btn"
                        onClick={() => this.loadPageData(this.state.ascending)}
                      >
                        Update List
                      </Button>
                      <ContentButton variant="app-primary" text="Help" btnClick={() => this.setShowHelp(true)}>
                        <BiHelpCircle className="help-btn w-100 h-100"/>
                      </ContentButton>
                    </div>
                    <Button
                      variant="app-main"
                      className="p-0 single-pl-btn"
                      onClick={() => {
                        history.push(`/lobby/playwithbot`);
                      }}
                    >
                      Single Player
                    </Button>
                  </div>
                </Row>
              </div>
            <Table className={'lobby'}>
              <thead>
                <tr>
                  <th>
                    <button className={'lobby__sort-button'} onClick={this.reverseSort}>
                      <span>Player</span>
                      <SortArrow className={this.state.ascending ? 'sort-up' : 'sort-down'} />
                    </button>
                  </th>
                  <th className={'lobby__ranking'}>Ranking</th>
                  <th>Games</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Join</th>
                </tr>
              </thead>
              <tbody>
                {this.state.lobbyData.length > 0 &&
                  this.state.lobbyData.map((lobby: GameLobby, i) => {
                    return (
                      <tr key={i} className={i % 2 === 0 ? 'dark' : 'light'}>
                        <td>
                          <div className={'avatar-wrapper pr-2'}>
                            <Image src={(lobby.hostUser.avatar && lobby.hostUser.avatar!=='bot') ? lobby.hostUser.avatar : emptyAvatar} className="rounded-circle" />
                          </div>

                          {`${lobby.hostUser.displayName || lobby.hostUser.email || lobby.hostUser.userId} (#${lobby.id})`}
                        </td>
                        <td className={'lobby__ranking'}>{lobby.hostUser.rating}</td>
                        <td>{lobby.hostUser.totalGames}</td>
                        <td>{lobby.hostUser.wonGames}</td>
                        <td>{lobby.hostUser.looseGames}</td>
                        <td>
                          <button
                            className="btn btn-success lobby-btn"
                            disabled={lobby.status !== 1}
                            onClick={() => {this.setJoinGameId(lobby.id); this.setJoinGameModalShow(true);}}
                          >
                            Join
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Pagination>
              <Pagination.Prev disabled={this.state.page - 1 < 0} onClick={() => this.setState({ page: this.state.page - 1 })}>
                Previous
              </Pagination.Prev>
              {getPaginationList(this.props.lobbyList.count, this.state.page, (newPage) => this.setState({ page: newPage }))}
              <Pagination.Next
                disabled={this.state.page + 1 > Math.floor(this.state.lobbyData.length / gamesOnPage)}
                onClick={() => this.setState({ page: this.state.page + 1 })}
              >
                Next
              </Pagination.Next>
            </Pagination>
            {newGameModal}
            {joinGameModal}
            {gameInfoModal}
          </div>
        </div>
        <CustomFooter history={history} />
      </>
    );
  }
}

const mapStateToProps = ({ game, user, firebase }) => {
  return {
    lobbyList: game.lobbyList,
    profile: user.myProfile,
    myColor: game.myColor,
    isRotated: game.isRotated,
    auth: firebase.auth,
    isNewUser: user.isNewUser,
  };
};

export default withOrientationChange(connect(mapStateToProps, {
  getMyProfile,
  getLobbyList,
  createLobby,
  changeColor,
  rotateBoard,
})(Lobbies));
