import React, { FunctionComponent } from 'react';
import { GameColor } from '../../api/enums';

import Image from 'react-bootstrap/Image';
import pin1 from '../../assets/pin1.svg';
import pin2 from '../../assets/pin2.svg';

type Props = {
  replacerColor: boolean;
  player: GameColor;
  canMove?: number;
  count?: number;
};

export const Checker: FunctionComponent<Props> = ({ replacerColor, player, ...props }) => {
  let classes = 'checker svg-checker';
  let containerClasses = 'checker-cont';
  // if (props.player === 1) {
  //   classes += ' checkerP1';
  // } else {
  //   classes += ' checkerP2';
  // }

  if (props.canMove === 1) {
    containerClasses += ' canMove';
  }

  return (
    <div className={containerClasses}>
      <Image
        className={classes}
        src={!replacerColor ? (player === GameColor.White ? pin1 : pin2) : player === GameColor.White ? pin2 : pin1}
        fluid
      />
      <div className="checker-num">{props.count > 1 ? props.count : ''}</div>
    </div>
  );
};
