import {
  ActionsType,
  CREATE_LOBBY,
  PREPARE_NEW_GAME,
  SETUP_NEW_GAME,
  SET_ACTIVE_GAME,
  CHANGE_NEXT,
  CHANGE_GAME_STATUS,
  CHANGE_GAME_POINTS,
  CHANGE_ON_BAR,
  CHANGE_OUTSIDE_BAR,
  UPDATE_BOARD,
  CHANGE_DICE_VALUES,
  GET_LOBBY,
  GET_LOBBY_LIST,
  UPDATE_MOVES,
  CHANGE_CURRENT_PLAYER_ID,
  CHANGE_FLAG_NO_MOVE,
  CLEAR_GAME,
  CHANGE_COLOR,
  JOIN_LOBBY,
  ROTATE_BOARD,
  GET_OPPONENT_PROFILE,
  CHANGE_DOUBLING_CUBE,
  CHANGE_DONE_CLICKED,
} from './gameActions';
import { PlayerProfile, Lanes, BarCheckers, Move, Game, GameLobby, LobbyList } from '../../api/models';
import { GameStatus, GameColor, LobbyStatus } from '../../api/enums';
import { FULLOUTSIDE } from '../../api/constants';

export type InitialGameStateType = {
  opponentProfile: PlayerProfile;
  isMyTurn: boolean;
  myColor: GameColor;
  opponentColor: GameColor;
  activeLobby?: GameLobby;
  activeGame: Game;
  gameStatus: GameStatus;
  currentPlayerId: string;
  points: Lanes[];
  checkersOnBar: BarCheckers;
  outsideCheckers: BarCheckers;
  moves: Move[];
  dice: number[];
  opponentNoMove: boolean;
  lobbyList: LobbyList;
  isRotated: boolean;
  doneClicked: boolean;
};

const initialState: InitialGameStateType = {
  opponentProfile: null,
  isMyTurn: true,
  activeLobby: null,
  activeGame: {
    player1: null,
    player2: null,
    gameId: null,
    isMyFirstTurn: false,
    meIsPlayer1: false,
    firstDicePlayer1: 0,
    firstDicePlayer2: 0,
    firstMovePlayerId: null,
    doublingCube: 1,
  },
  gameStatus: GameStatus.NoStarted, //not started

  //board
  currentPlayerId: null,
  points: Array(24).fill({ playerColor: null, checkers: 0 }),
  checkersOnBar: { firstPlayer: 0, secondPlayer: 0 },
  outsideCheckers: { firstPlayer: FULLOUTSIDE, secondPlayer: FULLOUTSIDE },
  // points: Array(24).fill({ playerColor: GameColor.Black, checkers: 7 }),
  // checkersOnBar: { firstPlayer: 2, secondPlayer: 3 },
  // outsideCheckers: { firstPlayer: 8, secondPlayer: 15 },
  moves: [],

  dice: [0],
  opponentNoMove: false,
  lobbyList: { lobbies: [], count: 0 },
  myColor: GameColor.Black,
  opponentColor: GameColor.White,
  isRotated: true,
  doneClicked: false,
};

const gameReducer = (state = initialState, action: ActionsType): InitialGameStateType => {
  switch (action.type) {
    case CREATE_LOBBY:
      const newLobby = {
        id: action.lobbyId,
        status: LobbyStatus.HostWaitingOpponent,
        gameId: null,
        hostUser: null,
        opponentUser: null,
        isHostRotated: false,
      };
      return {
        ...state,
        activeLobby: newLobby,
      };
    case JOIN_LOBBY:
      return {
        ...state,
        // isRotated: !action.isRotated,
      };
    case GET_LOBBY:
      {
        const newActiveGame = state.activeGame;
        newActiveGame.meIsPlayer1 = action.meIsPlayer1; // first player is Host
        return {
          ...state,
          activeLobby: action.lobby,
          isRotated: action.meIsPlayer1 ? action.lobby.isHostRotated : !action.lobby.isHostRotated,
          activeGame: newActiveGame,
          opponentProfile: action.meIsPlayer1 ? null : action.lobby.hostUser, // if u are no host
        };
      }
    case CHANGE_DOUBLING_CUBE:
      {
        const newActiveGame = state.activeGame;
        newActiveGame.doublingCube = action.value;
        return {
          ...state,
          activeGame: newActiveGame,
        };
      }
    case GET_LOBBY_LIST:
      return {
        ...state,
        lobbyList: { ...action.lobbyList },
      };
    case PREPARE_NEW_GAME:
      return {
        ...state,
        points: Array(24).fill({ playerColor: null, checkers: 0 }),
        gameStatus: GameStatus.PlayersRollDice,
        dice: [0],
      };
    case CLEAR_GAME:
      return {
        ...state,
        points: initialState.points,
        gameStatus: initialState.gameStatus,
        dice: initialState.dice,
        activeGame: initialState.activeGame,
        currentPlayerId: initialState.currentPlayerId,
        checkersOnBar: initialState.checkersOnBar,
        outsideCheckers: initialState.outsideCheckers,
        moves: initialState.moves,
        opponentProfile: null,
      };
    case SETUP_NEW_GAME:
      //let p: Lanes[] = Array(24).fill({ playerColor: GameColor.Black, checkers: 2 });
      //p[0] = { playerColor: GameColor.White, checkers: 1 }
      return {
        ...state,
        points: action.points,
        // points: p,
        gameStatus: GameStatus.PlayersRollDice,
      };
    case SET_ACTIVE_GAME:
      {
        return {
          ...state,
          isMyTurn: action.isMyTurn,
          activeGame: action.game,
        };
      }
    case CHANGE_NEXT:
      return {
        ...state,
        isMyTurn: action.isMyTurn,
      };
    case CHANGE_GAME_STATUS:
      return {
        ...state,
        gameStatus: action.status,
      };
    case CHANGE_GAME_POINTS:
      return {
        ...state,
        points: action.points,
      };
    case CHANGE_ON_BAR:
      return {
        ...state,
        checkersOnBar: action.checkersOnBar,
      };
    case CHANGE_OUTSIDE_BAR:
      return {
        ...state,
        outsideCheckers: action.outsideCheckers,
      };
    case UPDATE_BOARD:
      return {
        ...state,
        points: action.board.lanes,
        currentPlayerId: action.board.currentPlayerId,
        checkersOnBar: action.board.checkersOnBar,
        outsideCheckers: action.board.outsideCheckers,
      };
    case CHANGE_DICE_VALUES:
      return {
        ...state,
        dice: action.dice,
      };
    case UPDATE_MOVES:
      return {
        ...state,
        moves: action.moves,
      };
    case CHANGE_CURRENT_PLAYER_ID:
      return {
        ...state,
        currentPlayerId: action.currentPlayerId,
      };
    case CHANGE_FLAG_NO_MOVE:
      return {
        ...state,
        opponentNoMove: action.opponentNoMove,
      };
    case CHANGE_COLOR:
      return {
        ...state,
        myColor: action.color,
        opponentColor: action.color === GameColor.White ? GameColor.Black : GameColor.White
      }
    case ROTATE_BOARD:
      return {
        ...state,
        isRotated: action.value
      }
    case GET_OPPONENT_PROFILE:
      return {
        ...state,
        opponentProfile: action.opponenProfile,
      }
    case CHANGE_DONE_CLICKED:
      return {
        ...state,
        doneClicked: action.clicked,
      }
    default:
      return state;
  }
};
export default gameReducer;
