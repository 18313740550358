import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { FirebaseReducer } from 'react-redux-firebase/index.d';
import firebase from 'firebase/app';
import { ContentButton } from '../components/ContentButton/ContentButton';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as EmailIcon } from '../assets/email-icon.svg';
import { ReactComponent as PasswordIcon } from '../assets/password-icon.svg';
import Button from 'react-bootstrap/Button';
import { getToken, getMyProfile, setMyPicture, registerProfile, setNewUser } from '../redux/user/userActions';
import { toDataUrl } from '../helpers/convertToImage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { IoIosArrowBack } from 'react-icons/io';
import { PlayerProfile, PlayerRegister } from '../api/models';
import { AxiosError } from 'axios';
import { LoginPageMode } from '../api/enums';
import { IAppContext, withAppContext } from '../context';
import { AlertVariant } from './AlertList/AlertsList';
import cn from 'classnames';

interface Props extends RouteComponentProps {
  auth: FirebaseReducer.AuthState;
  getMyProfile: (playerId: string) => Promise<PlayerProfile>;
  setMyPicture: (base64: string) => void;
  setNewUser: () => void;
  getToken: (uid: string) => Promise<any>;
  login: () => void;
  registerProfile: (uid: string, newProfile: PlayerRegister) => Promise<any>;
  appContext?: IAppContext
}

interface LoginError {
  email: boolean;
  password: boolean;
  registerLogin: boolean;
  registerPass: boolean;
  confirmPass: boolean;
}

interface State {
  email: string;
  password: string;
  mode: LoginPageMode;
  registerLogin: string;
  registerPass: string;
  confirmPass: string;
  errors: LoginError,
}

class PlayerAuth extends Component<Props, State> {
  state = {
    email: null,
    password: null,
    mode: LoginPageMode.Login,
    registerLogin: null,
    registerPass: null,
    confirmPass: null,
    errors: {
      email: false,
      password: false,
      registerLogin: false,
      registerPass: false,
      confirmPass: false,
    },
  };

  handleLoginKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && this.state.email && this.state.password) {
      this.signIn();
    }
  }

  handleRegisterKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.registerUserFirebase();
    }
  }

  getTokenHandle(uid: string) {
    this.props.getToken(uid).then(() => {
      this.props.login();
      this.goToLobby();
    });
  }

  componentDidMount() {
    if (this.props.auth.uid) {
      this.props
        .getMyProfile(this.props.auth.uid)
        .then((p: PlayerProfile) => {
          this.getTokenHandle(this.props.auth.uid);
        })
        .catch((err: AxiosError) => {
          console.log(err);
          if (err.response && err.response.status === 404) {
            this.signOut();
          }
        });
    }
  }

  signOut = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        console.log('Sign-out successful', res);
        // document.location.reload();
      })
      .catch((error) => {
        console.log('signOut error', error);
      });
  };

  analiseFirebaseError(error, title = 'Login Error') {
    const errors = this.state.errors;
    if (error.code.includes('email')) {
      if (this.state.mode === LoginPageMode.Register) {
        this.setState({ errors: { ...errors, registerLogin: true } });
      } else {
        this.setState({ errors: { ...errors, email: true } });
      }
    }
    if (error.code.includes('password')) {
      if (this.state.mode === LoginPageMode.Register) {
        this.setState({ errors: { ...errors, confirmPass: true, registerPass: true } });
      } else {
        this.setState({ errors: { ...errors, password: true } });
      }
    }
    this.props.appContext.setAlert({
      title: title,
      message: error.message,
      variant: AlertVariant.danger,
    });
  }

  signIn = () => {
    if (this.state.email && this.state.password) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then((res) => {
          console.log(res);
          this.getTokenAndProfile(res);
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.analiseFirebaseError(error);
        });
    } else {
      if (!this.state.email) {
        this.setState((state) => ({ errors: { ...state.errors, email: true } }));
      }
      if (!this.state.password) {
        this.setState((state) => ({ errors: { ...state.errors, password: true } }));
      }
      this.props.appContext.setAlert({
        title: 'Login Error',
        message: 'Incorrect user data',
        variant: AlertVariant.danger,
      });
    }
  };

  loginWithFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        console.log(res);
        this.checkPlayerProfile(res);
      })
      .catch((error) => {
        console.log(error.code, error.message);
        this.analiseFirebaseError(error);
      });
  };

  signUp = () => {
    this.setState({
      mode: LoginPageMode.Register,
    });
  };

  goBack = () => {
    this.setState({
      mode: LoginPageMode.Login,
    });
  };

  goForgot = () => {
    this.setState({
      mode: LoginPageMode.Forgot,
    });
  };

  changeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: e.target.value,
      errors: { ...this.state.errors, email: false },
    });
  };

  changePass = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: e.target.value,
      errors: { ...this.state.errors, password: false },
    });
  };

  registerLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      registerLogin: e.target.value,
      errors: { ...this.state.errors, registerLogin: false },
    });
  };

  registerPass = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      registerPass: e.target.value,
      errors: { ...this.state.errors, registerPass: false },
    });
  };

  confirmPass = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      confirmPass: e.target.value,
      errors: { ...this.state.errors, confirmPass: false },
    });
  };

  getTokenAndProfile = (res: firebase.auth.UserCredential) => {
    this.props
      .getMyProfile(res.user.uid)
      .then(() => {
        this.getTokenHandle(res.user.uid);
      })
      .catch((err: AxiosError) => {
        console.log(err);
        if (err.response && err.response.status === 404) {
          this.registerPlayer(res);
        }
      });
  };

  registerPlayer = (res: firebase.auth.UserCredential) => {
    const profile: any = res.additionalUserInfo.profile;

    // facebook register
    if (profile) {
      const cred: any = res.credential;
      const photoUrl =
        'https://graph.facebook.com/' + profile.id + '/picture?type=square&height=200&width=200&access_token=' + cred.accessToken;

      toDataUrl(photoUrl, (myBase64: string) => {
        this.props.setMyPicture(myBase64);
        console.log(myBase64); // myBase64 is the base64 string

        this.props
          .registerProfile(res.user.uid, {
            avatar: myBase64,
            displayName: res.user.displayName,
            email: res.user.email,
          })
          .then((r: any) => {
            this.getTokenAndProfile(res);
          })
          .catch((err) => {
          });
      });
    } else {
      // email register
      this.props
        .registerProfile(res.user.uid, {
          avatar: '',
          displayName: '', // new field for register page
          email: res.user.email,
        })
        .then((r: any) => {
          this.getTokenAndProfile(res);
        });
    }
  };

  checkPlayerProfile = (res: firebase.auth.UserCredential) => {
    if (res.user.uid) {
      if (res.additionalUserInfo.isNewUser) {
        this.props.setNewUser();
        this.registerPlayer(res);
      } else {
        this.getTokenAndProfile(res);
      }
    }
  };

  registerUserFirebase = () => {
    if (this.state.registerLogin && this.state.registerPass && this.state.registerPass === this.state.confirmPass) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.registerLogin, this.state.registerPass)
        .then((res) => {
          this.checkPlayerProfile(res);
        })
        .catch((error) => {
          console.log(error.code, error.message);
          this.analiseFirebaseError(error, 'Sign Up Error');
        });
    } else {
      if (!this.state.registerLogin) {
        this.setState((state) => ({ errors: { ...state.errors, registerLogin: true } }));
      }
      if (!this.state.registerPass) {
        this.setState((state) => ({ errors: { ...state.errors, registerPass: true } }));
      }
      if (!this.state.confirmPass) {
        this.setState((state) => ({ errors: { ...state.errors, confirmPass: true } }));
      }
      if (this.state.registerPass !== this.state.confirmPass) {
        this.setState((state) => ({ errors: { ...state.errors, confirmPass: true, registerPass: true } }));
        this.props.appContext.setAlert({
          title: 'Sign Up Error',
          message: 'Passwords do not match',
          variant: AlertVariant.danger,
        });
      } else {
        this.props.appContext.setAlert({
          title: 'Sign Up Error',
          message: 'Incorrect user data',
          variant: AlertVariant.danger,
        });
      }
    }
  };

  forgot = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(this.state.email)
      .then((res) => {
        this.props.appContext.setAlert({
          title: 'Forgot Password',
          message: 'E-mail Sent',
          variant: AlertVariant.info,
        });
        this.goBack();
      })
      .catch((error) => {
        console.log(error.code, error.message);
      });
  };

  goToLobby = () => {
    this.props.history.push('/Lobbies');
  };

  render() {
    const { auth } = this.props;

    let loginForm = (
      <div className="login-auth__form">
        <label htmlFor="email">E-mail</label>
        <div className={cn({
          'input-group mb-3': true,
          'input-group--error': this.state.errors.email,
        })}>
          <div className="input-group-prepend">
            <EmailIcon />
          </div>
          <input
            id={'email'}
            type={'text'}
            className="form-control custom"
            placeholder="Type your E-mail"
            aria-label="Email"
            defaultValue={this.state.email}
            onChange={this.changeMail}
            onKeyDown={this.handleLoginKeyDown}
          />
        </div>

        <label htmlFor="password">Password</label>
        <div className={cn({
          'input-group mb-3': true,
          'input-group--error': this.state.errors.password,
        })}>
          <div className="input-group-prepend">
            <PasswordIcon />
          </div>
          <input
            id={'password'}
            type={'password'}
            className="form-control custom"
            placeholder="Type your Password"
            aria-label="Password"
            defaultValue={this.state.password}
            onChange={this.changePass}
            onKeyDown={this.handleLoginKeyDown}
          />
        </div>
        <p className="forgot">
          <a className="forgot__link" role="button" onClick={this.goForgot}>
            Forgot Password?
          </a>
        </p>
      </div>
    );

    let forgotForm = (
      <div className="login-auth__form">
        <p className="back">
          <a className="back__link" role="button" onClick={this.goBack}>
            <IoIosArrowBack /> Go Back
          </a>
        </p>
        <label htmlFor="email">E-mail</label>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <EmailIcon />
          </div>
          <input
            id={'email'}
            type={'text'}
            className="form-control custom"
            placeholder="Type your E-mail"
            aria-label="Email"
            defaultValue={this.state.email}
            onChange={this.changeMail}
          />
        </div>
      </div>
    );

    let registerForm = (
      <div className="login-auth__form">
        <p className="back">
          <a className="back__link" role="button" onClick={this.goBack}>
            <IoIosArrowBack /> Go Back
          </a>
        </p>
        <label htmlFor="email">E-mail</label>
        <div className={cn({
          'input-group mb-3': true,
          'input-group--error': this.state.errors.registerLogin,
        })}>
          <div className="input-group-prepend">
            <EmailIcon />
          </div>
          <input
            id={'email'}
            type={'text'}
            className="form-control custom"
            placeholder="Type your E-mail"
            aria-label="Email"
            onChange={this.registerLogin}
            onKeyDown={this.handleRegisterKeyDown}
          />
        </div>

        <label htmlFor="new-password">Password</label>
        <div className={cn({
          'input-group mb-3': true,
          'input-group--error': this.state.errors.registerPass,
        })}>
          <div className="input-group-prepend">
            <PasswordIcon />
          </div>
          <input
            id={'new-password'}
            type={'password'}
            className="form-control custom"
            placeholder="Type your Password"
            aria-label="Password"
            onChange={this.registerPass}
            onKeyDown={this.handleRegisterKeyDown}
          />
        </div>
        <div className={cn({
          'input-group mb-3': true,
          'input-group--error': this.state.errors.confirmPass,
        })}>
          <div className="input-group-prepend">
            <PasswordIcon />
          </div>
          <input
            id={'confirm-password'}
            type={'password'}
            className="form-control custom"
            placeholder="Confirm your Password"
            aria-label="Confirm Password"
            onChange={this.confirmPass}
            onKeyDown={this.handleRegisterKeyDown}
          />
        </div>
      </div>
    );

    let loginButtonGroup = (
      <>
        <div className="login-auth__buttons">
          <Button
            className="login-button"
            variant="app-main"
            onClick={this.signIn}
            disabled={!isEmpty(auth)}
          >
            Login
          </Button>
          <span>or</span>
          <ContentButton
            text={'Login with Facebook'}
            className={'btn-app-facebook facebook-button'}
            variant={'primary'}
            btnClick={this.loginWithFacebook}
            disabled={!isEmpty(auth)}
          >
            <FacebookIcon />
          </ContentButton>
        </div>
        <div className="login-auth__signup">
          <p className="description">Don't have an account?</p>
          <a className="sign-up-link" onClick={this.signUp} role="button">
            SIGN UP
          </a>
        </div>
      </>
    );

    let logOut = (
      <div className="avatar d-flex justify-content-center align-items-center flex-column p-2">
        <div className="pb-2">
          <img src={auth.photoURL} />
          {auth.displayName || auth.email}
        </div>
        <div>
          <Button variant="app-red" onClick={this.signOut}>
            Sign Out
          </Button>
        </div>
      </div>
    );

    let resContent = () => {
      switch (this.state.mode) {
        case LoginPageMode.Register:
          return registerForm;
        case LoginPageMode.Forgot:
          return forgotForm;
        default:
          return loginForm;
      }
    };

    return !isLoaded(auth) ? (
        <span>Loading...</span>
      ) :
      <div className={cn('login-auth', {
        'show-loader': !isEmpty(auth),
      })}>
        <div className="loader">
          <Spinner animation="border" variant="light" />
        </div>
        {isEmpty(auth) && resContent()}
        {isEmpty(auth) && <>
          {this.state.mode === LoginPageMode.Register ? (
            <div className="login-auth__buttons">
              <Button className="register-button" variant="app-main" onClick={this.registerUserFirebase}>
                Register
              </Button>
            </div>
          ) : null}

          {this.state.mode === LoginPageMode.Forgot ? (
            <div className="login-auth__buttons">
              <Button className="register-button" variant="app-main" onClick={this.forgot}>
                Send E-mail
              </Button>
            </div>
          ) : null}

          {this.state.mode === LoginPageMode.Login ? loginButtonGroup : null}
        </>}
      </div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

export default connect(
  mapStateToProps,
  { getToken, getMyProfile, setMyPicture, registerProfile, setNewUser })
(withAppContext(withRouter(PlayerAuth)),
);
