export enum LobbyStatus {
  None = 0,
  HostWaitingOpponent = 1,
  GameActive = 2,
  GameFinished = 3,
  Closed = 4,
}

export enum GameStatus {
  CreatedLobby = 2,
  GamePreparation = 8,
  PlayersRollDice = 10,
  NewGame = 11,
  PossibleLeave = 12,
  RollDice = 20,
  Playing = 30,
  FromCheckersOnBar = 31,
  BearingOff = 32,
  NoDiceToPlay = 40,
  NoMoveAvailable = 50,
  PlayerWin = 60,
  PlayerLose = 70,
  NoStarted = 80,
  OpponentDice = 1000,
}

export enum GameColor {
  Black = "Black",
  White = "White",
}

export enum IconType {
  Rotate = "Rotate",
  Double = "Double",
  Leave = "Leave",
  Help = "Help",
  Plus = "Plus",
  List = "List",
  FullScreen = "FullScreen",
  FullScreenExit = "FullScreenExit",
  Dice="Dice",
}

export enum PositionType {
  Top,
  Bottom,
}

export enum LoginPageMode {
  Login,
  Register,
  Forgot,
}

export enum CheckerFlatPlayer {
  P1 = "checkerFlatP1",
  P2 = "checkerFlatP2",
}
