import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import AppFullScreen from '../components/Fullscreen/AppFullScreen';
import Lobbies from '../components/Lobbies/Lobbies';
import Login from '../components/Login/Login';
import PrivacyAndTerms from '../components/PrivacyAndTerms/PrivacyAndTerms';
import Profile from '../components/Profile/Profile';
import Contacts from '../components/Contacts/Contacts';
import packageJson from '../../package.json';
import AlertsList, { IAlert } from '../components/AlertList/AlertsList';
import { AppContext } from '../context';


// function PrivateRoute({ children, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         true ? ( // isAuth=true
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }

function AppFullScreenContainer({ match }) {
  return <AppFullScreen match={match} />;
}

const PrivateRoute: React.ComponentType<any> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const RouterHistory = withRouter(({ history }) => (
  <>
    {/* <Route path="/" exact component={PrivateRoute} /> */}
    <Route path="/">
      <Redirect
        to={{
          pathname: '/Login',
        }}
      />
    </Route>
    {/* <PrivateRoute path="/Lobbies" component={Lobbies} /> */}
    <Route path="/Lobbies" component={Lobbies}>
      <Lobbies history={history} />
    </Route>
    <Route path="/Lobby/:id" component={AppFullScreenContainer} />
    <Route path="/Login"> <Login
      loginHandler={() => {
        history.push('/Lobbies');
      }}
    /></Route>
    <Route path="/PrivacyAndTerms" component={PrivacyAndTerms}>
      <PrivacyAndTerms history={history} />
    </Route>
    <Route path="/Contacts" component={Contacts}>
      <Contacts history={history} />
    </Route>
    <Route path="/Profile" component={Profile}>
      <Profile history={history} />
      </Route>
  </>
));

// const AuthButton = withRouter(({ history }) =>
//   true ? (
//     <p>
//       Welcome!{' '}
//       <Login
//         loginHandler={() => {
//           history.push('/Lobbies');
//         }}
//       />
//     </p>
//   ) : (
//     <p>You are not logged in.</p>
//   )
// );
interface Props {
}
interface State {
  addAlertFunc: (alert) => void
}

class AppRouter extends React.Component <Props, State> {
  state = {
    addAlertFunc: (p: { alert: IAlert }) => {
    },
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          setAlert: this.state.addAlertFunc,
        }}
      >
        <AlertsList addItem={(f) => this.setState({ addAlertFunc: f })} maxCountAlerts={5} />
        <Router>
          {process.env.REACT_APP_PROD === '0' ? (
            <nav className="debug-button debug-routes">
              <Link to="/">Home</Link>
              <Link to="/Login">Login</Link>
              <Link to="/Lobbies">Lobby</Link>
              <Link to="/PrivacyAndTerms">Privacy</Link>
              <Link to="/Contacts">Contacts</Link>
              <Link to="/Profile">Profile</Link>
            </nav>
          ) : null}
          <div className="version">v.{packageJson.version}</div>
          <RouterHistory />
        </Router>
      </AppContext.Provider>
    );
  }
}

export default AppRouter;
