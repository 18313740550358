import { getPlayer } from "./getPlayer";

export const checkCanBearOff = (points, checker, isMyTurn, dice) => {
  let canBearOff = false;

  //Check if it is a player checker
  if (checker >= 0 && checker < 24 && points[checker].playerColor === getPlayer(isMyTurn)) {
    for (let die of dice) {
      //check if the dice have the right number to bear off
      if ((isMyTurn && checker + die === 24) || (!isMyTurn && checker - die === -1)) {
        canBearOff = die;
      }
    }

    if (!canBearOff) {
      const highDie = [...dice].sort().reverse()[0]; //Get the highest die
      let checkerBehind = false; //Check if there is checker behind the moving checker

      //die is more than necessary to bear off
      if ((isMyTurn && checker + highDie > 24) || (!isMyTurn && checker - highDie < -1)) {
        if (isMyTurn) {
          for (let i = 18; i < checker; i++) {
            if (points[i].playerColor && points[i].playerColor === getPlayer(isMyTurn)) {
              checkerBehind = true;
            }
          }
        } else {
          for (let i = 5; i > checker; i--) {
            if (points[i].playerColor && points[i].playerColor === getPlayer(isMyTurn)) {
              checkerBehind = true;
            }
          }
        }

        //If there is no checker behind, it can bear off
        if (!checkerBehind) {
          canBearOff = highDie;
        }
      }
    }
  }
  return canBearOff;
};
