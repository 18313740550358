import React, { Component } from 'react';

interface Props {
  allGames: number;
  winsGames: number;
  lossesGames: number;
}

class UserStatus extends Component<Props> {
  render() {
    return (
      <ul className=" userGames tableGames">
        <li className="borderGames">
          <div className="userGamesHeader">games</div>
          <div className="userGamesHeader userGamesHeader--short">g</div>
          <div className="userGamesValue">{this.props.allGames.toString()}</div>
        </li>
        <li className="borderGames">
          <div className="userGamesHeader userGamesHeader">wins</div>
          <div className="userGamesHeader userGamesHeader--short">w</div>
          <div className="userGamesValue">{this.props.winsGames.toString()}</div>
        </li>
        <li>
          <div className="userGamesHeader">losses</div>
          <div className="userGamesHeader userGamesHeader--short">l</div>
          <div className="userGamesValue endPadding">{this.props.lossesGames.toString()}</div>
        </li>
      </ul>
    );
  }
}

export default UserStatus;
